import React from 'react';
import PropTypes from 'prop-types';

IsMappedLineDot.propTypes = {
    color: PropTypes.string
};

function IsMappedLineDot({color}) {
    return (
        <div style={{
            background: color,
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            marginTop: "41px"
        }}></div>
    );
}

export default IsMappedLineDot;
