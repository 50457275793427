import {API_HOST} from "../config/_entrypoint";
import {SubmissionError} from "redux-form";
import get from "lodash/get";
import has from "lodash/has";
import mapValues from "lodash/mapValues";
import Cookies from "js-cookie";
import abort from "utils/abort";

const MIME_TYPE = "application/ld+json";

export function fetch(id, options = {}, noAuthorization = false) {
    if ("undefined" === typeof options.headers) options.headers = new Headers();
    if (null === options.headers.get("Accept"))
        options.headers.set("Accept", MIME_TYPE);

    if (
        "undefined" !== options.body &&
        !(options.body instanceof FormData) &&
        null === options.headers.get("Content-Type")
    )
        options.headers.set("Content-Type", MIME_TYPE);

    const token = Cookies.get("_f") ? JSON.parse(Cookies.get("_f").toString()) : null;

    if (token) {
        options.headers.set("authorization", "Bearer " + token);
    }

    if (null === options.headers.get("authorization") && !noAuthorization){
        window.location.href = "/login";
    }

    if ("undefined" === typeof options.signal && ("undefined" === typeof options.method || "GET" === options.method)) {
        let abortController = abort.getAbortController();
        options["signal"] = abortController.signal;
    }

    const url = new URL(id, API_HOST);
    if(!options["doNotFillOrganizationId"] && localStorage.getItem('organizationId') && !url.searchParams.has('organizationId')) {
        url.searchParams.set('organizationId', localStorage.getItem('organizationId'));
        delete options["doNotFillOrganizationId"];
    }

    return global.fetch(url, options).then(response => {
        if (response.ok) return response;

        return response.json().then(json => {
            const error = json["hydra:description"] || json.statusText || response.statusText || json.detail;

            // If the API token has expired.
            if (token && response.status === 401) {
                window.location.href = "/logout";
            }

            if (token && (response.status === 404)) {

                window.location.href = "/404";
            }

            if (token && (response.status !== 422 && response.status !== 400)) {

                window.location.href = "/error";
            }

            if (!json.violations) {
                if (json.code === 401 && json.message === "Bad credentials") {
                    throw new SubmissionError({_error: "login.form.error.badCredentials"})
                }

                // If the API return a not found resource.
                if (token && (json["hydra:description"] === "Not Found" && json["@type"] === "hydra:Error")) {
                    window.location.href = "/error";
                }

                throw Error(error);
            }

            let errors = {_error: error};

            json.violations.map(
                violation => (errors[violation.propertyPath] = violation.message)
            );

            throw new SubmissionError(errors);
        });
    });
}

export function normalize(data, flatten = true) {
    if (has(data, "hydra:member")) {
        // Normalize items in collections
        data["hydra:member"] = data["hydra:member"].map(item => normalize(item));

        return data;
    }

    // PHP BUG: normalization may end up in array [] while translations are an object.
    if (data.translations && data.translations instanceof Array) {
        data.translations = {};
    }

    // some time you need the entire collection of object instead of an array of uuids
    if (!flatten) {
        return data;
    }

    // Flatten nested documents
    return mapValues(data, value =>
        Array.isArray(value)
            ? value.map(v => get(v, "@id", v))
            : get(value, "@id", value)
    );
}
