import React from 'react';
import PropTypes from 'prop-types';

import DefaultContainer from "components/containers/DefaultContainer";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";

import PageContent from "./PageContent";

Page.propTypes = {
    titleId: PropTypes.string.isRequired,
    subtitleId: PropTypes.string,
    loading: PropTypes.bool,
    fullPage: PropTypes.bool,
    rightContent: PropTypes.element
};

function Page({titleId, subtitleId, loading, fullPage, rightContent, ...props}) {
    return (
        <DefaultContainer>
            <GridContainer>
                <GridItem xs={12}>
                    <PageContent
                        fullPage={fullPage}
                        loading={loading}
                        titleId={titleId}
                        subtitleId={subtitleId}
                        rightContent={rightContent}
                        {...props}
                    />
                </GridItem>
            </GridContainer>
        </DefaultContainer>
    );
}

export default Page;
