import React from 'react';
import PropTypes from 'prop-types';

import {DragDropContext, Droppable} from "react-beautiful-dnd";

DragAndDropContext.propTypes = {
    onDragEnd: PropTypes.func,
    droppableId: PropTypes.string.isRequired
};

function DragAndDropContext({onDragEnd, droppableId, ...props}) {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={droppableId}>
                {provided => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{ width:'100%' }}
                    >
                        {props.children}
                        {/*
                            it's possible to create a custom reserved space,
                            but this is far too complex and is not implemented in the library
                            see: https://github.com/atlassian/react-beautiful-dnd/issues/518#issuecomment-617082552
                        */}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default DragAndDropContext;
