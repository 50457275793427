import React from 'react';
import PropTypes from "prop-types";

import {styled} from "@mui/material/styles";

import {ReactComponent as ArrowUp} from "assets/img/arrow-up.svg";
import {greyishBlue300} from "assets/jss/main";

ArrowUpIcon.propTypes = {
    color: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.number,
};

const StyledSvg = styled(ArrowUp)(({ theme, color, disabled, size}) => ({
    ...size && {
        width: size,
        height: size
    },
    'path': {
        ...color !== null && {
            fill: color
        },
        ...disabled === true &&  {
            fill: greyishBlue300
        }
    },
}));

export default function ArrowUpIcon(props) {
    return <StyledSvg {...props} />;
}
