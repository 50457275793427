import React, {useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import {InfoOutlined} from "@mui/icons-material";

import {Tooltip} from "@mui/material";

import LockFieldButton from "../../LockFieldButton";

// LockProductButton and LockVariationButton have the same functions but not the same rendering
// we should create a single component later
function LockProductButton() {
    const [notAvailableKeys] = useState([
        '@id',
        '@type',
        'additionalDescription',
        'currency',
        'weight'
    ]);

    const [availableKeys] = useState([
        'name',
        'description',
        'sku',
        'categories',
        'brand',
        'gender',
        'taxRate',
        'externalId'
    ]);

    const [isEveryFieldLocked, setIsEveryFieldLocked] = useState(false);

    const {values, setFieldValue} = useFormikContext();

    // when a lock field value change
    useEffect(() => {
        if (!values.productLock) {
            setIsEveryFieldLocked(false);
            return;
        }

        // make a copy to not directly delete form values
        const productLockValues = structuredClone(values.productLock);

        // remove key that not exist in the form
        notAvailableKeys.forEach(key => {
            delete productLockValues[key];
        });

        const valueHasEveryAvailableKeys = availableKeys.every(key => productLockValues.hasOwnProperty(key));

        if (valueHasEveryAvailableKeys) {
            const isEveryFieldLocked = Object.values(productLockValues).every((lock) => lock === true);

            setIsEveryFieldLocked(isEveryFieldLocked);
        }
    }, [values.productLock])

    function updateAllLockFields(isLock) {
        let allLockedFields = {};

        if (values.productLock) {
            const productLockValues = structuredClone(values.productLock);

            // remove notAvailableKeys from values to change
            const filtered = Object.entries(productLockValues).filter(([key]) => !notAvailableKeys.includes(key))

            filtered.forEach(([key]) => {
                productLockValues[key] = isLock
            });

            allLockedFields = productLockValues;
        } else {
            availableKeys.forEach((key) => {
                allLockedFields[key] = isLock
            })
        }

        setFieldValue('productLock', allLockedFields)
    }

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <LockFieldButton
                isLocked={isEveryFieldLocked}
                onClick={() => updateAllLockFields(!isEveryFieldLocked)}
            />
            <Tooltip
                placement="top-end"
                title={
                    <React.Fragment>
                        <FormattedMessage id={"product.show.lock.help"}/>
                    </React.Fragment>
                }
            >
                <InfoOutlined size="small" style={{width: '.8em', color: '#333333'}}/>
            </Tooltip>
        </div>
    );
}

export default LockProductButton;
