import React from 'react';
import PropTypes from 'prop-types';

import IconButton from "@mui/material/IconButton";

import ArrowDown from "./components/ArrowDown";
import ArrowRight from "./components/ArrowRight";

AccordionArrow.propTypes = {
    isOpen: PropTypes.bool,
    setOpen: PropTypes.func,
    hasVariants: PropTypes.number
};

function AccordionArrow({isOpen, setOpen, hasVariants}) {
    if (!hasVariants) return null

    return (
        <IconButton aria-label="settings" onClick={() => setOpen(!isOpen)}>
            {isOpen ? (
                <ArrowDown />
            ) : (
                <ArrowRight />
            )}
        </IconButton>
    );
}

export default AccordionArrow;
