import React from 'react';
import PropTypes from 'prop-types';

ErrorMessage.propTypes = {
    message: PropTypes.string
};

function ErrorMessage({message}) {
    return (
        <span style={{color: 'red'}}>
            {message}
        </span>
    );
}

export default ErrorMessage;
