import React from 'react';
import PropTypes from 'prop-types';

import MuiStepButton from '@mui/material/StepButton';

import {boxShadow, primaryColor} from "assets/jss/main";

StepButton.propTypes = {
    isSelectedStep: PropTypes.bool,
    onClick: PropTypes.func
};

function StepButton({isSelectedStep, onClick, ...props}) {
    const style = {
        "& span svg:nth-of-type(1)": {
            fill: isSelectedStep ? primaryColor : "white",
            fontSize: "50px",
            "& > text": {
                display: "none"
            },
        },
        "& > span:first-of-type > span:first-of-type": {
            borderRadius: "50%",
            ...boxShadow
        },
    };

    return (
        <MuiStepButton
            sx={style}
            onClick={onClick}
        >
            {props.children}
        </MuiStepButton>
    );
}

export default StepButton;
