import React from 'react';
import PropTypes from 'prop-types';

import {AllMappingTabEnums} from "../enums/MappingTabEnums";

CustomTabPanel.propTypes = {
    activeTab: PropTypes.oneOf(AllMappingTabEnums),
    index: PropTypes.oneOf(AllMappingTabEnums),
    children: PropTypes.any
};

function CustomTabPanel({children, activeTab, index, ...props}) {
    if (activeTab === index) {
        return (
            <div
                role="tabpanel"
                hidden={activeTab !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...props}
            >
                {children}
            </div>
        );
    }

    return null;
}

export default CustomTabPanel;
