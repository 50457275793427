import React from "react";
import {Chip, chipClasses, Tooltip,} from "@mui/material";
import {withStyles} from "@mui/styles";
import productLabelStyle from "../../../../assets/jss/views/product/productLabelStyle";
import {getSimpleObjectTranslation} from "../../../helpers/translations";
import {FormattedMessage} from "react-intl";

function ProductSingleStockLabel({stock, stockType, classes}) {

    if (stock['count'] === 0) {
        return;
    }

    let stockTypeClass;
    let trad;
    if (stockType === 'out-of-stock') {
        stockTypeClass = classes.noStock
        trad = 'retailer.product.list.row.stock.out-of-stock'
    } else if (stockType === 'mid-stock') {
        stockTypeClass = classes.middleStock
        trad = 'retailer.product.list.row.stock.mid-stock'
    } else {
        stockTypeClass = classes.inStock
        trad = 'retailer.product.list.row.stock.in-stock'
    }
    const label = <FormattedMessage id={trad} values={{stock: stock['count']}}/>;

    return (
        <Tooltip title={<FormattedMessage id={"retailer.product.list.row.stock.variation_count"} values={{disabled: stock['count_disabled']}}/>}>
            <Chip
                key={stock}
                label={
                    <>
                        <div className={stock['count_disabled'] === 0 ? classes.stockBadgeOnline : classes.stockBadgeOffline}></div>
                        <FormattedMessage id={trad} values={{stock: stock['count']}}/>
                    </>
                }
                className={stockTypeClass}
                size="small"
                sx={{[`& .${chipClasses.icon}`]: {
                        color: "#ffffff"
                    }}}
            />
        </Tooltip>
    );
}

export default withStyles(productLabelStyle)(ProductSingleStockLabel);
