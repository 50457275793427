import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from "@mui/material/CircularProgress";

import {primaryColor} from "assets/jss/main";

StepCount.propTypes = {
    loading: PropTypes.bool,
    count: PropTypes.number,
    isStepSelected: PropTypes.bool
};

function StepCount({loading, count, isStepSelected}) {
    const style = isStepSelected ? {color: primaryColor} : {};

    if (loading) {
        return (
            <span style={style}>
                &nbsp;(<CircularProgress
                    size={12}
                    color={"inherit"}
                />)
            </span>
        )
    }

    return <span style={style}>&nbsp;({count})</span>;
}

export default StepCount;
