import React from 'react';
import {FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import {Grid} from "@mui/material";

import CancelButtonStyled from "components/button/CancelButtonStyled";
import SaveButton from "components/button/SaveButton";
import ActionsContainer from "components/containers/ActionsContainer";

function PreferencesActions(props) {
    const {isSubmitting, handleReset, isValid, dirty} = useFormikContext();

    if (!dirty) return null;

    return (
        <Grid item xs={12}>
            <ActionsContainer style={{ gap: 'unset', justifyContent: 'space-between' }}>
                <CancelButtonStyled
                    disabled={isSubmitting}
                    onClick={handleReset}
                >
                    <FormattedMessage id='product.show.preferences.actions.cancel' />
                </CancelButtonStyled>
                <SaveButton
                    type='submit'
                    disabled={isSubmitting || !isValid}
                    loading={isSubmitting}
                >
                    <FormattedMessage id='product.show.preferences.actions.submit' />
                </SaveButton>
            </ActionsContainer>
        </Grid>
    );
}

export default PreferencesActions;
