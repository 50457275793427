import React, {Component} from "react";
import {connect} from "react-redux";

import {Authorizations, hasAuthorization} from "../../../utils/authorizations";
import {Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {ExpandLess, ExpandMore, DeviceHub} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

import list from "api/option/list";

import {sortOn} from "utils/sortOn";
import {getTranslation} from "../../../domain/helpers/translations";

class DrawerOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionTabOpen: this.isOptionTabShouldStayOpen(this.props.match.path),
            organizationOptions: []
        };
    }

    // this component and all its parents (and its content) mount every time the page is changed
    // this is due to the RequireAuthentication component which is called each time the page is changed
    componentDidMount() {
        list('mapped=true').then((response) => {
            const formattedOptions = response.map((option) => ({
                id: option.id,
                name: getTranslation(option).name,
                position: option.position
            }));

            formattedOptions.sort(sortOn('position'));

            this.setState({
                organizationOptions: formattedOptions
            })
        })
    }

    isOptionTabShouldStayOpen = (path) => {
        return !!(path.startsWith("/option/"))
    }

    handleOptionTabChange = () => {
        this.setState(state => ({optionTabOpen: !state.optionTabOpen}));
    };

    render() {
        const {authorizations, classes, match} = this.props;

        if (!this.state.organizationOptions || !hasAuthorization(authorizations, Authorizations.MARKETPLACE_OPTION_MANAGEMENT)) {
            return null;
        }

        const {optionTabOpen} = this.state;
        const isInConfigurationPath = match.path.startsWith("/option/");

        return (
            <div>
                <ListItem
                    button
                    className={classes.listItem}
                    classes={{selected: classes.subListSelected}}
                    onClick={this.handleOptionTabChange}
                    key={"configuration"}
                    selected={isInConfigurationPath}
                >
                    <ListItemIcon>
                        <DeviceHub color={isInConfigurationPath ? "primary" : "secondary"} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{color: isInConfigurationPath ? "primary" : "secondary"}}
                        primary={<FormattedMessage id={"sidebar.app.option_value"}/>}
                    />
                    {optionTabOpen ? (
                        <ExpandLess color={"primary"} />
                    ) : (
                        <ExpandMore color={"secondary"} />
                    )}
                </ListItem>
                <Collapse in={optionTabOpen}>
                    <List className={classes.listContainer} disablePadding>
                        {this.state.organizationOptions.map(item => {
                            const isUrlSelected = match.url.startsWith(`/option/${item['id']}`);

                            return (
                                <ListItemButton
                                    className={classes.listItem}
                                    classes={{selected: classes.selected}}
                                    component={Link}
                                    to={`/option/${item['id']}`}
                                    key={item['id']}
                                    selected={isUrlSelected}
                                >
                                    <ListItemText
                                        className={classes.subListText}
                                        inset
                                        primaryTypographyProps={{color: isUrlSelected ? "primary" : "secondary"}}
                                        primary={item['name']}
                                    />
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Collapse>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        member: state.authentication.member,
    }
};

export default connect(mapStateToProps)(DrawerOptions);
