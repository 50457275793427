import React from 'react';
import PropTypes from 'prop-types';

import {Grid} from '@mui/material';

import FormTitle from 'components/form/FormTitle';

FormBloc.propTypes = {
    titleId: PropTypes.string.isRequired
};

/**
 * @deprecated use @src/components/form/FormBloc
 */
function FormBloc({titleId, ...props}) {
    return (
        <Grid item xs={12}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}>
                    <FormTitle titleId={titleId} />
                </Grid>
                <Grid item xs={12}>
                    {props.children}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default FormBloc;
