import React from 'react';
import {injectIntl, FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import InputAdornment from "@mui/material/InputAdornment";

import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";

import LockFieldButton from "../../../../LockFieldButton";

function ExternalIdField(props) {
    const {values, handleChange, touched, errors, handleBlur, setFieldValue} = useFormikContext();

    return (
        <>
            <Label htmlFor='externalId'>
                <FormattedMessage id='product.show.product.form.field.externalId' />
            </Label>
            <InputTextField
                name={`externalId`}
                onChange={handleChange}
                value={values.externalId}
                placeholder={props.intl.formatMessage({id: 'product.show.product.form.field.externalId.placeholder'})}
                fullWidth
                onBlur={handleBlur}
                error={touched.externalId && Boolean(errors.externalId)}
                helperText={touched.externalId && errors.externalId}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <LockFieldButton
                                isLocked={values.productLock?.externalId}
                                onClick={() => setFieldValue('productLock.externalId', !values.productLock?.externalId)}
                            />
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
}

export default injectIntl(ExternalIdField);
