import React from 'react';
import PropTypes from 'prop-types';
import {PropagateLoader} from "react-spinners";

import {primaryColor} from "assets/jss/main";

Loader.propTypes = {
    loading: PropTypes.bool
};

function Loader({loading}) {
    return (
        <div style={{
            left: "0",
            right: "0",
            margin: "0 auto",
            top: "15%",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
        }}>
            <PropagateLoader
                sizeUnit={"px"}
                size={16}
                color={primaryColor}
                loading={loading}
            />
        </div>
    );
}

export default Loader;
