import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Card, CardContent, CardHeader, IconButton} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {blueColor, grey200} from "../../assets/jss/main";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import ArrowUpIcon from "../icons/ArrowUpIcon";

FoldingCard.propTypes = {
    titleId: PropTypes.string.isRequired,
    selected: PropTypes.bool
};


function FoldingCard({titleId, selected = false, backgroundOnHover = false, ...props}) {
    const [isOpen, setOpen] = useState(true);

    return (
        <Card
            sx={{
                borderRadius: '8px',
                ...selected && {
                    border: `1px solid ${blueColor}`
                },
                ...backgroundOnHover && {
                    '&:hover': {
                        background: grey200
                    }
                },
                boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.04)',
            }}
        >
            <CardHeader
                title={<FormattedMessage id={titleId} />}
                sx={{
                    '.MuiCardHeader-title': {
                        color: blueColor,
                        fontSize: '20px',
                        fontWeight: '600',
                    },
                    padding: '24px'
                }}
               action={
                   <IconButton aria-label="settings" onClick={() => setOpen(!isOpen)}>
                       {isOpen ? (
                           <ArrowDownIcon size={20} />
                       ) : (
                           <ArrowUpIcon size={20} />
                       )}
                   </IconButton>
                }
            />
            {isOpen && (
                <CardContent sx={{ padding: '24px'}}>
                    {props.children}
                </CardContent>
            )}
        </Card>
    );
}

export default FoldingCard;
