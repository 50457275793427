import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {styled} from "@mui/styles";
import {BadgeContext} from "../BadgeContext";

Indicator.propTypes = {

};

const StyledIndicator = styled('div')(({ theme, color }) => ({
    width: '8px',
    height: '8px',
    borderRadius: '2px',
    backgroundColor: theme.palette[color]?.main ?? color,
}));


function Indicator(props) {
    const {color, indicatorColor} = useContext(BadgeContext);

    return <StyledIndicator color={indicatorColor ?? color} />
}

export default Indicator;
