import React from "react";
import {Route} from "react-router-dom";
import {List, Order} from "../../views/picking/retailer/index";
import RequireAuthentication from "../../hoc/authentication/requireAuthentication";
import RetailerUnloader from "../../hoc/retailer/retailerUnloader";
import RetailerLoader from "../../hoc/retailer/retailerLoader";
import Cookies from "js-cookie";

let routes = [];
const applications = Cookies.get("_a") ? JSON.parse(Cookies.get("_a").toString()) : null;

if (applications && applications["picking"]) {
    routes.push([
        <Route
            path="/picking/retailers/:params"
            component={RequireAuthentication(RetailerUnloader(List))}
            exact
            key="retailerList"
        />,
        <Route
            path="/picking/retailers/:id/order/:params"
            component={RequireAuthentication(RetailerLoader(Order))}
            exact
            key="retailerOrder"
        />
    ])
}

export default routes;
