import React from 'react';

import Grid from "@mui/material/Grid";

function ListElementContainer(props) {
    return (
        <Grid
            item
            container
            rowSpacing={1}
            sx={{
                maxHeight: `${window.innerHeight * 0.79}px`,
                overflow: "scroll",
                paddingBottom: '20px'
            }}
        >
            {props.children}
        </Grid>

    );
}

export default ListElementContainer;
