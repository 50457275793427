import React, {useContext} from 'react';
import {injectIntl, FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import InputAdornment from "@mui/material/InputAdornment";

import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";

import LockFieldButton from "../../../../LockFieldButton";

import {ProductShowContext} from "../../../../../context/ProductShowContext";

function SkuField(props) {
    const {values, handleChange, touched, errors, handleBlur, setFieldValue} = useFormikContext();
    const {isStockEasyUser, selectedVariation} = useContext(ProductShowContext);

    return (
        <>
            <Label htmlFor='sku'>
                <FormattedMessage id='product.show.variation.form.field.sku' />
            </Label>
            <InputTextField
                name='sku'
                value={values.sku}
                placeholder={props.intl.formatMessage({id: 'product.show.variation.form.field.sku.placeholder'})}
                disabled={selectedVariation && isStockEasyUser}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.sku && Boolean(errors.sku)}
                helperText={touched.sku && errors.sku}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <LockFieldButton
                                isLocked={values.variationLock?.sku}
                                onClick={() => setFieldValue('variationLock.sku', !values.variationLock?.sku)}
                            />
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
}

export default injectIntl(SkuField);
