import React from 'react'
import PropTypes from "prop-types";

import store from "../../../../../../store";
import {FormattedMessage} from "react-intl";

export default function Options({lineItem, separator}) {
    if (lineItem.options) {
        const userLocale = store.getState().authentication?.member?.locale;

        return (
            <>
                {Object.keys(lineItem.options).map(key => {
                    if (!lineItem.options[key] || !lineItem.options[key].option || !lineItem.options[key].value) {
                        return;
                    }
                    const optionName = lineItem.options[key].option[userLocale] ?? lineItem.options[key].option[Object.keys(lineItem.options[key].option)[0]]
                    const optionValue = lineItem.options[key].value[userLocale] ?? lineItem.options[key].value[Object.keys(lineItem.options[key].value)[0]]

                    return (
                        <span>
                            {optionName} : <b>{optionValue ?? <FormattedMessage id={"product.show.attribute.na"} />}</b>
                            {separator}
                        </span>
                    )
                })}
            </>
        );
    }
}

Options.defaultProps = {
    lineItem: {},
    separator: null
};

Options.propTypes = {
    lineItem: PropTypes.shape({
        options: PropTypes.array,
        separator: PropTypes.element
    })
};
