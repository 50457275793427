import React, {useContext, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useFormikContext} from "formik";

import SelectTitle from "components/radioGroup/SelectTitle";
import RadioGroup from "components/radioGroup/RadioGroup";
import FormControlLabel from "components/radioGroup/FormControlLabel";

import {ProductShowContext} from "../../../../../context/ProductShowContext";

import {getTranslation} from "domain/helpers/translations";

RetailerOptionValueField.propTypes = {
    variationRetailerOptionValue: PropTypes.object,
    option: PropTypes.object,
    required: PropTypes.bool
}

function RetailerOptionValueField({variationRetailerOptionValue, option, required = false}) {
    const {retailerOptionsValueList, orderFormattedSelectOptionsByLabel} = useContext(ProductShowContext);

    const {values, setFieldValue, setFieldTouched} = useFormikContext();

    const [retailerOptionsValuesByOption, setRetailerOptionsValuesByOption] = useState([]);
    const [orderedRetailerOptionValues, setOrderedRetailerOptionValues] = useState([]);

    useEffect(() => {
        let retailerOptionsValuesByOption = retailerOptionsValueList[option.id];

        setRetailerOptionsValuesByOption(retailerOptionsValuesByOption);

        // this is for the select values
        const formattedRetailerOptionsValuesByOption = formatRetailerOptionValuesForSelect(retailerOptionsValuesByOption);
        const orderedRetailerOptionsValuesByOption = orderFormattedSelectOptionsByLabel(formattedRetailerOptionsValuesByOption);

        setOrderedRetailerOptionValues(orderedRetailerOptionsValuesByOption)
    }, [retailerOptionsValueList])


    function formatRetailerOptionValuesForSelect(retailerOptionsValuesByOption) {
        return retailerOptionsValuesByOption.map((key) => ({
            "value": formatRetailerOptionValueId(key.id),
            "label": getTranslation(key)?.name
        }));
    }

    function getRetailerOptionValueTranslationById(id) {
        if (!id) return null;

        const rov = retailerOptionsValuesByOption.find((rov) => rov.id === id);

        if (!rov) return null;

        return getTranslation(rov)?.name;
    }

    function formatRetailerOptionValueId(id) {
        return `/retailer_option_values/${id};`
    }

    function onChange(retailerOptionValueId) {
        // make a copy
        let newVariationRetailerOptionValues = [...values.retailerOptionValues];

        // find the location of the option we wish to modify
        const index = newVariationRetailerOptionValues.findIndex((rov) => rov.option.id === option.id);

        // check if the selected rov exist
        // maybe not necessary
        const selectedRetailerOptionValue = retailerOptionsValuesByOption.find((rov) => formatRetailerOptionValueId(rov.id) === retailerOptionValueId);

        if (index !== -1) {
            // replace current rov, by the selected rov
            newVariationRetailerOptionValues.splice(index, 1, selectedRetailerOptionValue);
        } else {
            // add the new rov to the variation
            newVariationRetailerOptionValues.push(selectedRetailerOptionValue);
        }

        setFieldTouched('retailerOptionValues', true, false);
        setFieldValue('retailerOptionValues', newVariationRetailerOptionValues);
    }

    return (
        <>
            <SelectTitle
                titleTranslationKey={getTranslation(option)?.name}
                required={required}
            />
            <RadioGroup
                alignListWithButton
                selectedValueLabel={getRetailerOptionValueTranslationById(variationRetailerOptionValue?.id)}
                name={'retailerOptionValues'}
                value={variationRetailerOptionValue?.id}
                onClickAway={() => setFieldTouched('retailerOptionValues')}
                onChange={(event) => {
                    onChange(event.target.value);
                }}
            >
               {orderedRetailerOptionValues.map((rov, index) => (
                    <FormControlLabel
                        key={index}
                        value={rov.value}
                        label={rov.label}
                    />
                ))}
            </RadioGroup>
        </>
    );
}

export default RetailerOptionValueField;
