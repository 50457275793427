import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage, injectIntl} from "react-intl";

// @mui/material components
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import {ArrowBackIos} from "@mui/icons-material";

// core components
import BarcodeScanner from "../../../../../../components/barcodeScanner";
import Button from "components/button/button";
import Card from "components/card/card";
import CardHeader from "components/card/cardHeader";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Loader from "../../../components/loader";

// styles
import CreateProductFormStyle from "assets/jss/views/stockeasy/product/create/form/createProductFormStyle";

// actions
import {request} from "actions/stockeasy/product/request";

// utils
import {UserAgentUtils} from "../../../../../../utils/useragent";
import { v4 as uuidv4 } from 'uuid';

import localStorageSE from "../localStorageManager/localStorageSE";
import {BeatLoader} from "react-spinners";
import {withStyles} from "@mui/styles";

class BarcodeStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            barcode: '',
            sku: '',
            barcodePopup: false,
            isDesktop: true,
        };
    }

    componentDidMount() {
        if (UserAgentUtils.isMobileOrTablet()) {
            this.setState({
                isDesktop: false,
            });
        }

        const searchParams = new URLSearchParams(window.location.search);
        const scannedBarcode = searchParams.get('scannedBarcode');
        const isVariation = searchParams.get('variation');
        if (scannedBarcode && (!isVariation || 'false' === isVariation)) {
            this.setBarcode(scannedBarcode);
            this.requestProductByBarcode(scannedBarcode);
        }

        window.addEventListener("resize", this.handleChangeDevice);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleChangeDevice);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.productRequestData !== nextProps.productRequestData) {
            if (nextProps.productRequestData.realId) {
                // product is found with its barcode, let's redirect user to the product page
                this.props.history.push(`/stockeasy/show/retailers/${this.props.retailerId}/products/show/${nextProps.productRequestData.realId}`);
            }else {
                localStorageSE.updateProductData(nextProps.productRequestData);
                localStorageSE.updateCurrentStep(1);
                this.closeBarcodePopup();
                this.props.goToNextStep(); // redirect to product step
            }
        }
    }

    setBarcode(barcode) {
        this.setState({
            barcode: barcode
        });
    }

    resetBarCode() {
        this.setState({
            barcode: null
        });
    }

    setSku(sku) {
        this.setState({
            sku: sku
        })
    }

    showBarcodePopup() {
        this.setState({
            barcodePopup: true,
        });
    }

    createEmptyProductAndVariationWithSku() {

        const retailerId = this.props.retailerId
        let taxRate = 20;
        this.props.retailers.forEach( (retailer) => {
            if (retailer['@id'] === '/retailers/'+retailerId) {
                if (retailer['taxRateZero'] === true) {
                    taxRate = 0;
                }
            }
        })

        let sku = uuidv4();
        let product =  {
            'name': null,
            'pictures': [],
            'barcode': null,
            'categories': [],
            'sku': uuidv4(),
            'tva': taxRate,
            'isProductWithoutBarCode': true,
            'productNotFound': true,
            'variations': [
                {
                    'sku': sku,
                    'barcode': null,
                    'pictures': [],
                    'options': {},
                    'weight': null,
                }
            ]
        }

        this.setState({
            sku: sku,
        });

        this.props.setProductWithoutBarcode(product);

        this.props.goToNextStep()
    }

    closeBarcodePopup() {
        this.setState({
            barcodePopup: false
        });
    }

    showBarcodeScanner() {
        if (!UserAgentUtils.isMobileOrTablet()) {
            return;
        }

        if (this.state.barcode !== '') {
            return;
        }

        if (this.state.barcodePopup === true) {
            return;
        }

        return <BarcodeScanner onBarcodeFound={(result) => {
            this.setBarcode(result);
            this.showBarcodePopup();
        }} onExit={(type) => {
            if ('barcode' === type){
                this.showBarcodePopup();
            } else {
                this.createEmptyProductAndVariationWithSku();
            }
        }} browserScanner={false} />;
    }

    handleChangeDevice = () => {
        this.setState({ isDesktop: !UserAgentUtils.isMobileOrTablet() });
    };

    requestProductByBarcode(barcode) {
        this.props.barcodeRequest(barcode, this.props.retailerId);
    }

    render() {
        const {classes, intl} = this.props;

        if (this.props.productRequestLoading) {
            return (
                <Card className={classes.barcodeCard}>
                    <div className={classes.loader}>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={"#0052cb"}
                            loading={true}
                        />
                    </div>
                </Card>
            );
        }

        return (
            <Card className={classes.barcodeCard}>
                <CardHeader color="info" className={classes.cardHeader}>
                    <GridContainer>
                        {this.state.barcodePopup === false && this.state.isDesktop && (
                            <GridItem xs={12} className={classes.referenceButton}>
                                <Button onClick={() => this.showBarcodePopup()} color="primary">
                                    <FormattedMessage id={"stockeasy.barcode.button.open.barcode"}/>
                                </Button>
                                <Button onClick={() => this.createEmptyProductAndVariationWithSku()} color="primary">
                                    <FormattedMessage id={"stockeasy.barcode.button.open.sku"}/>
                                </Button>
                            </GridItem>
                        )}

                        {this.showBarcodeScanner()}

                    </GridContainer>
                </CardHeader>
                <Dialog
                    open={this.state.barcodePopup}
                    onClose={() => this.closeBarcodePopup()}
                >
                    <DialogTitle id="form-dialog-title">
                        <FormattedMessage id={"stockeasy.barcode.dialog.title"}/>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            variant="standard"
                            autoFocus
                            margin="dense"
                            id="barcode"
                            label={intl.formatMessage({id: "stockeasy.barcode.label"})}
                            type="text"
                            fullWidth
                            value={this.state.barcode}
                            onChange={(e) => this.setBarcode(e.target.value)}
                            disabled={this.props.productRequestLoading} />
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            simple
                            color={"info"}
                            href={`/stockeasy/show/retailers/${this.props.retailerId}/products/${encodeURIComponent(`retailer.id=${this.props.retailerId}`)}`}
                        >
                            <ArrowBackIos />
                            <FormattedMessage id={"stockeasy.barcode.dialog.cancel"}/>
                        </Button>
                        <Button
                            round
                            color={"success"}
                            onClick={() => this.requestProductByBarcode(this.state.barcode)}
                            disabled={this.props.productRequestLoading}
                        >
                            {this.props.productRequestLoading ? <Loader /> : intl.formatMessage({id:"stockeasy.barcode.dialog.button"})}
                        </Button>
                    </DialogActions>
                    {this.props.productRequestError && (
                        <DialogContent>
                            <span className={classes.error}>
                                <FormattedMessage id={"stockeasy.barcode.dialog.error"}/>
                            </span>
                        </DialogContent>
                    )}
                </Dialog>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        productRequestData: state.stockEasy.request.retrieved,
        productRequestLoading: state.stockEasy.request.loading,
        productRequestError: state.stockEasy.request.error,
        retailers: state.authentication.member.retailers,
    };
};

const mapDispatchToProps = dispatch => ({
    barcodeRequest: (barcode, retailerId) => dispatch(request(barcode, retailerId, 'barcode')),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(CreateProductFormStyle)(injectIntl(BarcodeStep)));
