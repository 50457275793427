import React from 'react';
import {injectIntl, FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import InputAdornment from "@mui/material/InputAdornment";

import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";

import LockFieldButton from "../../../../LockFieldButton";

function OutletPriceField(props) {
    const {values, handleChange, touched, errors, handleBlur, setFieldValue} = useFormikContext();

    return (
        <>
            <Label htmlFor='outletPrice'>
                <FormattedMessage id='product.show.variation.form.field.outletPrice' />
            </Label>
            <InputTextField
                name='outletPrice'
                onChange={handleChange}
                value={values.outletPrice ?? ''}
                placeholder={props.intl.formatMessage({id: 'product.show.variation.form.field.outletPrice.placeholder'})}
                fullWidth
                onBlur={handleBlur}
                error={touched.outletPrice && Boolean(errors.outletPrice)}
                helperText={touched.outletPrice && errors.outletPrice}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <LockFieldButton
                                isLocked={values.variationLock?.outletPrice}
                                onClick={() => setFieldValue('variationLock.outletPrice', !values.variationLock?.outletPrice)}
                            />
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
}

export default injectIntl(OutletPriceField);
