import React, {useContext} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';

import {ImageDropFieldContext} from "../../../context/ImageDropFieldContext";
import FileInfosText from "./FileInfosText";

RecommendedFormatText.propTypes = {
    format: PropTypes.string.isRequired,
};

export default function RecommendedFormatText({format}) {
    const {disabled} = useContext(ImageDropFieldContext);

    if (!format) {
        return null;
    }

    return (
        <p>
            <FileInfosText disabled={disabled}>
                <FormattedMessage id="components.imageDropField.recommendedFormat" />
                {format}
            </FileInfosText>
        </p>
    );
}
