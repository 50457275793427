import React from 'react';
import {injectIntl, FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import InputAdornment from "@mui/material/InputAdornment";

import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";

import LockFieldButton from "../../../../LockFieldButton";

function EcoTaxField(props) {
    const {values, handleChange, touched, errors, handleBlur, setFieldValue} = useFormikContext();

    return (
        <>
            <Label htmlFor='ecoTax'>
                <FormattedMessage id='product.show.variation.form.field.ecoTax' />
            </Label>
            <InputTextField
                name='ecoTax'
                onChange={handleChange}
                value={values.ecoTax ?? ''}
                placeholder={props.intl.formatMessage({id: 'product.show.variation.form.field.ecoTax.placeholder'})}
                fullWidth
                onBlur={handleBlur}
                error={touched.ecoTax && Boolean(errors.ecoTax)}
                helperText={touched.ecoTax && errors.ecoTax}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <LockFieldButton
                                isLocked={values.variationLock?.ecoTax}
                                onClick={() => setFieldValue('variationLock.ecoTax', !values.variationLock?.ecoTax)}
                            />
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
}

export default injectIntl(EcoTaxField);
