import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import {greyishBlue400} from "assets/jss/main";

TotalImages.propTypes = {
    count: PropTypes.number
};

function TotalImages({count}) {
    return (
        <div style={{
            position: 'absolute',
            right: 0,
            top: -24,
            fontSize: 12,
            fontWeight: 600,
            color: greyishBlue400
        }}>
            <FormattedMessage id='components.multiImagesDropField.count' values={{count: count}} />
        </div>
    );
}

export default TotalImages;
