import React from 'react';
import TabMui from "@mui/material/Tab"
import {styled} from "@mui/styles";
import {black500, greyishBlue500} from "../../assets/jss/main";

const StyledTab = styled(TabMui)(({ theme}) => ({
    textTransform: 'none',
    paddingTop: 'unset',
    paddingBottom: 'unset',
    color: greyishBlue500,
    fontSize: '16px',
    fontWeight: 400,
    '&.Mui-selected': {
        color: black500,
    },
}));

function Tab(props) {
    return (
        <StyledTab {...props}>
            {props.children}
        </StyledTab>
    )
}

export default Tab;
