import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage, injectIntl} from "react-intl";
import {Redirect} from "react-router-dom";

// @mui/icons-material
import {NotificationsActive} from "@mui/icons-material";

// core components
import Attributes from "./attributes";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Snackbar from "components/snackbar/snackbar";
import DefaultContainer from "components/containers/DefaultContainer";

// actions
import {update} from "actions/feed/attribute/update";

class Update extends Component {
    constructor(props) {
        super(props);

        this.state = {
            retrieveErrorNotification: true,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            retrieveErrorNotification: true,
        });
    };

    isThereRetailerOptionValuesToMap(retailerOptionValues) {
        if (!retailerOptionValues) {
            return false;
        }

        for (const retailerOptionValue of Object.values(retailerOptionValues)) {
            if (retailerOptionValue['retailerOptionValuesCount'] > 0) {
                return true;
            }
        }

        return false;
    }

    render() {
        let retailerId = decodeURIComponent(this.props.match.params.id);// this.props.match.params.id;
        let retailerIri = '/retailers/'+retailerId;

        if (this.props.attributeRetrieved?.['missingCategories']?.length === 0
            && this.props.attributeRetrieved?.['missingGenders']?.length === 0
            && !this.isThereRetailerOptionValuesToMap(this.props.attributeRetrieved?.['retailerOptionValues'])
        ) {
            return <Redirect to={`/retailers/show/${encodeURIComponent(retailerIri)}`}/>;
        }

        return (
            <DefaultContainer>
                    {this.props.listError && (
                        <Snackbar
                            open={this.state.retrieveErrorNotification}
                            close
                            closeNotification={() => this.setState({retrieveErrorNotification: false})}
                            place={"bl"}
                            color={"danger"}
                            icon={function () {
                                return <NotificationsActive/>
                            }}
                            message={<FormattedMessage id={"feed.attribute.notifications.list.error"}/>}
                        />
                    )}
                    <GridContainer>
                        <GridItem xs={12}>
                            <div>
                                <h3><FormattedMessage id={"feed.attribute.form.title"}/></h3>
                            </div>
                            <Attributes
                                onSubmitEvent={this.props.update}
                                retailerId={retailerId}
                                history={this.props.history}
                            />
                        </GridItem>
                    </GridContainer>
            </DefaultContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        listError: state.attribute.list.error,
        attributeRetrieved: state.attribute.list.retrieved,
        retailer: state.retailer.show.retrieved,
    };
};

const mapDispatchToProps = dispatch => ({
    update: (values, retailerId) => dispatch(update(values, retailerId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Update));
