import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {ReactComponent as ArrowUp} from "assets/img/arrow-up.svg";
import {ReactComponent as ArrowDown} from "assets/img/arrow-down.svg";

import {makeStyles} from "@mui/styles";

import {
    black500,
    greyishBlue200,
    greyishBlue50,
    greyishBlue500,
    infoColor,
    grayColor,
    blue50,
} from "assets/jss/main";

const useStyles = makeStyles(theme => ({
    fakeSelectInputContainer: {
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: '#fff',
        cursor: 'pointer',
        border: `1px solid ${greyishBlue200}`,
        borderRadius: '8px',
        '&:hover': {
            borderColor: greyishBlue500,
        },
    },
    fakeSelectInput: {
        width: '100%',
        backgroundColor: 'transparent',
        border: 'unset',
        display:'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: black500,
        padding: '16px',
        fontSize: '14px',
        textAlign: 'left',
    },
    disabled: {
        borderColor: greyishBlue50,
        backgroundColor: greyishBlue50,
        color: greyishBlue500,
        '&:hover': {
            borderColor: greyishBlue50,
        },
    },
    opened: {
        borderColor: infoColor,
        boxShadow: `0px 0px 0px 2px ${blue50}`,
        '&:hover': {
            borderColor: infoColor,
        },
    },
    hasEndAdornment: {
        paddingRight: '8px'
    },
    noRightPadding: {
        paddingRight: 'unset'
    },
    arrowContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '32px',
        height: '32px',
        cursor: 'pointer',
        justifyContent: 'center',
        borderRadius: '50%',
        marginTop: '-16px',
        marginBottom: '-16px',
        '&:hover': {
            background: greyishBlue50,
        },
    }
}));

export default function FakeSelectInput({placeholder, text, disabled, open, onClick, endAdornment, ...props}) {
    const classes = useStyles();

    return (
        <div
            className={classNames({
                [classes.fakeSelectInputContainer]: true,
                [classes.disabled]: disabled,
                [classes.opened]: open,
                [classes.hasEndAdornment]: !!endAdornment
            })}
        >
            <button
                className={classNames({
                    [classes.fakeSelectInput]: true,
                    [classes.noRightPadding]: !!endAdornment,
                })}
                disabled={disabled}
                onClick={(e) => {
                    e.preventDefault();
                    onClick()
                }}
            >
                <div>
                    {text ? (
                        <span style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}>
                            {text}
                        </span>
                    ) : placeholder ? (
                        <span style={{color: grayColor}}>
                            {placeholder}
                        </span>
                    ) : (
                        <span>&nbsp;</span> // trick to keep the same input height without placeholder
                    )}
                </div>
                <div className={classes.arrowContainer}>
                    {!disabled && (open ? <ArrowUp /> : <ArrowDown />)}
                </div>
            </button>
            {!disabled && endAdornment}
        </div>
    )
}

FakeSelectInput.defaultProps = {
    onClick: () => {},
    disabled: false,
    text: '',
    opened: false,
    endAdornment: null,
}

FakeSelectInput.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.bool
    ]),
    open: PropTypes.bool
}
