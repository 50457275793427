import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import InfoCircleIcon from "../../icons/InfoCircleIcon";

import { useTheme } from '@mui/material/styles';
import {BadgeContext} from "../BadgeContext";

Icon.propTypes = {

};


function Icon(props) {
    const theme = useTheme();

    const {color, iconColor} = useContext(BadgeContext);

    const colorBis = iconColor ?? color;

    return <InfoCircleIcon size={16} color={theme.palette[colorBis]?.main ?? colorBis}/>
}

export default Icon;
