import React, {Component} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import Cookies from "js-cookie";

// core components
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "../components/card/card";
import CardBody from "components/card/cardBody";
import CardHeader from "../components/card/cardHeader";
import Loader from "react-spinners/BeatLoader";

// @mui/material components
import {Button} from "@mui/material";

// styles
import logoStripeGrey from "../assets/img/logoStripeGrey.png";
import logoWishibamGrey from "../assets/img/logoWishibamGrey.png";
import stripeStyle from "../assets/jss/views/stripeStyle";
import squareLogo from "../assets/img/squareLogo.png";

// actions
import {invitationLink} from "actions/retailer/stripe/invitationLink";
import {withStyles} from "@mui/styles";

class SignupStripe extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let member = JSON.parse(Cookies.get("_c").toString());

        let retailerWithoutStripe = null;
        if (member['stripeAccounts']) {
            Object.keys(member['stripeAccounts']).forEach(key => {
                if (false === member['stripeAccounts'][key]['hasAccount'] && true === member['stripeAccounts'][key]['needsAccount']) {
                    retailerWithoutStripe = key;

                    return false;
                }
            });
        }

        if (null === retailerWithoutStripe) {
            window.location = '/';
        } else {
            this.props.invitationLink(retailerWithoutStripe);
        }
    }

    render() {
        const {classes} = this.props;

        return (
            <GridContainer direction="row" justifyContent="center" alignItems="center" className={classes.containerCard}>
                <GridItem xs={12} className={classes.card}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardHeader}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <img className={classes.logo} src={squareLogo} alt={""}/>
                                    <h3><FormattedMessage id={"retailer.stripe.action.welcome"}/></h3>
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody className={classes.cardBody}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <FormattedMessage id={"retailer.stripe.action.instruction"}/><br/>
                                    <br/><br/>
                                    {this.props.stripeLoading ?
                                        <Button className={classes.button}>
                                            <Loader color={'#FFFFFF'} size={8}/>
                                        </Button>
                                        :
                                        <div>
                                            {this.props.stripeSuccess ?
                                                <Button className={classes.button} onClick={() => window.location = this.props.stripeSuccess.url}>
                                                    <FormattedMessage id={"retailer.stripe.action.nextStep"}/>
                                                </Button>
                                                :
                                                <FormattedMessage className={classes.error} id={"retailer.stripe.action.error"}/>
                                        }
                                        </div>
                                    }
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                    <Link to={'/logout'} className={classes.logoutLink}>
                        <FormattedMessage id={'retailer.stripe.action.logout'}/>
                    </Link><br/>
                    <img className={classes.logoFooter} src={logoStripeGrey} alt={""}/><br/>
                    <img className={classes.logoFooter} src={logoWishibamGrey} alt={""}/>
                </GridItem>
            </GridContainer>
        );
    }
}

const mapStateToProps = state => {

    return {
        stripeSuccess: state.retailer.invitationLink.success,
        stripeLoading: state.retailer.invitationLink.loading,
        stripeError: state.retailer.invitationLink.error,
    };
};

const mapDispatchToProps = dispatch => ({
    invitationLink: params => dispatch(invitationLink(params))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(stripeStyle)(SignupStripe));
