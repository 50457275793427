import React from "react";
import PropTypes from "prop-types";
import ImageZoom from 'react-medium-image-zoom';

ProductPreview.propTypes = {
    links: PropTypes.arrayOf(PropTypes.string),
};

export default function ProductPreview({links}) {
    if (!links || links.length === 0) {
        return null;
    }

    return (
        <div style={{
            marginTop: 10,
            display: 'flex',
            justifyContent: 'space-evenly',
        }}>
            {links.map((link, index) => (
                <ImageZoom key={index}>
                    <img
                        src={link}
                        alt={"product preview"}
                        style={{width: 100}}
                    />
                </ImageZoom>
            ))}
        </div>
    )
}
