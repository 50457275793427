import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
// "react-dropzone" is broken
// https://github.com/react-dropzone/react-dropzone/issues/1259#issuecomment-1742141848
import {useDropzone} from "react-dropzone-esm";

// local components
import ImportZone from "./components/ImportZone";
import Preview from "./components/Preview";
import Errors from "./components/Errors";

import {ImageDropFieldContext} from "./context/ImageDropFieldContext";

SingleImageDropField.propTypes = {
    accept: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    fileSupportTypes: PropTypes.array,
    previewUrl: PropTypes.string,
    onDrop: PropTypes.func,
    onDelete: PropTypes.func,
    recommendedFormat: PropTypes.string,
    disabled: PropTypes.bool
};

SingleImageDropField.defaultProps = {
    accept: {
        'image/jpeg': [],
        'image/png': []
    },
    fileSupportTypes: ['JPG', 'PNG']
}

function SingleImageDropField({accept, fileSupportTypes, previewUrl, recommendedFormat, disabled, onPicturesDrop, onPictureDelete, ...props}) {
    const [loading, setLoading] = useState(false)
    const [isValid, setValid] = useState(true);

    const [files, setFiles] = useState([]);
    const [urlValue, setUrlValue] = useState(null);

    // update preview when file from parent component change
    useEffect(() => {
        setUrlValue(previewUrl);
    }, [previewUrl]);

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    function deleteFiles() {
        setFiles([]);
        setUrlValue(null);
    }

    function removeLoader() {
        setLoading(false);
    }

    const {getRootProps, getInputProps, open, fileRejections} = useDropzone({
        accept: accept,
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            setValid(true);

            if (acceptedFiles.length === 0) {
                return;
            }

            setLoading(true)

            if (onPicturesDrop) {
                onPicturesDrop(acceptedFiles, removeLoader);
            } else {
                setFiles(acceptedFiles?.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));
                setLoading(false);
            }
        },
        onDropRejected: (fileRejections) => {
            setValid(false);
        }
    });

    const imgSrc = urlValue ? urlValue : files.length > 0 ? files[0].preview : null;

    return (
        <ImageDropFieldContext.Provider value={{
            openFileSelector: open,
            disabled: disabled || loading,
            importLoading: loading,
            inputProps: getInputProps(),
            rootProps: getRootProps(),
            fileRejections: fileRejections
        }}>
            {imgSrc ? (
                <Preview
                    imgSrc={imgSrc}
                    onDelete={() => onPictureDelete ? onPictureDelete() : deleteFiles()}
                />
            ) : (
                <ImportZone
                    recommendedFormat={recommendedFormat}
                    fileSupportTypes={fileSupportTypes}
                />
            )}
            <Errors />
        </ImageDropFieldContext.Provider>
    );
}

export default SingleImageDropField;
