import React from 'react';
import PropTypes from 'prop-types';

import {blueColor} from "assets/jss/main";

ItemName.propTypes = {
    label: PropTypes.string.isRequired
};

function ItemName({label,...props}) {
    return (
        <span style={{
            color: blueColor,
            fontWeight: 600,
            fontSize: '14px',
        }}>
            {label}
        </span>
    );
}

export default ItemName;
