import React, {Component} from "react";

// @mui/material component
import {Dialog} from "@mui/material";

// core components
import CreateVariationModalContent from "./content/createVariationModalContent";
import GridItem from "../../../../../../components/grid/gridItem";

// style
import CreateProductFormStyle from "../../../../../../assets/jss/views/stockeasy/product/create/form/createProductFormStyle";
import {withStyles} from "@mui/styles";

import localStorageSE from "../localStorageManager/localStorageSE";

class AddVariationModal extends Component {
    render() {
        const {open, error, classes} = this.props;
        const productData = localStorageSE.getProductData();

        return (
            <Dialog
                open={open}
                onClose={this.props.onClose}
                fullWidth
            >
                <CreateVariationModalContent
                    productData={productData}
                    barcode={this.props.barcode}
                    submit={this.props.submit}
                    selectedVariation={this.props.selectedVariation}
                    delete={this.props.delete}
                    options={this.props.options}
                />
                <GridItem>
                    <p className={classes.errorMessage}>{error}</p>
                </GridItem>
            </Dialog>
        );
    }
}

export default withStyles(CreateProductFormStyle)(AddVariationModal);
