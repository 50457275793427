import React, {useContext} from 'react';
import FormBloc from "../../../../../../../../components/form/FormBloc";
import Grid from "@mui/material/Grid";
import AdditionalAttributeField from "./components/AdditionalAttributeField";
import {ProductShowContext} from "../../../../context/ProductShowContext";
import {ProductFormContext} from "../../context/ProductFormContext";

function AdditionalAttributes() {
    const {currentRetailer} = useContext(ProductShowContext);
    const {selectedLocale} = useContext(ProductFormContext);

    if (!selectedLocale) return null;

    const additionalMappingFields = currentRetailer?.additional_mapping_fields ?? [];

    if (Array.isArray(additionalMappingFields) && additionalMappingFields.length === 0) return null;

    return (
        <FormBloc titleId='product.show.product.form.additionalAttributes.title'>
            <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <Grid container direction='column' rowSpacing={3}>
                        <Grid item container columnSpacing={2} rowSpacing={3}>
                            {Object.entries(additionalMappingFields).map(([key, values]) => (
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    key={key}
                                >
                                    <AdditionalAttributeField
                                        name={key}
                                        required={values.required}
                                        localeCode={selectedLocale}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FormBloc>
    );
}

export default AdditionalAttributes;
