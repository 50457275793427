import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import IconButton from "@mui/material/IconButton";

import LockIcon from "components/icons/LockIcon";
import UnlockIcon from "components/icons/UnlockIcon";

import {ProductShowContext} from "../context/ProductShowContext";

LockFieldButton.propTypes = {
    isLocked: PropTypes.bool,
    onClick: PropTypes.func
};

function LockFieldButton({isLocked = false, onClick = () => {}}) {
    const {isStockEasyUser} = useContext(ProductShowContext);

    if (isStockEasyUser) return null;

    return (
        <IconButton onClick={(e) => onClick(e)} >
            {isLocked ? <LockIcon variant={'bold'} /> : <UnlockIcon />}
        </IconButton>
    )
}

export default LockFieldButton;
