import React from 'react';
import PropTypes from "prop-types";

import {styled} from "@mui/material/styles";

import {ReactComponent as Lock} from "assets/img/lock.svg";
import {ReactComponent as LockBold} from "assets/img/lock-bold.svg";

import {greyishBlue300} from "assets/jss/main";

LockIcon.propTypes = {
    color: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.number,
    variant: PropTypes.oneOf(['default', 'bold'])
};

const StyledSvg = styled(Lock)(({ theme, color, disabled, size}) => ({
    ...size && {
        width: size,
        height: size
    },
    'path': {
        ...color !== null && {
            stroke: color
        },
        ...disabled === true &&  {
            stroke: greyishBlue300
        }
    },
}));

const StyledBoldSvg = styled(LockBold)(({ theme, color, disabled, size}) => ({
    ...size && {
        width: size,
        height: size
    },
    'path': {
        ...color !== null && {
            stroke: color
        },
        ...disabled === true &&  {
            stroke: greyishBlue300
        }
    },
}));

export default function LockIcon(props) {
    if (props.variant === 'bold') {
        return <StyledBoldSvg {...props} />
    }

    return <StyledSvg {...props} />;
}
