import React, {useEffect, useLayoutEffect, useState} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import Dropzone from "react-dropzone";
import classNames from 'classnames';

// @mui/material components
import {Button, Checkbox, CircularProgress} from '@mui/material';

// core components
import Card from "components/card/card";
import CardBody from "components/card/cardBody";
import Grid from '@mui/material/Grid';

// actions
import {downloadExport, importRetailer} from "../../../actions/retailer/sync";
import list from 'api/option/list';

// helpers
import abort from "utils/abort";
import {UserAgentUtils} from '../../../utils/useragent';
import {makeStyles} from '@mui/styles';
import {fifteenthGrey, grayColor, infoColor, infoColorDisabled, infoColorHover} from 'assets/jss/main';
import createTheme from '@mui/material/styles/createTheme';
import {ThemeProvider} from '@mui/material/styles';
import { ReactComponent as ExportImage } from 'assets/img/export.svg';
import { ReactComponent as ImportImage } from 'assets/img/import.svg';
import { ReactComponent as DocumentDownload } from 'assets/img/document-download.svg';
import { ReactComponent as SwitchOff } from 'assets/img/switch_off.svg';
import { ReactComponent as SwitchOn } from 'assets/img/switch_on.svg';
import CustomCheckbox from 'components/checkbox/CustomCheckbox';
import {getTranslation} from "../../../domain/helpers/translations";

const useStyles = makeStyles(theme => ({
    cardBody: {
        padding: '48px 24px',
    },
    separatorContainer: {
        display: 'flex',
        '& hr': {
            border: 'none',
            borderLeft: '1px solid #E6E6E6',
            height: '100%',
            width: 1,
        },
    },
    title: {
        marginBottom: 32,
        '& h3': {
            margin: 0,
            color: fifteenthGrey,
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
        },
        '& p': {
            margin: 0,
            color: grayColor,
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
        },
    },
    form: {
        marginBottom: 32,
    },
    importActions: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: 16,
        '@media (max-width: 1333px)': {
            flexDirection: 'column',
        },
        '& > div:first-child': {
            width: '245px',
        },
        '& > div:first-child > span': {
            display: 'block',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        paddingBottom: 12,
        color: fifteenthGrey,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 'normal',
        cursor: 'pointer',
        '& .MuiCheckbox-root': {
            padding: 0,
        },
        '&:nth-last-child(2)': {
            paddingBottom: 32,
        },
    },
    button: {
        display: 'flex',
        padding: '12px 16px',
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'none',
        boxShadow: 'none !important',
        '&:hover': {
            backgroundColor: infoColorHover,
        },
        '&.Mui-disabled': {
            color: '#fff',
            backgroundColor: infoColorDisabled,
        },
    },
    downloadButton: {
        marginBottom: 4,
        backgroundColor: '#F2F2F2',
        color: fifteenthGrey,
        '&:hover': {
            backgroundColor: '#F2F2F2',
        },
    },
    loadingIcon: {
        marginLeft: '10px',
    },
    success: {
        marginTop: 4,
        color: '#21BA8C',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 'normal',
    },
    error: {
        marginTop: 4,
        color: '#FF3737',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 'normal',
    },
}));

const theme = createTheme({
    palette: {
        primary: {main: infoColor},
    },
});

function Sync(props) {
    const {retailer, importLoading, importRetailer, exportLoading, exportRetailer, imported, importError} = props;
    const classes = useStyles();
    const [importFile, setImportFile] = useState(null);
    const [withRetailerSettings, setWithRetailerSettings] = useState(true);
    const [withFeeds, setWithFeeds] = useState(true);
    const [withMappingCategories, setWithMappingCategories] = useState(true);
    const [withMappingGenders, setWithMappingGenders] = useState(true);
    const [overwriteMapping, setOverwriteMapping] = useState(true);
    const [options, setOptions] = useState([]);
    const [checkedOptions, setCheckedOptions] = useState({});

    useEffect(() => {
        list('mapped=true').then((res) => {
            setOptions(res);
            const initialCheckedOptions = res.reduce((acc, option) => {
                acc[option.id] = false;

                return acc;
            }, {});
            setCheckedOptions(initialCheckedOptions);
        })
    }, [])

    // componentWillUnmount
    useLayoutEffect(() => {
        return () => {
            abort.abortRequests();
        }
    }, []);

    if (UserAgentUtils.isMobileOrTablet()) {
        return null;
    }

    if (options.length === 0) {
        return;
    }

    const handleCheckboxOption = (id) => {
        setCheckedOptions((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    return (
        <Card className={classes.card}>
            <CardBody className={classes.cardBody}>
                <Grid container>
                    <Grid
                        className={classes.importContainer}
                        item
                        xs={12}
                        sm={6}
                    >
                        <div className={classes.title}>
                            <h3><FormattedMessage id={'retailer.update.import'} /></h3>
                            <p><FormattedMessage id={'retailer.update.import.subtitle'} /></p>
                        </div>
                        <div className={classes.form}>
                            <label className={classes.label}>
                                <CustomCheckbox
                                    checked={withRetailerSettings}
                                    onClick={() => setWithRetailerSettings(!withRetailerSettings)}
                                />
                                <FormattedMessage id={'retailer.update.import.form.retailerSettings'} />
                            </label>
                            <label className={classes.label}>
                                <CustomCheckbox
                                    checked={withFeeds}
                                    onClick={() => setWithFeeds(!withFeeds)}
                                />
                                <FormattedMessage id={'retailer.update.import.form.feeds'} />
                            </label>
                            <label className={classes.label}>
                                <CustomCheckbox
                                    checked={withMappingCategories}
                                    onClick={() => setWithMappingCategories(!withMappingCategories)}
                                />
                                <FormattedMessage id={'retailer.update.import.form.categories'} />
                            </label>
                            <label className={classes.label}>
                                <CustomCheckbox
                                    checked={withMappingGenders}
                                    onClick={() => setWithMappingGenders(!withMappingGenders)}
                                />
                                <FormattedMessage id={'retailer.update.import.form.genders'} />
                            </label>
                            { Object.values(options).map(option => {
                                 return (
                                     <label key={option.id}  className={classes.label}>
                                         <CustomCheckbox
                                             checked={checkedOptions[option.id]}
                                             onClick={() => handleCheckboxOption(option.id)}
                                         />
                                         <FormattedMessage id={'retailer.update.import.form.option'} values={{option: getTranslation(option).name}} />
                                     </label>
                                 )
                            })}
                            <label className={classes.label}>
                                <Checkbox
                                    icon={<SwitchOff/>}
                                    checkedIcon={<SwitchOn/>}
                                    checked={overwriteMapping}
                                    onClick={() => setOverwriteMapping(!overwriteMapping)}
                                    inputProps={{'aria-label': 'primary checkbox'}}
                                />
                                <FormattedMessage id={'retailer.update.import.form.overwrite'} />
                            </label>
                        </div>
                        <div className={classes.importActions}>
                            <Dropzone
                                name={'file'}
                                accept='application/json'
                                multiple={false}
                                onDrop={(acceptedFiles) => setImportFile(acceptedFiles[0])}
                            >
                                {({getRootProps, getInputProps, acceptedFiles}) => {
                                    const file = acceptedFiles[0];

                                    return (
                                        <div {...getRootProps()}>
                                            <input
                                                id={'file'}
                                                name={'file'}
                                                {...getInputProps()}
                                            />
                                            {importLoading ? (
                                                <CircularProgress
                                                    className={classes.loadingIcon}
                                                    size={24}
                                                />
                                            ) : (
                                                <>
                                                    <Button
                                                        className={classNames(
                                                            classes.button,
                                                            classes.downloadButton,
                                                        )}
                                                        color='primary'
                                                        variant='contained'
                                                    >
                                                        <DocumentDownload />
                                                        <FormattedMessage id={'retailer.update.import.file.button'} />
                                                    </Button>
                                                    <span>
                                                        {file && file.name}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    )
                                }}
                            </Dropzone>
                            <div>
                                <ThemeProvider theme={theme}>
                                    <Button
                                        className={classes.button}
                                        color='primary'
                                        variant='contained'
                                        disabled={
                                            importFile === null || (
                                                !withRetailerSettings &&
                                                !withFeeds &&
                                                !checkedOptions &&
                                                !withMappingCategories &&
                                                !withMappingGenders
                                            )
                                        }
                                        onClick={() => {
                                            const config = {
                                                'withRetailerSettings': withRetailerSettings,
                                                'withFeeds': withFeeds,
                                                'withMappingCategories': withMappingCategories,
                                                'withMappingGenders': withMappingGenders,
                                                'options': checkedOptions,
                                                'overwriteMapping': overwriteMapping,
                                            };

                                            importRetailer(retailer['@id'], importFile, config);
                                        }}
                                    >
                                        <ImportImage />
                                        <FormattedMessage id={'retailer.update.import.button'} />
                                    </Button>
                                </ThemeProvider>
                                {imported && (
                                    <span className={classes.success}>
                                        <FormattedMessage id={'retailer.update.import.form.success'} />
                                    </span>
                                )}
                                {importError && (
                                    <span className={classes.error}>
                                        <FormattedMessage id={'retailer.update.import.form.error'} />
                                    </span>
                                )}
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        className={classes.separatorContainer}
                        item
                        sm={1}
                    >
                        <hr />
                    </Grid>
                    <Grid
                        className={classes.exportContainer}
                        item
                        xs={12}
                        sm={5}
                    >
                        <div className={classes.title}>
                            <h3><FormattedMessage id={'retailer.update.export'} /></h3>
                            <p><FormattedMessage id={'retailer.update.export.subtitle'} /></p>
                        </div>
                        <div>
                            {exportLoading ? (
                                <CircularProgress
                                    className={classes.loadingIcon}
                                    size={24}
                                />
                            ) : (
                                <ThemeProvider theme={theme}>
                                    <Button
                                        className={classes.button}
                                        color='primary'
                                        variant='contained'
                                        onClick={() => exportRetailer(retailer['@id'])}
                                    >
                                        <ExportImage />
                                        <FormattedMessage id={'retailer.update.export.button'} />
                                    </Button>
                                </ThemeProvider>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </CardBody>
        </Card>
    );
}

Sync.propTypes = {
    retailer: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    exportLoading: state.retailer.sync.exportLoading,
    importError: state.retailer.sync.importError,
    importLoading: state.retailer.sync.importLoading,
    imported: state.retailer.sync.imported,
})

const mapDispatchToProps = dispatch => ({
    exportRetailer: (retailerId) => dispatch(downloadExport(retailerId)),
    importRetailer: (retailerId, file, config) => dispatch(importRetailer(retailerId, file, config)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Sync);
