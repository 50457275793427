import {useEffect, useState} from "react";
import {fetch} from "utils/dataAccess";
import SimpleFilterPanel from "views/marketplace/merchandising/filter/simpleFilterPanel";

export default function useGenders() {

    const [gendersFilters, setGendersFilters] = useState(null);
    const [gendersLoaded, setGendersLoaded] = useState(false);

    useEffect(() => {
        fetch("/genders?pagination=false&draft=false").then(
            response => {
                response.json().then(retrieved => {
                    setGendersFilters(
                        {
                            "tabTitle": "genders",
                            "tabContent": {
                                component: SimpleFilterPanel,
                                items: retrieved['hydra:member'],
                            }
                        }
                    );

                    setGendersLoaded(true);
                });
            }
        );
    }, []);

    return {
        gendersLoaded,
        gendersFilters
    };
}
