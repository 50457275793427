import React, {useContext, useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import Grid from "@mui/material/Grid";

import FormBloc from "components/form/FormBloc";
import MultiImageDropField from "components/input/ImageDropField/MultiImagesDropField";
import Label from "components/input/new/Label";

import {ProductShowContext} from "../../../../context/ProductShowContext";

import {handleFileUpload} from "api/upload";

import {FileUploadType} from "utils/fileUploadType";

function VariationPictures({picturesToDelete, setPicturesToDelete}) {
    const {setFieldValue} = useFormikContext();

    const {selectedVariation} = useContext(ProductShowContext);

    const [pictures, setPictures] = useState([]);

    // on variation change, load his pictures
    useEffect(() => {
        setPictures(selectedVariation?.pictures ?? [])
    }, [selectedVariation])

    // when picture list change, update form value
    // it persists new position too
    useEffect(() => {
        setFieldValue('pictures', pictures);
    }, [pictures])

    function addFileToPicturesSlider(file) {
        setPictures( [...pictures, file]);
    }

    function removeFileToPicturesSlider(fileIndex) {
        let newPictures = [...pictures];

        setPicturesToDelete([...picturesToDelete, newPictures[fileIndex]])

        newPictures.splice(fileIndex, 1);

        setPictures(newPictures);
    }

    return (
        <FormBloc titleId='product.show.variation.form.pictures.title'>
            <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <Label htmlFor='pictures'>
                        <FormattedMessage id='product.show.variation.form.field.pictures' />
                    </Label>
                    <MultiImageDropField
                        pictures={pictures}
                        onPicturesReordered={(orderedPictures) => setPictures(orderedPictures)}
                        onPictureDelete={(fileIndex) => removeFileToPicturesSlider(fileIndex)}
                        onPicturesDrop={(acceptedFiles, removeLoader) => {
                            handleFileUpload(
                                acceptedFiles,
                                FileUploadType.PRODUCT_PICTURE,
                                selectedVariation?.id
                            ).then(response => {
                                removeLoader();
                                addFileToPicturesSlider(response)
                            })
                        }}
                    />
                </Grid>
            </Grid>
        </FormBloc>
    );
}

export default VariationPictures;
