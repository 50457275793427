import React from 'react';

import Grid from "@mui/material/Grid";

import FormBloc from "components/form/FormBloc";

import BrandField from "./components/BrandField";
import CornerField from "./components/CornerField";
import ExternalIdField from "./components/ExternalIdField";
import SkuField from "./components/SkuField";

function ProductReferences(props) {
    return (
        <FormBloc titleId='product.show.product.form.reference.title'>
            <Grid container rowSpacing={3}>
                <Grid item container columnSpacing={2}>
                    <Grid item md={6} xs={12}>
                        <SkuField />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <ExternalIdField />
                    </Grid>
                </Grid>
                <Grid item container columnSpacing={2}>
                    <Grid item md={6} xs={12}>
                        <BrandField />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <CornerField />
                    </Grid>
                </Grid>
            </Grid>
        </FormBloc>
    );
}

export default ProductReferences;
