import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {connectRouter, routerMiddleware} from "connected-react-router";
import thunk from "redux-thunk";
import {reducer as form} from "redux-form";
import {createBrowserHistory} from "history";

// Reducers
import authenticationReducer from "./reducers/authentication";
import feedReducer from "./reducers/feed";
import mapperReducer from "./reducers/feed/mapper";
import retailerReducer from "./reducers/retailer";
import uploadReducer from "./reducers/upload";
import productReducer from "./reducers/product";
import brandReducer from "./reducers/brand";
import analyticsReducer from "./reducers/analytics";
import categoryReducer from "./reducers/category";
import variationReducer from "./reducers/variation";
import genderReducer from "./reducers/gender";
import physicalEntryReducer from "./reducers/physicalEntry";
import attributeReducer from "./reducers/feed/attribute";
import taxRateReducer from "./reducers/taxRate";
import orderReducer from "./reducers/order";
import noteReducer from "./reducers/order/note";
import lineItemActivityReducer from "./reducers/order/lineItemActivity";
import lineItemReducer from "./reducers/lineItem";
import productLockReducer from "./reducers/product";
import variationLockReducer from "./reducers/variation";
import eshopConfigReducer from "./reducers/eshopConfig";
import itemsPerPageReducer from "./reducers/itemsPerPage";
import dashboardReducer from "./reducers/dashboard";
import pickingDashboardReducer from "./reducers/picking/dashboard";
import sectorReducer from "./reducers/sector";
import profileReducer from "./reducers/profile";
import notificationReducer from "./reducers/notification";
import memberReducer from "./reducers/member";
import roleReducer from "./reducers/role";
import authorizationsReducer from "./reducers/authorization";
import reportReducer from "./reducers/report";
import stockEasyReducer from "./reducers/stockeasy";
import dictionariesReducer from "./reducers/dictionaries";
import currentOrganizationReducer from "./reducers/organization";
import cityReducer from "./reducers/city";
import organizationCategoryReducer from "./reducers/organizationCategory"


const browserHistory = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(browserHistory),
    form,
    authentication: authenticationReducer,
    feed: feedReducer,
    mapper: mapperReducer,
    retailer: retailerReducer,
    upload: uploadReducer,
    product: productReducer,
    brand: brandReducer,
    analytics: analyticsReducer,
    category: categoryReducer,
    organizationCategory: organizationCategoryReducer,
    variation: variationReducer,
    gender: genderReducer,
    physicalEntry: physicalEntryReducer,
    attribute: attributeReducer,
    taxRate: taxRateReducer,
    order: orderReducer,
    note: noteReducer,
    lineItemActivity: lineItemActivityReducer,
    lineItem: lineItemReducer,
    productLock: productLockReducer,
    variationLock: variationLockReducer,
    eshopConfig: eshopConfigReducer,
    itemsPerPage: itemsPerPageReducer,
    dashboard: dashboardReducer,
    pickingDashboard: pickingDashboardReducer,
    sector: sectorReducer,
    profile: profileReducer,
    notification: notificationReducer,
    member: memberReducer,
    role: roleReducer,
    authorization: authorizationsReducer,
    report: reportReducer,
    stockEasy: stockEasyReducer,
    dictionaries: dictionariesReducer,
    currentOrganization: currentOrganizationReducer,
    city: cityReducer,
    /* Add your reducers here */
});

const rootReducer = (state, action) => {
    if (action.type === "LOGOUT_SUCCESS") {
        state = undefined;
    }

    return appReducer(state, action)
};

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(
            routerMiddleware(browserHistory),
            thunk
        )
    ),
);

export default store;
export const history = browserHistory;
