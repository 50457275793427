import React from 'react';
import PropTypes from 'prop-types';

import {Grid} from '@mui/material';

import FormTitle from 'components/form/FormTitle';
import FormSubtitle from "components/form/FormSubtitle";

FormBloc.propTypes = {
    titleId: PropTypes.string.isRequired,
    subtitleId: PropTypes.string,
};

function FormBloc({titleId, subtitleId, ...props}) {
    return (
        <Grid item xs={12}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}>
                    <FormTitle titleId={titleId} />
                    {subtitleId && <FormSubtitle subtitleId={subtitleId} />}
                </Grid>
                <Grid item xs={12}>
                    {props.children}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default FormBloc;
