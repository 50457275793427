import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import IconButton from "components/button/IconButton";
import GalleryAddIcon from "components/icons/GalleryAddIcon";
import TrashIcon from "components/icons/TrashIcon";

import Image from "./components/Image";
import ActionsContainer from "./components/ActionsContainer";
import PreviewContainer from "./components/PreviewContainer";
import Loading from "../Loading";

import {ImageDropFieldContext} from "../../context/ImageDropFieldContext";

Index.propTypes = {
    imgSrc: PropTypes.string.isRequired,
    onDelete: PropTypes.func,
    isMulti: PropTypes.bool,
};

function Index({imgSrc, onDelete = () => {}, isMulti = false}) {
    const {inputProps, importLoading, openFileSelector, disabled} = useContext(ImageDropFieldContext);

    return (
        <PreviewContainer isMulti={isMulti}>
            <Image src={imgSrc} />
            {(!importLoading || isMulti) && (
                <ActionsContainer>
                    <input {...inputProps} />
                    {!isMulti && (
                        <IconButton disabled={disabled} onClick={openFileSelector}>
                            <GalleryAddIcon />
                        </IconButton>
                    )}
                    <IconButton disabled={disabled} onClick={(e) => {
                        e.stopPropagation();
                        onDelete();
                    }}>
                        <TrashIcon />
                    </IconButton>
                </ActionsContainer>
            )}
            {!isMulti && (
                <Loading loading={importLoading} />
            )}
        </PreviewContainer>
    );
}

export default Index;
