import React from 'react';
import PropTypes from 'prop-types';

import Grid from "@mui/material/Grid";

import {black500} from "assets/jss/main";

Detail.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any
};

function Detail({name, value}) {
    return (
        <Grid
            item
            container
            justifyContent='space-between'
            sx={{
                fontSize: '14px',
                fontWeight: 400,
                color: black500
            }}
        >
            <Grid item>
                {name}:
            </Grid>
            <Grid item>
                {value}
            </Grid>
        </Grid>
    );
}

export default Detail;
