export function objectsHaveDifferentValues(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the objects have the same number of keys and the same keys
    if (keys1.length !== keys2.length) {
        return true;
    }

    // Check if any of the values are different
    return keys1.some(key => obj1[key] !== obj2[key]);
}
