import React from 'react';
import PropTypes from 'prop-types';

OptionValueName.propTypes = {
    name: PropTypes.string
};

function OptionValueName({name}) {
    return (
        <b style={{
            textAlign: "right",
            float: "right",
            marginTop: "-5px"
        }}>
            {name}
        </b>
    );
}

export default OptionValueName;
