import React from 'react';

function ArrowRight(props) {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.99421 0.282405L8.97086 3.69763C9.97858 4.27348 9.97858 5.72652 8.97086 6.30236L2.99421 9.71759C1.99422 10.289 0.750001 9.56696 0.750001 8.41523L0.750001 1.58477C0.750001 0.433036 1.99422 -0.289015 2.99421 0.282405Z" fill="#333333"/>
        </svg>
    );
}

export default ArrowRight;
