import React from 'react';
import PropTypes from 'prop-types';

import {black500} from "assets/jss/main";

ProductName.propTypes = {
    name: PropTypes.string
};

function ProductName({name}) {
    return (
        <div style={{
            fontSize: '16px',
            fontWeight: 600,
            color: black500
        }}>
            {name}
        </div>
    );
}

export default ProductName;
