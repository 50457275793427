import React, {useState} from "react";
import {Draggable} from "react-beautiful-dnd";

// @mui/material components
import {Checkbox, IconButton} from "@mui/material";

// @mui/icons-material
import { MoreVert, SubdirectoryArrowRightRounded } from "@mui/icons-material";

// core components
import CustomDate from 'components/date/date';

// styles
import genderStyle from "assets/jss/views/configuration/defaultAttributeRowStyle";
import {getTranslation} from "../../../domain/helpers/translations";
import {withStyles} from "@mui/styles";

import CreateBelow from '../../configuration/components/CreateBelow';
import CreateDialog from './createDialog';

function Gender (props) {
    const {
        allGenders,
        checkedGenders,
        classes,
        data,
        draggableId,
        index,
        openPopover,
        search,
        selectGender,
        setGenderTablePosition,
        setCheckedGenders,
    } = props;

    const isChecked = checkedGenders.some(item => item['@id'] === data['@id']);

    const [createDialog, showCreateDialog] = useState(false);
    const [createBelow, showCreateBelow] = useState(false);

    function showPopover(e) {
        e.stopPropagation();
        selectGender(data);
        setGenderTablePosition(e.currentTarget);
        openPopover();
    }

    function checkGender(item) {
        const isItemAlreadyChecked = checkedGenders.some(itemAdded => itemAdded['@id'] === item['@id']);

        if (!isItemAlreadyChecked) {
            setCheckedGenders([...checkedGenders, item]);
        } else {
            let newSelected = checkedGenders.filter((d) => !(d['@id'] === item['@id']));

            setCheckedGenders(newSelected);
        }
    }

    function isGenderMatchWithSearch(gender) {
        return !!getTranslation(gender).value.toLowerCase().includes(search);
    }

    function elementClasses(gender) {
        if(search.length === 0){
            return classes.element
        }

        if (search.length > 0 && isGenderMatchWithSearch(gender) ) {
            return classes.elementMatch
        } else {
            return classes.elementHidden
        }
    }


    function nameClasses() {
        return classes.attributeNameSelected;
    }

    return (
        <div
            onMouseEnter={() => showCreateBelow(true)}
            onMouseLeave={() => showCreateBelow(false)}
        >
            <Draggable index={index} draggableId={draggableId}>
                {provided => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        {data.parent && <SubdirectoryArrowRightRounded className={classes.actionsButtonContainer} />}
                        <li className={elementClasses(data)}>
                            <div className={classes.name}>
                                <Checkbox
                                    checked={isChecked}
                                    classes={{root: classes.checkbox, checked: classes.checked}}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={() => checkGender(data)}
                                />
                                <span className={nameClasses()}>
                                    {getTranslation(data).value}
                                </span>
                            </div>
                            <div className={classes.actionsButtonContainer}>
                                <CustomDate showRelativeDate={true} date={data.updatedAt}/>
                                <IconButton
                                    className={classes.iconButtonMargin}
                                    onClick={(e) => showPopover(e)}
                                    size={"small"}
                                >
                                    <MoreVert/>
                                </IconButton>
                            </div>
                            <CreateDialog
                                closeDialog={() => {
                                    showCreateBelow(false);
                                    showCreateDialog(false);
                                }}
                                isOpen={createDialog}
                                allGenders={allGenders}
                                position={index + 1}
                            />
                        </li>
                    </div>
                )}
            </Draggable>
            <CreateBelow
                onClick={() => showCreateDialog(true)}
                isVisible={createBelow}
            />
        </div>
    );
}

export default withStyles(genderStyle)(Gender);
