import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from "react-intl";
import {useFormikContext} from "formik";

import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";

AdditionalAttributeField.propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    localeCode: PropTypes.string,
};

function AdditionalAttributeField({name, required, localeCode, ...props}) {
    const {values, handleChange, handleBlur, touched, errors} = useFormikContext()

    return (
        <>
            <Label required={!!required} htmlFor={`translations.${localeCode}.additionalAttributes.${name}`}>
                <FormattedMessage id={`product.show.attribute.${name}`} />
            </Label>
            <InputTextField
                name={`translations.${localeCode}.additionalAttributes.${name}`}
                onChange={handleChange}
                value={values.translations?.[localeCode]?.additionalAttributes[name] ?? ''}
                placeholder={props.intl.formatMessage({id: 'product.show.product.form.field.name.placeholder'})}
                fullWidth
                onBlur={handleBlur}
                error={touched.translations?.[localeCode]?.additionalAttributes[name] && Boolean(errors.translations?.[localeCode]?.additionalAttributes[name])}
                helperText={touched.translations?.[localeCode]?.additionalAttributes[name] && errors.translations?.[localeCode]?.additionalAttributes[name]}
            />
        </>
    );
}

export default injectIntl(AdditionalAttributeField);
