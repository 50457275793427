import React from "react";

import {Button} from "@mui/material";

import {styled} from '@mui/material/styles';

import {
    dangerColor,
    dangerColorDisabled,
    dangerColorHover, greyishBlue200, greyishBlue50, red500, red600
} from "assets/jss/main";

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: red500,
    borderRadius: '8px',
    textTransform: 'none',
    padding:'12px 16px',
    lineHeight: 'normal',
    [theme.breakpoints.down('md')]: {
        width: '100%'
    },
    '&:hover': {
        backgroundColor: red600
    },
    '&.Mui-disabled' : {
        backgroundColor: greyishBlue50,
        color: greyishBlue200
    }
}));

export default function DeleteButton({...props}) {
    return (
        <StyledButton
            variant="contained"
            disableElevation
            {...props}
        >
            {props.children}
        </StyledButton>
    )
}
