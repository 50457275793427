import React from "react";
import {Route} from "react-router-dom";
import Corners from "../../views/corners/corners";
import RequireAuthentication from "../../hoc/authentication/requireAuthentication";
import Cookies from "js-cookie";

let routes = [];
const token = Cookies.get("_f") ? JSON.parse(Cookies.get("_f").toString()) : null;

if (token) {
    routes.push([
        <Route
            path="/corners"
            component={RequireAuthentication(Corners)}
            key="corners"
            exact
        />,
    ])
}

export default routes;
