import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {styled} from "@mui/styles";
import {BadgeContext} from "../BadgeContext";

Label.propTypes = {

};

const StyledLabel = styled('span')(({ theme, color, size }) => ({
    lineHeight: 'normal',
    color: theme.palette[color]?.main ?? color,
    fontSize: '12px',
    fontWeight: size === 'large' || color === 'outline' ? 400 : 600
}));

function Label({label}) {
    const {color, labelColor, size} = useContext(BadgeContext);

    return (
        <StyledLabel color={labelColor ?? color} size={size}>
            {label}
        </StyledLabel>
    );
}

export default Label;
