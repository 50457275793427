import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";

// @mui/material components
import {Chip, TextField, InputAdornment, MenuItem} from "@mui/material";

// @mui/icons-material
import {Edit, ExpandLess, ExpandMore} from "@mui/icons-material";

// core components
import Button from "components/button/button";
import Card from "components/card/card";
import CardBody from "components/card/cardBody";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import ButtonsWrapper from "../button/buttonsWrapper";

// styles
import CreateProductFormStyle from "assets/jss/views/stockeasy/product/create/form/createProductFormStyle";

// actions
import {PRICE} from "../../../../../../domain/enum/regexs";

import localStorageSE from "../localStorageManager/localStorageSE";
import {withStyles} from "@mui/styles";
import {getTranslation} from "../../../../../../domain/helpers/translations";
class PriceStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productData: localStorageSE.getProductData(),
            showDetails: false,
            isStepValid: true,
        }
    }

    toggleDetails() {
        this.setState({showDetails: !this.state.showDetails});
    }

    countVariationSelected(product){
        let count = 0;
        product.variations.forEach(variation => {
            if (variation.selected && !variation.default){
                count++;
            }
        });

        return count;
    }

    updateGlobalPrice(newPrice) {
        if (!PRICE.test(newPrice)) {
            return;
        }
        const {productData} = this.state;

        productData.price = newPrice;

        // update all declination
        productData.variations.forEach(variation => {
            variation.price = newPrice;
        })

        this.setState({productData});
    }

    updateGlobalOutletPrice(newOutletPrice) {
        if (!PRICE.test(newOutletPrice)) {
            return;
        }
        const {productData} = this.state;

        productData.outletPrice = newOutletPrice;

        // update all declination
        productData.variations.forEach(variation => {
            variation.outletPrice = newOutletPrice;
        })

        this.setState({productData});
    }

    updateGlobalTva(newTva) {
        const {productData} = this.state;

        productData.tva = newTva;

        this.setState({productData});
    }

    updateVariationSizePrice(variationIndex, newPrice) {
        if (!PRICE.test(newPrice)) {
            return;
        }
        const {productData} = this.state;

        productData.variations[variationIndex].price = newPrice;

        this.setState({productData});
    }

    updateVariationSizeOutletPrice(variationIndex, newOutletPrice) {
        if (!PRICE.test(newOutletPrice)) {
            return;
        }
        const {productData} = this.state;

        productData.variations[variationIndex].outletPrice = newOutletPrice;

        this.setState({productData});
    }

    renderGlobalPrices() {
        const {classes, intl} = this.props;
        const {productData} = this.state;

        let taxRates = [];
        let availableTaxes = [];
        if (this.props.dictionaries) {
            this.props.dictionaries["hydra:member"].forEach((dictionary) => {
                if (dictionary['type'] === "taxRate") {
                    taxRates = dictionary;
                }
            })

            taxRates['items'].forEach((taxRate) => {
                if (taxRate['value'] !== 0) {
                    availableTaxes = availableTaxes.concat([taxRate['value']]);
                }
            })

            const retailerId = this.props.retailerId
            this.props.retailers.forEach((retailer) => {
                if (retailer['@id'] === '/retailers/' + retailerId) {
                    if (retailer['taxRateZero'] === true) {
                        availableTaxes = [0];
                    }
                }
            });
        }

        return (
            <div>
                <div className={classes.stepTitle}>{intl.formatMessage({id: "stockeasy.price.globalPrice"})} :</div>
                <GridContainer direction="row" justifyContent="space-between" alignItems="flex-end">
                    <GridItem sm={4}>
                        <div className={classes.labelWrapper}>
                            <label htmlFor={"price"} className={classes.label}>
                                <FormattedMessage id={"stockeasy.price.included_taxes"}/>
                            </label>
                        </div>
                        <TextField
                            placeholder={intl.formatMessage({id: "stockeasy.price.price.placeholder"})}
                            id={"price"}
                            variant="outlined"
                            onChange={e => {
                                const price = e.target.value.replace('.', ',');
                                this.updateGlobalPrice(price);
                            }}
                            value={productData.price <= 0 ? '' : productData.price}
                            InputLabelProps={{shrink: true}}
                            fullWidth
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment className={classes.desktopOnly} position="end">
                                        <Edit fontSize={"small"}/>
                                    </InputAdornment>
                                )
                            }}
                            className={classes.textField}
                        />
                    </GridItem>
                    <GridItem sm={4}>
                        <div className={classes.labelWrapper}>
                            <label htmlFor={"outletPrice"} className={classes.label}>
                                <FormattedMessage id={"stockeasy.price.outlet.label"} />
                            </label>
                        </div>
                        <TextField
                            placeholder={intl.formatMessage({id: "stockeasy.price.outlet.placeholder"})}
                            id={"outletPrice"}
                            variant="outlined"
                            fullWidth
                            onChange={e => {
                                const price = e.target.value.replace('.', ',');
                                this.updateGlobalOutletPrice(price);
                            }}
                            value={productData.outletPrice <= 0 ? '' : productData.outletPrice}
                            InputLabelProps={{shrink: true}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment className={classes.desktopOnly} position="end">
                                        <Edit fontSize={"small"}/>
                                    </InputAdornment>
                                ),
                            }}
                            className={classes.textField}
                        />
                    </GridItem>
                    <GridItem sm={4}>
                        <div className={classes.labelWrapper}>
                            <label htmlFor={"tva"} className={classes.label}>
                                <FormattedMessage id={"stockeasy.price.vat"}/>
                            </label>
                        </div>
                        <TextField
                            id={"tva"}
                            variant="outlined"
                            select
                            fullWidth
                            onChange={(e) => this.updateGlobalTva(e.target.value)}
                            value={productData.tva <= 0 ? availableTaxes.at(-1) : productData.tva}
                            InputLabelProps={{shrink: true}}
                            required
                            className={classes.textField}
                        >
                            {availableTaxes.map((taxAmount) => (
                                <MenuItem key={taxAmount} value={taxAmount}>
                                    {taxAmount + ' %'}
                                </MenuItem>
                            ))}
                        </TextField>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }

    renderMoreDetailsButton() {
        const {classes, intl} = this.props;
        const {productData} = this.state;

        if(this.countVariationSelected(productData) > 0) {
            return (
                <GridContainer className={classes.detailsAction}>
                    <GridItem>
                        <Button
                            color={"primary"}
                            simple
                            onClick={() => this.toggleDetails()}
                        >
                            {this.state.showDetails === true ? intl.formatMessage({id: "stockeasy.product.show.less"}) : intl.formatMessage({id: "stockeasy.product.show.more"})}
                            {this.state.showDetails === true ?  <ExpandLess/> : <ExpandMore/>}

                        </Button>
                    </GridItem>
                </GridContainer>
            )
        }
    }

    renderVariationTitle(variation, variationIndex) {
        const {classes} = this.props;
        return <div className={classes.optionContentPrice} key={`vtitle${variationIndex}`}>
            {this.displayOptions(variation)}
        </div>
    }

    getOptionTitle(optionId) {
        const {options} = this.props;

        const option = Object.keys(options).find(item => options[item].option.id === optionId);
        if (option) {
            return getTranslation(options[option].option).name;
        }
    }

    displayOptions(variation) {
        const {classes} = this.props;

        return (
            Object.keys(variation.options).map(item => {

                return (
                    <span className={classes.stockOptionTitle}>
                        <span className={classes.strong}>{this.getOptionTitle(item)}</span> : <strong>{variation.options[item]}</strong>
                    </span>
                );
            })
        )
    }

    renderVariationPrice(variation, variationIndex) {
        const {classes, intl} = this.props;

        return (
            <div key={variationIndex} className={classes.variation}>
                <div className={classes.variationName}>
                    {this.renderVariationTitle(variation, variationIndex)}
                </div>

                <GridContainer
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    key={variation.id}
                    spacing={1}
                >
                    <GridItem xs={4} sm={4}>
                        <div className={classes.labelWrapper}>
                            <label htmlFor={"price"} className={classes.label}>
                                <FormattedMessage id={"stockeasy.price.included_taxes"} />
                            </label>
                        </div>
                        <TextField
                            placeholder={intl.formatMessage({id: "stockeasy.price.price.placeholder"})}
                            id={"price"}
                            variant="outlined"
                            fullWidth
                            onChange={e => {
                                const price = e.target.value.replace('.', ',');
                                this.updateVariationSizePrice(variationIndex, price);
                            }}
                            value={variation.price <= 0 ? 0 : variation.price}
                            InputLabelProps={{shrink: true}}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment className={classes.desktopOnly} position="end">
                                        <Edit fontSize={"small"}/>
                                    </InputAdornment>
                                ),
                                min: 1
                            }}
                            className={classes.textField}

                        />
                    </GridItem>
                    <GridItem xs={4} sm={4}>
                        <div className={classes.labelWrapper}>
                            <label htmlFor={"outletPrice"} className={classes.label}>
                                <FormattedMessage id={"stockeasy.price.outlet.label"} />
                            </label>
                        </div>
                        <TextField
                            placeholder={intl.formatMessage({id: "stockeasy.price.outlet.placeholder"})}
                            id={"outletPrice"}
                            variant="outlined"
                            fullWidth
                            onChange={e => {
                                const price = e.target.value.replace('.', ',');
                                this.updateVariationSizeOutletPrice(variationIndex, price);
                            }}
                            value={variation.outletPrice <= 0 ? '' : variation.outletPrice}
                            InputLabelProps={{shrink: true}}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment className={classes.desktopOnly} position="end">
                                        <Edit fontSize={"small"}/>
                                    </InputAdornment>
                                ),
                                min: 0, max: variation.price
                            }}
                            className={classes.textField}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        )
    }

    handleConfirmStep() {
        const {productData} = this.state;

        let isStepValid = true;
        let errorMessage = 'Les prix ne peuvent être inférieurs à 0.';

            //check if all prices have a correct value
        if (productData.price <= 0) {
            isStepValid = false;
        }

        if (isStepValid) {
            productData.variations.map(variation => {
                if (variation.price <= 0) {
                    isStepValid = false;
                }
            })
        }

        // check validations
        if (isStepValid) {
            localStorageSE.updateProductData(productData);
            this.props.goToNextStep(); // redirect to product step
        } else {
            this.setState({
                errorMessage: errorMessage
            });
        }

        localStorageSE.updateProductData(productData);
        this.props.goToNextStep(); // redirect to product step
    }

    render() {
        const {productData} = this.state;
        const {intl} = this.props;

        return (
                <Card>
                    <CardBody>
                        {this.renderGlobalPrices()}
                        {this.renderMoreDetailsButton()}

                        {this.state.showDetails === true && productData.variations.map((variation, index) => {
                            if (variation.selected) {
                                return this.renderVariationPrice(variation, index);
                            }
                        })}
                        <GridContainer>
                            <ButtonsWrapper
                                goToPreviousStep={this.props.goToPreviousStep}
                                goToStep={this.props.go}
                                firstButtonContent={intl.formatMessage({id: "stockeasy.button.continue"})}
                                submit={() => this.handleConfirmStep()}
                                isFormValid = {this.state.isStepValid}
                                step={3}
                            />
                        </GridContainer>
                    </CardBody>
                </Card>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        product: state.stockEasy.request.retrieved,
        retailers: state.authentication.member.retailers,
        dictionaries: state.dictionaries.list.retrieved
    };
};

export default connect(
    mapStateToProps,
)(withStyles(CreateProductFormStyle)(injectIntl(PriceStep)));
