import React from 'react';

/**
 * @deprecated use src/components/containers/ActionsContainer
 */
function ActionsContainer(props) {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '12px',
            alignItems: 'baseline',
        }}>
            {props.children}
        </div>
    );
}

export default ActionsContainer;
