import React from 'react';

import {styled} from "@mui/material/styles";

import {TextField} from "@mui/material";

import {
    black500,
    blue50,
    blueColor,
    greyishBlue200,
    greyishBlue400,
    greyishBlue50,
    greyishBlue500,
    red50,
    red500,
} from "assets/jss/main";

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        borderRadius: '8px',
        color: black500,
        fontSize: '14px',
        paddingRight: 'unset', // no extra padding for InputAdornment
        input: {
            padding: '16px',
            "&::placeholder": {
                color: greyishBlue400
            },
        },
        '& fieldset': {
            borderColor: greyishBlue200,
        },
        '&:hover fieldset': {
            borderColor: greyishBlue500,
        },
        '&.Mui-focused fieldset': {
            boxShadow: `0px 0px 0px 2px ${blue50}`,
            border: `1px solid ${blueColor}`,
        },
        '&.Mui-disabled': {
            background: greyishBlue50,
            "& .MuiInputBase-input": {
                WebkitTextFillColor: greyishBlue500,
            },
            '& fieldset': {
                borderColor: 'transparent',
            },
        },
        '&.Mui-error': {
            background: red50,
            '& fieldset': {
                boxShadow: 'unset',
                borderColor: red500,
            },
        },
    },
    // trick to handle error message style in input css
    '.MuiInputBase-root ~ p.MuiFormHelperText-root': {
        marginLeft: 0
    }
}));

function InputTextField(props) {
    return (
        <StyledTextField {...props}>
            {props.children}
        </StyledTextField>
    );
}

export default InputTextField;
