import {useEffect, useState} from "react";
import {fetch} from "utils/dataAccess";
import SimpleFilterPanel from "views/marketplace/merchandising/filter/simpleFilterPanel";
import {getTranslation} from "../../../../domain/helpers/translations";

export default function useOptions() {

    const [optionsFilters, setOptionsFilters] = useState(null);
    const [optionsLoaded, setOptionsLoaded] = useState(false);

    useEffect(() => {
        fetch("/options_merchandising_list").then(
            response => {
                response.json().then(retrieved => {
                    // Adding missing API property
                    retrieved['hydra:member'].forEach((item) => {
                        item['@type'] = 'RetailerOptionValue';
                    });

                    const groupedByOptionName = retrieved['hydra:member'].reduce((acc, item) => {
                        const optionName = getTranslation(item).name;
                        if (!acc[optionName]) {
                            acc[optionName] = item.optionsMerchandising.map(option => ({
                                ...option,
                                optionId: item.id
                            }));
                        }

                        return acc;
                    }, {});

                    const optionsFilters = Object.keys(groupedByOptionName).map(optionName => ({
                        tabTitle: optionName,
                        tabContent: {
                            component: SimpleFilterPanel,
                            items: groupedByOptionName[optionName],
                        }
                    }));

                    setOptionsFilters(optionsFilters);

                    setOptionsLoaded(true);
                });
            }
        );
    }, []);

    return {
        optionsLoaded,
        optionsFilters
    };
}
