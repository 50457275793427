import {fetch, normalize} from "utils/dataAccess";
import normalizeTranslations from "actions/translations";
import persist from "./persister";
import {convertMediaObjectsToVariationMediaObjects} from './variationMediaObject';

export default function create(values)  {
    let data = persist(values);

    delete data['pictures']; // pictures must be created after variation creation

    data = normalizeTranslations(data);

    return new Promise((resolve, reject) => {
        fetch('/variations', {
            method: "POST",
            body: JSON.stringify(data),
        }).then(response => response.json()).then(async (retrieved) => {
            if (values.pictures.length > 0) {
                retrieved.pictures = await convertMediaObjectsToVariationMediaObjects(retrieved['@id'], values.pictures);
            }
            resolve(normalize(retrieved));
        }).catch(error => reject(error.message));
    });
}
