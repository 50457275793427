import React from 'react';
import PropTypes from 'prop-types';

import {styled} from "@mui/material/styles";

import CustomCard from "components/card/card";

Card.propTypes = {
    fullPage: PropTypes.bool,
    noShadow: PropTypes.bool
};

const StyledCard = styled(CustomCard)(({ theme, fullPage, noShadow}) => ({
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0), 0 3px 1px -2px rgba(0, 0, 0, 0.01), 0 1px 5px 0 rgba(0, 0, 0, 0.13)",
    ...noShadow && {
        boxShadow: 'unset !important', // remove !important when this component will replace "components/card/card"
    },
    ...fullPage && {
        minHeight: `${window.innerHeight * 0.94}px`
    },
}));

function Card({fullPage, noShadow, ...props}) {
    return (
        <StyledCard fullPage={noShadow} noShadow={noShadow} {...props}>
            {props.children}
        </StyledCard>
    );
}
export default Card;
