import React from 'react';
import PropTypes from 'prop-types';

import StepCount from "./StepCount";

import {primaryColor} from "assets/jss/main";

StepName.propTypes = {
    name: PropTypes.string,
    count: PropTypes.number,
    loading: PropTypes.bool,
    isStepSelected: PropTypes.bool
};

function StepName({name, count, loading, isStepSelected}) {
    const styleName = isStepSelected ? {
        color: primaryColor
    } : {}

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }}>
            <span style={styleName}>{name}</span>
            <StepCount
                count={count}
                loading={loading}
                isStepSelected={isStepSelected}
            />
        </div>
    );
}

export default StepName;
