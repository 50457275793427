import React, {useContext} from "react";
import Cookies from "js-cookie";
import {FormattedMessage, injectIntl} from "react-intl";
import {CircularProgress, Tooltip, } from "@mui/material";
import {withStyles} from "@mui/styles";
import {styled} from "@mui/material/styles";
import moment from "moment";
import {QuoteContext} from "../../../../../contexts/quoteContext";
import detailStyle from "../../../../../assets/jss/views/quote/detailStyle";
import {
    WAITING_REVIEW,
    REVIEW_IN_PROGRESS,
    WAITING_CUSTOMER,
    UPDATING_QUOTE,
    ACCEPTED,
    REFUSED,
    EXPIRED
} from "../../../enums/state";
import {
    quoteAccepted,
    quoteExpired,
    quoteInProgress,
    quoteRefused,
    quoteUpdating,
    quoteWaitingCustomer,
    quoteWaitingReview,
} from "assets/jss/views/quote/colors";
import Button from "@mui/material/Button";
import {BeatLoader} from "react-spinners";

const StyledDisplayState = styled('div')({
    display: 'inline-block',
    height: '40px',
    padding: '12px 16px',
    marginLeft: '9px',
    borderRadius: '8px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
});

const QuoteDetailHeaderInfos = ({ classes, intl }) => {
    const { quote, isProcessing , isSubmitting} = useContext(QuoteContext);
    // const authenticatedUserData = JSON.parse(Cookies.get("_s").toString());
    const member = Cookies.get("_c") ? JSON.parse(Cookies.get("_c").toString()) : null;

    const displayLoader = () => {
        return (
            <Tooltip
                title={intl.formatMessage({id: "picking.quote.detail.header.processing"})}
                className={classes.processingLoader}
            >
                <CircularProgress />
            </Tooltip>
        );
    }

    const displayState = () => {
        let stateData = null;
        switch (quote.state) {
            case WAITING_REVIEW:
                stateData = ['picking.quote.detail.header.state.waiting', quoteWaitingReview, '#222222'];
                break;
            case WAITING_CUSTOMER:
                stateData = ['picking.quote.detail.header.state.waiting_customer', quoteWaitingCustomer, '#FFFFFF'];
                break;
            case REVIEW_IN_PROGRESS:
                stateData = ['picking.quote.detail.header.state.in_progress', quoteInProgress, '#222222'];
                break;
            case UPDATING_QUOTE:
                stateData = ['picking.quote.detail.header.state.updating_quote', quoteUpdating, '#222222'];
                break;
            case ACCEPTED:
                stateData = ['picking.quote.detail.header.state.accepted', quoteAccepted, '#FFFFFF'];
                break;
            case REFUSED:
                stateData = ['picking.quote.detail.header.state.refused', quoteRefused, '#FFFFFF'];
                break;
            case EXPIRED:
                stateData = ['picking.quote.detail.header.state.expired', quoteExpired, '#FFFFFF'];
                break;
            default:
                return null;
        }

        return (
            <StyledDisplayState style={{backgroundColor: stateData[1], color: stateData[2]}}>
                <FormattedMessage id={stateData[0]} />
            </StyledDisplayState>
        );
    };
    const context = useContext(QuoteContext);

    return (
        <div>
            <p>
                <strong>
                    {intl.formatMessage({id: "picking.quote.detail.header.quote"})}
                    <span className={classes.highlight}>#{quote.number}</span>
                </strong>{displayState()} {isProcessing && displayLoader()}<br/>
                {moment(quote.createdAt).format('dddd, D MMM YYYY')}<br/>
                {moment(quote.createdAt).format('HH:mm')}
            </p>
            <p>
                {!!quote.assignedAt && (
                       <span>{intl.formatMessage({id: "picking.quote.detail.header.assignedAt"})} {quote.assignedAt?.user.fullName}</span>
                )}
                {(!quote.assignedAt || quote.assignedAt.user.email !== member.email) && (
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={context.assignQuote}
                        className={classes.assign}
                    >
                        <span>{intl.formatMessage({id: "picking.quote.detail.header.assign_to_myself"})}</span>
                        <div className={classes.submitLoading}>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={4}
                                color={"#FFF"}
                                loading={isSubmitting}
                            />
                        </div>
                    </Button>
                )}
            </p>
        </div>
    );
};

export default withStyles(detailStyle)(injectIntl(QuoteDetailHeaderInfos));
