import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import InputAdornment from "@mui/material/InputAdornment";

import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";

import LockFieldButton from "../../../../LockFieldButton";

NameField.propTypes = {
    localeCode: PropTypes.string.isRequired
};

function NameField({localeCode, ...props}) {
    const {values, handleChange, handleBlur, touched, errors, setFieldValue} = useFormikContext();

    return (
        <>
            <Label required htmlFor={`translations.${localeCode}.name`}>
                <FormattedMessage id='product.show.variation.form.field.name' />
            </Label>
            <InputTextField
                name={`translations.${localeCode}.name`}
                onChange={handleChange}
                value={values.translations[localeCode]?.name ?? ''}
                placeholder={props.intl.formatMessage({id: 'product.show.variation.form.field.name.placeholder'})}
                fullWidth
                onBlur={handleBlur}
                error={touched.translations?.[localeCode].name && Boolean(errors.translations?.[localeCode]?.name)}
                helperText={touched.translations?.[localeCode].name && errors.translations?.[localeCode]?.name}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <LockFieldButton
                                isLocked={values.variationLock?.name}
                                onClick={() => setFieldValue('variationLock.name', !values.variationLock?.name)}
                            />
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
}

export default injectIntl(NameField);
