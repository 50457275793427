import React from "react";
import {Route} from "react-router-dom";
import Login from "../utils/auth0/login";
import Logout from "../utils/auth0/logout";
import Callback from "../utils/auth0/callback";
import Signin from "../views/signin";
import CompleteProfile from "../views/completeProfile";
import ValidateAccount from "../views/validateAccount";
import Invitation from "../views/invitation";
import SignupStripe from "../views/signupStripe";
import NoRequireAuthentication from "../hoc/authentication/noRequireAuthentication"
import Cookies from "js-cookie";
import RetailerConfirmStripeInvitation from "../views/retailerConfirmStripeInvitation";
import Signup from "../utils/auth0/signup";

let routes = [];
const token = Cookies.get("_f") ? JSON.parse(Cookies.get("_f").toString()) : null;

routes.push([
    <Route path="/logout" component={Logout} exact={true} key="logout"/>,
    <Route path="/retailers/stripe/confirm/retailer/:id" component={RetailerConfirmStripeInvitation} exact={true} key="confirmRetailerStripe"/>,
    <Route path="/invitation/:params?" component={Invitation} exact={true} key="invitation"/>,
    <Route path="/signupStripe" component={SignupStripe} exact={true} key="signupStripe"/>,
    <Route path="/signup" component={Signup} exact={true} key="signup"/>,
]);

if (!token) {
    routes.push([
        <Route
            path="/login"
            component={NoRequireAuthentication(Login)}
            exact
            key="login"
        />,
        <Route
            path="/login/callback"
            component={NoRequireAuthentication(Callback)}
            exact
            key="callback"
        />,
        <Route
            path="/signin"
            component={NoRequireAuthentication(Signin)}
            exact
            key="signin"
        />,
        <Route
            path="/completeprofile"
            component={NoRequireAuthentication(CompleteProfile)}
            exact
            key="completeprofile"
        />,
        <Route
            path="/validateaccount"
            component={NoRequireAuthentication(ValidateAccount)}
            exact
            key="validateaccount"
        />,
    ])
}

export default routes;
