import {fetch} from "utils/dataAccess";

export default function (params = "") {
    return new Promise((resolve, reject) => {
        fetch(`/retailer_option_values?${params}`)
            .then(response => {
                response.json().then(response => {
                    resolve(response["hydra:member"])
                })
            })
            .catch(error => reject(error));
    });
}

export function getOptionsWithRetailerOptionValues(retailerId) {
    return new Promise((resolve, reject) => {
        fetch(`/options_with_retailer_option_values/${retailerId}`)
            .then(response => {
                response.json().then(response => {
                    resolve(response)
                })
            })
            .catch(error => reject(error));
    });
}

export function getByRetailerGroupedByOptions(retailerId, options) {
    let optionsQueryParam = '';

    optionsQueryParam = options.map((option) => {
        return `option[]=${option}&`
    })

    optionsQueryParam = optionsQueryParam.join('&');

    return new Promise((resolve, reject) => {
        fetch(`/retailer_option_values_by_retailer_and_options/${retailerId}?${optionsQueryParam}`)
            .then(response => {
                response.json().then(response => {
                    resolve(response)
                })
            })
            .catch(error => reject(error));
    });
}
