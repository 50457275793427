import React from 'react';
import Grid from "@mui/material/Grid";

import FormBloc from "components/form/FormBloc";
import SkuField from "./components/SkuField";
import ExternalIdField from "./components/ExternalIdField";

function VariationReferences(props) {
    return (
        <FormBloc titleId='product.show.variation.form.reference.title'>
            <Grid container rowSpacing={3}>
                <Grid item container columnSpacing={2}>
                    <Grid item md={6} xs={12}>
                        <SkuField />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <ExternalIdField />
                    </Grid>
                </Grid>
            </Grid>
        </FormBloc>
    );
}

export default VariationReferences;
