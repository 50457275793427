import React from "react";
import {Route} from "react-router-dom";
import Members from "../../views/member/members";
import RequireAuthentication from "../../hoc/authentication/requireAuthentication";
import Cookies from "js-cookie";
import {Authorizations, hasAuthorization} from "../../utils/authorizations";

let routes = [];
const applications = Cookies.get("_a") ? JSON.parse(Cookies.get("_a").toString()) : null;
const authorizations = Cookies.get("_b") ? JSON.parse(Cookies.get("_b").toString()) : [];

if (
    applications
    && (applications["marketplace"] || applications["picking"])
    && hasAuthorization(authorizations, Authorizations.GLOBAL_MEMBER_MANAGEMENT)
) {
    routes.push([
        <Route
            path="/members/:params"
            component={RequireAuthentication(Members)}
            exact
            key="member"
        />,
    ])
}

export default routes;
