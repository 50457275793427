import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import Badge from "components/Badge";

VariationStock.propTypes = {
    stock: PropTypes.number
};

function VariationStock({stock}) {
    const [stockColor, setStockColor] = useState('online');

    useEffect(() => {
        if (stock < 5) {
            setStockColor('error');
        } else if (stock < 10) {
            setStockColor('warning');
        }
    }, [stock])

    return (
        <Badge
            label={stock}
            rounded={true}
            color={stockColor}
        />
    );
}

export default VariationStock;
