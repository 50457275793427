import React from "react";
import {Route} from "react-router-dom";
import Cookies from "js-cookie";
import RequireAuthentication from "../../hoc/authentication/requireAuthentication";
import Recommendations from "views/recommendations";

let routes = []
const token = Cookies.get("_f") ? JSON.parse(Cookies.get("_f").toString()) : null

if (token) {
    routes.push([
        <Route
            path="/recommendations"
            component={RequireAuthentication(Recommendations)}
            key="recommendations"
            exact
        />,
    ])
}

export default routes
