import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";

import Badge from "components/Badge";

StatusBadge.propTypes = {
    status: PropTypes.bool.isRequired
};

function StatusBadge({status, ...props}) {
    let statusLabel = props.intl.formatMessage({id: 'product.show.resume.image.status.disabled'})

    if (status) {
        statusLabel = props.intl.formatMessage({id: 'product.show.resume.image.status.enabled'})
    }

    return (
        <div style={{
            position: 'absolute',
            right: '12px',
            top: '12px'
        }}>
            <Badge
                label={statusLabel}
                color={status ? 'success' : 'error'}
                size={'large'}
                indicator={true}
            />
        </div>
    );
}

export default injectIntl(StatusBadge);
