import React, {useContext, useEffect, useState} from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveFilterPanel from "views/marketplace/merchandising/filter/removeFilterPanel";
import ResetFiltersButton from "views/marketplace/merchandising/filter/resetFiltersButton";
import {FilterContext} from "contexts/filterContext";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {FormattedMessage} from "react-intl";
import {primaryColor} from "assets/jss/main";
import CircularProgress from '@mui/material/CircularProgress';

export default function Filters(props) {

    const {filters} = useContext(FilterContext);
    const [value, setValue] = useState('0');
    const [lastSelected, setLastSelected] = useState(null);
    const [flattenedFilters, setFlattenedFilters] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    /** Options is an array of options not just one. So we need to flatten this **/
    useEffect(() => {
        if (props.filters) {
            let tempFilters = [];

            props.filters.forEach(item => {
                if (Array.isArray(item)) {
                    item.forEach(subItem => {
                        if (subItem.tabContent.items.length) {
                            tempFilters.push(subItem);
                        }
                    });
                } else {
                    tempFilters.push(item);
                }
            });
            setFlattenedFilters(tempFilters);
        }
    }, [props.filters]);

    return (
        <Box sx={{ marginBottom: 5 }}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-filters-content"
                    id="panel-filters-header"
                >
                    {props.loaded ?
                        <Typography>
                            <FormattedMessage id={"merchandising.show.label.filters.title"} />

                        </Typography>
                        : <CircularProgress sx={{color: primaryColor, marginX: 'auto'}} size={25} /> }

                </AccordionSummary>
                {(props.loaded && flattenedFilters) &&
                    <AccordionDetails>
                        <Box>
                            <TabContext value={value}>
                                <TabList onChange={handleChange} variant="scrollable">
                                    {flattenedFilters.map((filter, index) => {
                                        return <Tab
                                            label={<FormattedMessage id={"merchandising.show.label.filters.title." + filter.tabTitle} defaultMessage={filter.tabTitle} />}
                                            value={index.toString()}
                                            key={index}
                                        />
                                    })}
                                </TabList>
                                {flattenedFilters.map((filter, index) => {
                                    const tabContent = filter.tabContent;
                                    return (
                                        <TabPanel
                                            key={index}
                                            value={index.toString()}
                                            sx={{ '&.MuiTabPanel-root': {paddingLeft: '0'}, overflowX: 'auto' }}
                                        >
                                            <Grid container spacing={2}>
                                                <Stack key={index} spacing={2} direction={'row'}>
                                                    <tabContent.component 
                                                        items={tabContent.items}
                                                        lastSelected={lastSelected}
                                                        setLastSelected={setLastSelected}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </TabPanel>
                                    );
                                })}
                            </TabContext>
                        </Box>
                        <Stack>
                            <Grid
                                xs={12}
                                container
                                sx={{ borderTop: filters.length > 0 ? 1 : 0, borderColor: 'divider', paddingY: '10px'}}
                            >
                                <RemoveFilterPanel />
                            </Grid>
                            <Grid
                                xs={12}
                                container
                                justifyContent="flex-end"
                                sx={{ borderTop: 1, borderColor: 'divider', paddingTop: '10px'}}
                            >
                                <ResetFiltersButton />
                            </Grid>
                        </Stack>
                    </AccordionDetails>
                }
            </Accordion>
        </Box>
    );
}
