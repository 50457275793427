import {useEffect, useState} from "react";
import {fetch} from "utils/dataAccess";
import SimpleFilterPanel from "views/marketplace/merchandising/filter/simpleFilterPanel";
import {getCurrentOrganizationId} from "../../../../utils/dataAccessOrganizationHandler";

export default function useRetailers() {

    const [retailersFilters, setRetailersFilters] = useState(null);
    const [retailersLoaded, setRetailersLoaded] = useState(false);

    useEffect(() => {
        fetch("/retailers?pagination=false&order[name]=asc&organization.id="+getCurrentOrganizationId()).then(
            response => {
                response.json().then(retrieved => {
                    setRetailersFilters(
                        {
                            "tabTitle": "retailers",
                            "tabContent": {
                                component: SimpleFilterPanel,
                                items: retrieved['hydra:member'],
                            }
                        }
                    );

                    setRetailersLoaded(true);
                });
            }
        );
    }, []);

    return {
        retailersLoaded,
        retailersFilters
    };
}
