import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage } from "react-intl";

// core components
import Card from "components/card/card";
import CardBody from "components/card/cardBody";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import ImageSlider from "../imageSlider";
import ButtonsWrapper from "../button/buttonsWrapper";

// styles
import {withStyles} from "@mui/styles";
import CreateProductFormStyle from "assets/jss/views/stockeasy/product/create/form/createProductFormStyle";

// actions
import {create} from "actions/stockeasy/product/create";
import FormattedNumberWithCurrency from "../../../../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency";

import localStorageSE from "../localStorageManager/localStorageSE";
import LabeledSwitch from "../../../../../../components/switch/LabeledSwitch";
import {formatCornersOptions} from "../form/selectHelper";

// helpers
import {Authorizations, hasAuthorization} from "../../../../../../utils/authorizations";

class ConfirmationStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productData: localStorageSE.getProductData(),
            isStepValid: true,
        }
    }

    componentDidUpdate(prevProps,  prevState, snapshot) {
        if (this.props.createSuccess !== prevProps.createSuccess) {
            this.props.goToNextStep(); // redirect to congrats
        }
    }

    totalStock(variations) {
        let stockCount = 0

        variations.forEach(variation => {
            if (variation.selected) {
                stockCount = stockCount + Number(variation.stock);
            }
        })

        return stockCount
    }

    renderPrice(price, outletPrice) {
        const {classes} = this.props;

        if (outletPrice) {
            return(
                <>
                    <FormattedNumberWithCurrency className={classes.crossed} value={price} currency={this.props.currentOrganization.currency} />
                    <FormattedNumberWithCurrency className={classes.strong} value={outletPrice} currency={this.props.currentOrganization.currency} />
                </>
            )
        }

        return <FormattedNumberWithCurrency className={classes.strong} value={price} currency={this.props.currentOrganization.currency} />
    }

    handleConfirmStep() {
        const {productData} = this.state;

        productData['organization'] = this.props.member.organization;
        productData['retailer'] = this.props.retailerId;

        if (productData.categories) {
            let newCategories = [];

            productData.categories.forEach((category) => {
                if (typeof category === 'string') {
                    // since we do not use the CategoryProcessor when posting product with SE
                    // Mapper.php need an array of categories
                    let splitedCategory = category.split('>');

                    splitedCategory = splitedCategory.map((categ) => categ.trim());

                    newCategories.push(splitedCategory);
                }
            })

            productData.categories = newCategories;
        }

        // save only the selected variation
        let productToSave = productData;
        let variationsToSave = [];

        productData.variations.forEach(variation => {
            if (variation.selected) {
                Object.keys(variation.options).forEach(option => {
                   variation[option] = variation.options[option];
                });
                variationsToSave.push(variation);
            }
        });

        productToSave.variations = variationsToSave;

        productToSave.locale = this.props.currentOrganization.locales.includes(this.props.member.locale) ?
            this.props.member.locale
            : this.props.currentOrganization.defaultLocale
        ;

        this.props.create(productToSave);
        localStorageSE.removeData();
    }

    countWeights(variations) {
        const weights = [];
        variations.forEach(variation => {
            if (variation.selected && !weights.includes(variation.weight)) {
                weights.push(variation.color)
            }
        })

        return weights.length;
    }

    render() {
        const {classes, corners, authorizations} = this.props;
        const {productData} = this.state;

        const cornersList = formatCornersOptions(productData.corners, corners, this.props.member.locale);
        const canManageCatalogSettings = (hasAuthorization(authorizations, Authorizations.STOCK_EASY_ACCESS) && hasAuthorization(authorizations, Authorizations.STOCK_EASY_CATALOG_PRODUCT_SETTINGS)) || !hasAuthorization(authorizations, Authorizations.STOCK_EASY_ACCESS);

        return (
                <Card>
                    <CardBody>
                        <section className={classes.confirmationWrapper}>

                            <article className={classes.confirmationTitle}>
                                <h2><FormattedMessage id={"stockeasy.confirmation.title"}/>:</h2>
                            </article>

                            <article>
                                <ImageSlider images={productData.pictures} />
                            </article>

                            <article className={classes.confirmationMain}>
                                <header>
                                    <div className={classes.stepTitle}>{productData.name}</div>
                                    <div>{productData.brand}</div>
                                </header>

                                { cornersList.length > 0 &&
                                    <header>
                                        <div>
                                            <b><FormattedMessage id={"stockeasy.confirmation.corners"}/></b>
                                            { cornersList.map(corner => <span>{corner.label}</span>).reduce((prev, curr) => [prev, ', ', curr])}
                                        </div>
                                    </header>
                                }

                                {productData.additionalAttributes &&
                                    <header>
                                        {Object.keys(productData.additionalAttributes).map(key => {
                                            if ('' !== productData.additionalAttributes[key]) {
                                                return (
                                                    <div><b><FormattedMessage
                                                        id={`product.show.attribute.${key}`}/></b>: {productData.additionalAttributes[key]}
                                                    </div>
                                                );
                                            }
                                        })}
                                    </header>
                                }

                                <GridContainer className={classes.section}>
                                    <GridItem xs={12}>
                                        <GridContainer>
                                            <GridItem xs={6} className={classes.confirmationInfos}>
                                                <div>
                                                    <FormattedMessage id={"stockeasy.step.confirm.variation_count"}/>
                                                    <span className={classes.strong}>{productData.variations.length}</span>
                                                </div>
                                                <div>
                                                    <FormattedMessage id={"stockeasy.step.confirm.weights"}/>
                                                    <span className={classes.strong}>{this.countWeights(productData.variations)}</span>
                                                </div>
                                                <div>
                                                    <FormattedMessage id={"stockeasy.step.confirm.stock"}/>
                                                    <span className={classes.strong}>{this.totalStock(productData.variations)}</span>
                                                </div>
                                            </GridItem>
                                            <GridItem xs={6} className={classes.confirmationInfos}>
                                                <div>{this.renderPrice(productData.price, productData.outletPrice)}</div>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem xs={12} className={classes.description}>
                                        <div dangerouslySetInnerHTML={{
                                            __html: productData.description
                                        }}>
                                        </div>
                                    </GridItem>
                                    { canManageCatalogSettings &&
                                        <>
                                            <GridItem xs={12}>
                                                <LabeledSwitch
                                                    labelMessageId={"stockeasy.step.confirm.catalogOnly"}
                                                    checked={productData.catalogOnly}
                                                    disabled={true}
                                                />
                                            </GridItem>
                                            <GridItem xs={12}>
                                                <LabeledSwitch
                                                    labelMessageId={"stockeasy.step.confirm.hiddenPrice"}
                                                    checked={productData.hiddenPrice}
                                                    disabled={true}
                                                />
                                            </GridItem>
                                            <GridItem xs={12}>
                                                <LabeledSwitch
                                                    labelMessageId={"stockeasy.step.confirm.privateProduct"}
                                                    checked={productData.privateProduct}
                                                    disabled={true}
                                                />
                                            </GridItem>
                                            <GridItem xs={12}>
                                                <LabeledSwitch
                                                    labelMessageId={"stockeasy.step.confirm.privatePrice"}
                                                    checked={productData.privatePrice}
                                                    disabled={true}
                                                />
                                            </GridItem>
                                        </>
                                    }
                                </GridContainer>
                            </article>

                            <ButtonsWrapper
                                goToPreviousStep={this.props.goToPreviousStep}
                                removeDraft={this.props.removeDraft}
                                submit={() => this.handleConfirmStep()}
                                isFormValid = {this.state.isStepValid}
                                loading = {this.props.createLoading}
                                step={5}
                            />

                        </section>
                    </CardBody>
                </Card>
            );
        }
}

const mapStateToProps = (state) => {
    return {
        product: state.stockEasy.request.retrieved,
        member: state.authentication.member,
        currentOrganization: state.currentOrganization.retrieved,
        createLoading: state.stockEasy.create.loading,
        createSuccess: state.stockEasy.create.retrieved,
        authorizations: state.authentication.authorizations,
    };
};

const mapDispatchToProps = dispatch => ({
    create: (product) => dispatch(create(product))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(CreateProductFormStyle)(ConfirmationStep));
