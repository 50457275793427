import React, {useContext} from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// @mui/material components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import CancelButtonStyled from "components/button/CancelButtonStyled";
import DeleteButton from "components/button/DeleteButton";

import {ListContext} from "../context/ListContext";

DeleteDialog.Proptypes = {
    titleId: PropTypes.string.isRequired,
    contentTextId: PropTypes.string.isRequired,
    onDeleteClick: PropTypes.func
}

function DeleteDialog({titleId, contentTextId, onDeleteClick}) {
    const {loading, isDeleteDialogOpen, closeDeleteDialog} = useContext(ListContext);

    return (
        <Dialog
            open={isDeleteDialogOpen}
            onClose={closeDeleteDialog}
            fullWidth
        >
            <DialogTitle>
                <FormattedMessage id={titleId} />
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: '40px' }}>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage id={contentTextId} />
                </DialogContentText>
            </DialogContent>
                <DialogActions>
                    <CancelButtonStyled
                        disabled={loading}
                        onClick={closeDeleteDialog}
                    >
                        <FormattedMessage id="list.button.cancel" />
                    </CancelButtonStyled>
                    <DeleteButton
                        disabled={loading}
                        onClick={onDeleteClick}
                    >
                        <FormattedMessage id="list.button.delete" />
                    </DeleteButton>
                </DialogActions>
        </Dialog>
    )
}

export default DeleteDialog;
