import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";

import {Grid} from "@mui/material";

import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";

import RetailerOptionValueName from "./RetailerOptionValueName";
import MappingElementContainer from "./MappingElementContainer";
import MappingSeparator from "./MappingSeparator";
import OptionValueListButton from "./OptionValueListButton";
import OptionValueListButtonIcon from "./OptionValueListButtonIcon";
import OptionValueName from "./OptionValueName";
import MappingActionContainer from "./MappingActionContainer";
import OptionValueList from "./OptionValueList";

import mapRetailerOptionValue from 'api/retailerOptionValues/mapping';

MappingElement.propTypes = {
    retailerOptionValue: PropTypes.shape({
        value: PropTypes.shape({
            id: PropTypes.string,
            option_value_id: PropTypes.string,
            option_id: PropTypes.string,
        }),
        preview: PropTypes.arrayOf(PropTypes.string),
    }),
    optionValues: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string
        })
    ),
    onMap: PropTypes.func
};

// looks like <CardAttribute /> but retailerOption and category or gender are too different for a single component
function MappingElement({retailerOptionValue, optionValues, onMap, ...props}) {

    const [isOptionValuesListOpen, setIsOptionValuesListOpen] = useState(false);
    const [selectedMapping, setSelectedMapping] = useState(null);

    function toggleOptionValuesList() {
        setIsOptionValuesListOpen(!isOptionValuesListOpen);
    }

    function closeOptionValuesList() {
        setIsOptionValuesListOpen(false);
    }

    function handleChange(value)  {
        setSelectedMapping(value.label)
        closeOptionValuesList();

        mapRetailerOptionValue(retailerOptionValue.value.id, value.value).then(() => {
            onMap(retailerOptionValue.value.id, retailerOptionValue.value['option_id']);
        })
    }

    const isMapped = !!retailerOptionValue.value.option_value_id;

    function getMappedOptionValue(optionValueId) {
        if (!!selectedMapping) {
            return selectedMapping;
        }

        if (isMapped) {
            const matchedOptionValue = optionValues.find(optionValue => optionValue.value === optionValueId);
            return matchedOptionValue ? matchedOptionValue.label : null
        }

        // retailerOptionValue not mapped
        return null;
    }

    return (
        <MappingElementContainer>
            <GridContainer>
                <GridItem xs={4} sm={4}>
                    <RetailerOptionValueName
                        retailerOptionValueId={retailerOptionValue.value.id}
                        retailerOptionValue={JSON.parse(retailerOptionValue.value.translations)[props.member.locale].name}
                    />
                </GridItem>
                <GridItem xs={2} sm={3}>
                    <MappingSeparator isMapped={isMapped} links={retailerOptionValue.preview} />
                </GridItem>
                <GridItem xs={6} sm={5}>
                    <MappingActionContainer>
                        <Grid
                            item
                            xs={9}
                            sm={9}
                            sx={{
                                paddingLeft: '16px',
                                paddingRight: '16px',
                                alignSelf: 'center',
                            }}
                        >
                            {isOptionValuesListOpen ? (
                                <OptionValueList
                                    id={retailerOptionValue.value.id}
                                    valuesList={optionValues}
                                    onChange={(value) => handleChange(value)}
                                    onBlur={() => closeOptionValuesList()}
                                />
                            ) : (
                                <OptionValueName name={getMappedOptionValue(retailerOptionValue.value.option_value_id)} />
                            )}
                        </Grid>
                        <GridItem xs={3} sm={3}>
                            <OptionValueListButton onClick={() => toggleOptionValuesList()}>
                                <OptionValueListButtonIcon open={isOptionValuesListOpen} />
                            </OptionValueListButton>
                        </GridItem>
                    </MappingActionContainer>
                </GridItem>
            </GridContainer>
        </MappingElementContainer>
    );
}

const mapStateToProps = state => {
    return {
        member: state.authentication.member,
    };
};

export default connect(mapStateToProps)(MappingElement);
