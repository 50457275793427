import React from "react";
import {Route} from "react-router-dom";
import Sectors from "../../views/sectors/list";
import RequireAuthentication from "../../hoc/authentication/requireAuthentication";
import Cookies from "js-cookie";

let routes = [];
const token = Cookies.get("_f") ? JSON.parse(Cookies.get("_f").toString()) : null;

if (token) {
    routes.push([
        <Route
            path="/sectors"
            component={RequireAuthentication(Sectors)}
            key="sectors"
            exact
        />,
    ])
}

export default routes;
