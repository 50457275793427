import {useEffect, useState} from "react";
import {fetch} from "utils/dataAccess";
import SimpleFilterPanel from "views/marketplace/merchandising/filter/simpleFilterPanel";

export default function useBrands() {

    const [brandsFilters, setBrandsFilters] = useState(null);
    const [brandsLoaded, setBrandsLoaded] = useState(false);

    useEffect(() => {
        fetch("/brands?pagination=false&order[name]=asc").then(
            response => {
                response.json().then(retrieved => {

                    // Add missing API property
                    retrieved['hydra:member'].forEach((item) => {
                        item['@type'] = 'Brand';
                    })

                    setBrandsFilters(
                        {
                            "tabTitle": "brands",
                            "tabContent": {
                                component: SimpleFilterPanel,
                                items: retrieved['hydra:member'],
                            }
                        }
                    );

                    setBrandsLoaded(true);
                });
            }
        );
    }, []);

    return {
        brandsLoaded,
        brandsFilters
    };
}
