import React from 'react';

import Grid from "@mui/material/Grid";

import {greyishBlue50, greyishBlue500} from "assets/jss/main";

function HeaderContainer(props) {
    return (
        <Grid
            container
            sx={{
                background: greyishBlue50,
                color: greyishBlue500,
                fontWeight: 400,
                borderRadius: '8px',
                padding: '4px 12px',
                marginBottom: '8px',
            }}
        >
            {props.children}
        </Grid>
    );
}

export default HeaderContainer;
