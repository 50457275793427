import React from 'react';
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";

import {greyishBlue400} from "assets/jss/main";

import InputTextField from "components/input/new/InputTextField";
import SearchIcon from "components/icons/SearchIcon";

SearchBar.Proptypes = {
    onChange: PropTypes.func
}

function SearchBar({onChange, ...props}) {
    return (
        <InputTextField
            placeholder={props.intl.formatMessage({id: 'list.search'})}
            onChange={(event) => onChange(event.target.value)}
            InputProps={{
                startAdornment: <SearchIcon size={24}/>,
            }}
            sx={{
                width: '100%',
                maxWidth: '566px',
                '& .MuiInputBase-root': {
                    input: {
                        padding: '10px 16px',
                        "&::placeholder": {
                            color: greyishBlue400,
                            opacity: 1
                        },
                    },
                }
            }}
            {...props}
        />
    );
}

export default injectIntl(SearchBar);
