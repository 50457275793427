import React from 'react'
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import StyledSpanCellBold from "components/TableCells/StyledSpanCellBold";
import Options from "./Options";

export default function Name({lineItem}) {
    return (
        <>
            <StyledSpanCellBold>{lineItem.name}</StyledSpanCellBold>
            <span><FormattedMessage id={"picking.order.show.table.name.ugs"} /> {lineItem.productSku}</span>
            <span><FormattedMessage id={"picking.order.show.table.name.sku"} /> {lineItem.variationSku}</span>
            {(lineItem.variation && lineItem.variation.barcode) && (
                <span>
                    <FormattedMessage id={"picking.order.show.table.name.ean"} /> {lineItem.variation.barcode}
                </span>
            )}
            <Options lineItem={lineItem}/>
       </>
    );
}

Name.defaultProps = {
    lineItem: {}
};

Name.propTypes = {
    lineItem: PropTypes.shape({
        name: PropTypes.string,
        variation: PropTypes.object,
        productSku: PropTypes.string,
        variationSku: PropTypes.string,
        size: PropTypes.string,
        color: PropTypes.string
    })
};
