import React from 'react';

import Card from "components/card/card";
import CardBody from "components/card/cardBody";

function MappingElementContainer(props) {
    return (
        <Card style={{
            marginTop: "10px",
            padding: "5px",
        }}>
            <CardBody style={{overflow: 'unset'}}>
                {props.children}
            </CardBody>
        </Card>
    );
}

export default MappingElementContainer;
