import React from 'react';
import {injectIntl, FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import InputAdornment from "@mui/material/InputAdornment";

import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";

import LockFieldButton from "../../../../LockFieldButton";

function DiscountedPriceField(props) {
    const {values, handleChange, touched, errors, handleBlur, setFieldValue} = useFormikContext();

    return (
        <>
            <Label htmlFor='discountedPrice'>
                <FormattedMessage id='product.show.variation.form.field.discountedPrice' />
            </Label>
            <InputTextField
                name='discountedPrice'
                onChange={handleChange}
                value={values.discountedPrice ?? ''}
                placeholder={props.intl.formatMessage({id: 'product.show.variation.form.field.discountedPrice.placeholder'})}
                fullWidth
                onBlur={handleBlur}
                error={touched.discountedPrice && Boolean(errors.discountedPrice)}
                helperText={touched.discountedPrice && errors.discountedPrice}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <LockFieldButton
                                isLocked={values.variationLock?.discountedPrice}
                                onClick={() => setFieldValue('variationLock.discountedPrice', !values.variationLock?.discountedPrice)}
                            />
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
}

export default injectIntl(DiscountedPriceField);
