import React from 'react';

function PreviewContainer({isMulti = false, ...props}) {
    return (
        <div style={{
            display: 'inline-flex',
            width: isMulti ? '160px' : '100%',
            height: isMulti ? '160px' : '252px',
            boxSizing: 'border-box'
        }}>
            <div style={{
                display: 'flex',
                minWidth: 0,
                overflow: 'hidden',
                width: '100%',
                position: 'relative'
            }}>
                {props.children}
            </div>
        </div>
    );
}

export default PreviewContainer;
