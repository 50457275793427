import {fetch} from "utils/dataAccess";

export default async function mapRetailerOptionValue(retailerOptionValue, optionValue)  {
    const values = {
        'retailerOptionValues': {
            [retailerOptionValue]: optionValue
        }
    };

    const response = await fetch('/feed_attributes/missing', {
        method: "POST",
        body: JSON.stringify(values),
    });

    return await response.json();
}
