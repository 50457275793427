import React from "react";
import ProductSingleStockLabel from "./ProductSingleStockLabel";
import {withStyles} from "@mui/styles";
import merchandisingStyle from "../../../../assets/jss/views/merchandising/merchandisingStyle";
import { v4 as uuidv4 } from 'uuid';

function ProductStockLabels({product, classes}) {

    return (
        <div className={classes.stocksLabels}>
            {Object.keys(product.stocks).map((index) => {
                let key = product.id + uuidv4();

                return <ProductSingleStockLabel key={key} stock={product.stocks[index]} stockType={index} />
            })}
        </div>
    );
}

export default withStyles(merchandisingStyle)(ProductStockLabels);
