import {useEffect, useState} from "react";
import {fetch} from "utils/dataAccess";
import SimpleFilterPanel from "views/marketplace/merchandising/filter/simpleFilterPanel";
import {getCurrentOrganizationId} from "../../../../utils/dataAccessOrganizationHandler";

export default function useCorners() {

    const [cornersFilters, setCornersFilters] = useState(null);
    const [cornersLoaded, setCornersLoaded] = useState(false);

    useEffect(() => {
        fetch("/corners?pagination=false&order[name]=asc&organization.id="+getCurrentOrganizationId()).then(
            response => {
                response.json().then(retrieved => {
                    setCornersFilters(
                        {
                            "tabTitle": "corners",
                            "tabContent": {
                                component: SimpleFilterPanel,
                                items: retrieved['hydra:member'],
                            }
                        }
                    );

                    setCornersLoaded(true);
                });
            }
        );
    }, []);

    return {
        cornersLoaded,
        cornersFilters
    };
}
