import {fetch, normalize} from "utils/dataAccess";

export default function update(lockId, values) {

    return new Promise((resolve, reject) => {
        fetch(lockId, {
            method: "PUT",
            body: JSON.stringify(values),
        }).then(response => response.json()).then((retrieved) => {
            resolve(normalize(retrieved));
        }).catch(error => reject(error.message));
    });
}
