import React from "react";
import {Route} from "react-router-dom";
import {ProductList, Create, Stock} from "../../views/stockeasy/product/index";
import RequireAuthentication from "../../hoc/authentication/requireAuthentication";
import {Authorizations, hasAuthorization} from "../../utils/authorizations";
import Cookies from "js-cookie";
import {ProductShow, VariationList} from "../../views/marketplace/retailer/product";
import RetailerLoader from "../../hoc/retailer/retailerLoader";
import StockEasyOfferInjector from "../../hoc/offer/stockEasyOfferInjector";

let routes = [];
const applications = Cookies.get("_a") ? JSON.parse(Cookies.get("_a").toString()) : null;
const authorizations = Cookies.get("_b") ? JSON.parse(Cookies.get("_b").toString()) : [];

if (applications && applications["stockEasy"]) {
    if (hasAuthorization(authorizations, Authorizations.STOCK_EASY_ACCESS)) {
        routes.push([
            <Route
                path="/stockeasy/show/retailers/:id/products/variations/:params"
                component={RequireAuthentication(RetailerLoader(StockEasyOfferInjector(VariationList)))}
                exact
                strict
                key="stockEasyVariationList"
            />,
            <Route
                path="/stockeasy/show/retailers/:id/products/:params"
                component={RequireAuthentication(RetailerLoader(ProductList))} // need RetailerLoader for export
                exact
                strict
                key="stockeasyList"
            />,
            <Route
                path="/stockeasy/retailers/:id/product/create"
                component={RequireAuthentication(Create)}
                exact
                strict
                key="create"
            />,
            <Route
                path="/stockeasy/show/retailers/:id/products/show/:prid" //pr=productId
                component={RequireAuthentication(RetailerLoader(ProductShow))}
                exact
                strict
                key="stockEasyProductShow"
            />,
            <Route
                path="/stockeasy/retailers/:id/product/stock"
                component={RequireAuthentication(Stock)}
                exact
                strict
                key="stock"
            />,
        ])
    }
}

export default routes;
