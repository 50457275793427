import {fetch} from "utils/dataAccess";
import normalizeTranslations from "../../actions/translations";

export async function updatePatch(productId, values)  {
    const response = await fetch(`/products/${productId}`, {
        method: "PATCH",
        body: JSON.stringify(values),
        headers: new Headers({'Content-Type': 'application/merge-patch+json'}),
    });

    return await response.json();
}

export async function update(productId, values)  {
    values = normalizeTranslations(values);

    const response = await fetch(`/products/${productId}`, {
        method: "PUT",
        body: JSON.stringify(values),
    });

    return await response.json();
}
