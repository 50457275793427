import React, {useContext, useEffect, useState} from 'react';
import {useFormikContext} from "formik";

import SelectTitle from "components/radioGroup/SelectTitle";
import RadioGroup from "components/radioGroup/RadioGroup";
import FormControlLabel from "components/radioGroup/FormControlLabel";

import LockFieldButton from "../../../../LockFieldButton";

import {ProductShowContext} from "../../../../../context/ProductShowContext";

function TaxRateField(props) {
    const {values, setFieldValue} = useFormikContext();

    const {taxRateList, currentRetailer} = useContext(ProductShowContext);

    const [orderedTaxRate, setOrderedTaxRate] = useState([]);

    useEffect(() => {
        if (!currentRetailer?.taxRateZero) {
            const taxRateZeroIndex = taxRateList.findIndex((taxRate) => taxRate.value === 0);

            if (taxRateZeroIndex !== -1) {
                taxRateList.splice(taxRateZeroIndex, 1);
            }
        }

        const formattedTaxRates = taxRateList.map((key) => {
            return {"value": key['@id'], "label": key.value};
        });

        const orderedTaxRates = formattedTaxRates.sort((a, b) => a.label < b.label);

        setOrderedTaxRate(orderedTaxRates);
    }, [taxRateList]);


    function getTaxRateValueById(id) {
        return taxRateList.find((taxRate) => taxRate['@id'] === id)?.value
    }

    return (
        <>
            <SelectTitle titleTranslationKey='product.show.product.form.field.taxRate' />
            <RadioGroup
                alignListWithButton
                selectedValueLabel={`${getTaxRateValueById(values.taxRate)}%`}
                name={'taxRate'}
                value={values.taxRate}
                onChange={(event) => {
                    setFieldValue('taxRate', event.currentTarget.value)
                }}
                endAdornment={(
                    <LockFieldButton
                        isLocked={values.productLock?.taxRate}
                        onClick={() => setFieldValue('productLock.taxRate', !values.productLock?.taxRate)}
                    />
                )}
            >
                {orderedTaxRate.map((taxRate, index) => (
                    <FormControlLabel
                        key={index}
                        value={taxRate.value}
                        label={`${taxRate.label}%`}
                    />
                ))}
            </RadioGroup>
        </>
    );
}

export default TaxRateField;
