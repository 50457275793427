import {fetch} from "utils/dataAccess";

export default function listByOptionId(optionId) {
    return new Promise((resolve, reject) => {
        fetch(`/option_values?option.id=${optionId}&order[position]=asc&pagination=false`).then(response => {
            response.json().then(response => {
                resolve(response["hydra:member"])
            })
        }).catch(error => reject(error));
    });
}
