export const MappingTabEnums = Object.freeze({
    UNMAPPED: 'unmapped',
    MAPPED: 'mapped',
});

export const AllMappingTabEnums = Object.freeze([
    MappingTabEnums.UNMAPPED,
    MappingTabEnums.MAPPED,
])

