import React, {Component} from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {BeatLoader, PropagateLoader} from 'react-spinners';

// @mui/material components
import {withStyles} from "@mui/styles";
import {Table, TableHead, TableBody, TableRow, TableCell, Tooltip} from "@mui/material";

// @mui/icons-material
import { RoomOutlined } from "@mui/icons-material";

// styles
import tableStyle from "assets/jss/views/picking/order/showTableStyle";
import {primaryColor} from "assets/jss/main";

// utils
import {capitalize} from "utils/capitalize";

// helpers
import {renderS3Url} from "utils/renderS3Url";

class RetailerTable extends Component {
    static propTypes = {
        loading: PropTypes.bool,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(!!nextProps.countRetrieved && !!nextProps.tableData)
        {
            nextProps.tableData.map((item, key) => {
                item.lastOrderDate = nextProps.countRetrieved.totalCount[item["id"]]["oldestOrderDate"];
            });

            nextProps.tableData.sort(function(a, b) {
                if (a.lastOrderDate === null && b.lastOrderDate !== null) {
                    return 1;
                } else if (a.lastOrderDate !== null && b.lastOrderDate === null) {
                    return -1;
                } else if (a.lastOrderDate === null && b.lastOrderDate === null) {
                    return 0;
                } else {
                    return new Date(a.lastOrderDate) - new Date(b.lastOrderDate);
                }
            });
        }
    }

    render() {
        const {classes, tableHead, tableData, tableHeaderColor, countRetrieved} = this.props;

        return (
            <div className={classes.tableResponsive}>
                {!tableData ?
                    <div className={classes.propagateLoader}>
                        <PropagateLoader
                            sizeUnit={"px"}
                            size={16}
                            color={primaryColor}
                            loading={this.props.loading}
                        />
                    </div> :
                    <div className={classes.beatLoader}>
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={primaryColor}
                            loading={this.props.loading || this.props.countLoading}
                        />
                    </div>
                }

                <Table className={classes.table}>
                    {tableHead !== undefined ? (
                        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                            <TableRow>
                                {tableHead.map((prop, key) => {
                                    return (
                                        <TableCell
                                            className={(prop.props.id === "picking.retailer.list.table.header.location" ?
                                                classes.tableCellCenter :
                                                classes.tableHeadCell) + " " + classes.tableHeadCellXl
                                            }
                                            key={key}
                                        >
                                            {prop && prop.props.id === "picking.retailer.list.table.header.brand" ?
                                                <Tooltip title={<FormattedMessage id={"picking.retailer.list.title"}/>}>
                                                    <span>
                                                        {prop}
                                                    </span>
                                                </Tooltip>
                                                : prop
                                            }
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                    ) : null}
                    {(tableData && tableData.length) ?
                        <TableBody>
                            {tableData.sort().map((item, key) => {
                                let pending = '.';
                                let reserved = '.';
                                let returning = '.';

                                if (countRetrieved !== false && countRetrieved.totalCount && countRetrieved.totalCount[item["id"]]) {
                                    pending = countRetrieved.totalCount[item["id"]]["pending"];
                                    reserved = countRetrieved.totalCount[item["id"]]["reserved"];
                                    returning = countRetrieved.totalCount[item["id"]]["returning"];
                                }
                                return (
                                    <TableRow
                                        hover
                                        key={key}
                                        className={classes.tableRow}
                                        style={{cursor: "pointer"}}
                                        onClick={() => this.props.history.push(`/picking/retailers/${item.id}/order/${encodeURIComponent(`retailer.id=${item.id}&itemsPerPage=500&page=1&pagination=true&has_return_opened_with_date=true&order[status]=asc&order[createdAt]=asc`)}`)}
                                    >
                                        <TableCell className={classes.tableCell}>
                                            {item["logo"] !== null && item["logoUrl"] ?
                                                <img
                                                    src={item["logoUrl"].substring(0, 4) !== "http" ? renderS3Url(this.props.organizations, this.props.member, "retailer", item["logoUrl"]) : item["logoUrl"]}
                                                    alt={capitalize(item["name"])}
                                                    className={classes.retailerLogo}/>
                                                : capitalize(item["name"])}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div className={0 == pending.toLocaleString() ? classes.unavailable : classes.pending}>{pending.toLocaleString()}</div>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div className={0 == reserved.toLocaleString() ? classes.unavailable : classes.reserved}>{reserved.toLocaleString()}</div>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div className={0 == returning.toLocaleString() ? classes.unavailable : classes.returning}>{returning.toLocaleString()}</div>
                                        </TableCell>
                                        <TableCell align={"center"} className={classes.tableCell}>
                                            <a
                                                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(item.address)}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <RoomOutlined onClick={(e) => e.stopPropagation()} fontSize={"large"}/>
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody> :
                        <TableBody>
                            {(tableData) &&
                            <TableRow>
                                <TableCell colSpan={5} className={classes.tableCellNoResult}>
                                    <FormattedMessage id={"retailer.list.table.no_result"}/>
                                </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    }
                </Table>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.retailer.list.loading,
    organizations: state.authentication.organizations,
    member: state.authentication.member,
    countLoading: state.retailer.list.countLoading,
});

RetailerTable.defaultProps = {
    tableHeaderColor: "gray"
};

RetailerTable.propTypes = {
    classes: PropTypes.object.isRequired,
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.object),
};

export default connect(
    mapStateToProps,
    null
)(withStyles(tableStyle)(RetailerTable));
