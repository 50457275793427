import {
    successColor,
    dangerColor,
    crystalGreenColor,
    crystalRedColor,
    warningColor, green50, green500, orange50, orange500, red50, red500
} from "../../main";

const baseLabel = {
    margin: "2px",
    fontSize: "14px",
}

const baseStock = {
    margin: "2px 2px 2px 2px",
    fontWeight: "400",
    fontSize: "14px",
    height: "24px",
    padding: "2px",
    border: "1px solid",
}

const stockBadgeDefault =  {
    width: "8px",
    height: "8px",
    borderRadius: "2px",
    display: "block",
    marginRight: "4px",
};

const productLabelStyle = theme => ({
    popover: {
        pointerEvents: "none",
    },
    popoverContent: {
        pointerEvents: "auto",
        padding: theme.spacing(1),
        backgroundColor: "#000",
        color: "#fff",
        maxWidth: "300px",
        maxHeight: "200px",
        overflowWrap: "break-word",
        overflow: "scroll"
    },
    baseLabel: {
        ...baseLabel,
    },
    ok: {
        ...baseLabel,
        backgroundColor: green50,
        borderColor: green500,
        color: green500,
    },
    ko: {
        ...baseLabel,
        backgroundColor: crystalRedColor,
        borderColor: dangerColor,
        color: dangerColor,
    },
    inStock: {
        ...baseStock,
        backgroundColor: green50,
        borderColor: green500,
        color: green500,
        "& > .MuiChip-label": {
            display: "flex",
            alignItems: "center",
        }
    },
    middleStock: {
        ...baseStock,
        backgroundColor: orange50,
        borderColor: orange500,
        color: orange500,
        "& .MuiChip-label": {
            display: "flex",
            alignItems: "center",
        }
    },
    noStock: {
        ...baseStock,
        backgroundColor: red50,
        borderColor: red500,
        color: red500,
        "& > .MuiChip-label": {
            display: "flex",
            alignItems: "center",
        }
    },
    stockBadgeOnline: {
        ...stockBadgeDefault,
        background: green500,
    },
    stockBadgeOffline: {
        ...stockBadgeDefault,
        background: red500,
    },
});

export default productLabelStyle;
