import React from 'react';
import PropTypes from 'prop-types';

import IsMappedLine from "./components/IsMappedLine";
import ProductPreview from "./components/ProductPreview";

MappingSeparator.propTypes = {
    isMapped: PropTypes.bool,
    links: PropTypes.arrayOf(PropTypes.string),
};

function MappingSeparator({isMapped, links}) {
    return (
        <div style={{
            textAlign: "center",
            marginTop: "12px",
            textTransform: "uppercase",
        }}>
            <IsMappedLine isMapped={isMapped} />
            <ProductPreview links={links} />
        </div>
    );
}

export default MappingSeparator;
