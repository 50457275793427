import React, {useContext} from 'react';
import {injectIntl} from "react-intl";

import Grid from "@mui/material/Grid";

import FormTranslator from "components/translator/formTranslator";
import FormBloc from "components/form/FormBloc";

import NameField from "./components/NameField";
import GenderField from "./components/GenderField";
import CategoryField from "./components/CategoryField";
import DescriptionField from "./components/DescriptionField";
import TaxRateField from "./components/TaxRateField";

import {ProductFormContext} from "../../context/ProductFormContext";

function MainInformation(props) {
    const {setSelectedLocale} = useContext(ProductFormContext);

    return (
        <FormBloc titleId='product.show.product.form.mainInformation.title'>
            <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <FormTranslator
                        onLocaleChange={(locale) => setSelectedLocale(locale.code)}
                        formGenerator={(index, code) => (
                            <Grid container direction='column' rowSpacing={3}>
                                <Grid item container columnSpacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <NameField localeCode={code} />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <GenderField />
                                    </Grid>
                                </Grid>
                                <Grid item container columnSpacing={2}>
                                    <Grid item md={12} xs={12}>
                                        <CategoryField />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <DescriptionField localeCode={code} />
                                </Grid>
                                <Grid item container columnSpacing={2} >
                                    <Grid item md={6} xs={12}>
                                        <TaxRateField />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    } />
                </Grid>
            </Grid>
        </FormBloc>
    );
}

export default injectIntl(MainInformation);
