import {fetch, normalize} from "../../utils/dataAccess";
import {success as createSuccess} from "./create";
import {loading, error} from "./delete";
import {uploadLoading, uploadError} from "../upload";
import normalizeTranslations from "../translations";
import {daysMap} from "../../views/marketplace/retailer/openingDays";

export function retrieveError(retrieveError) {
    return {type: "RETAILER_UPDATE_RETRIEVE_ERROR", retrieveError};
}

export function retrieveLoading(retrieveLoading) {
    return {type: "RETAILER_UPDATE_RETRIEVE_LOADING", retrieveLoading};
}

export function retrieveSuccess(retrieved) {
    return {type: "RETAILER_UPDATE_RETRIEVE_SUCCESS", retrieved};
}

// deprecated: use api/retailer/list.js
export function retrieve(id) {

    // Old routes contains IRI encoded directly
    // while new routes only contains the id
    // this condition allows us to support old and new routes
    if (!id.includes('/retailers/')) {
        id = '/retailers/' + id;
    }

    return dispatch => {
        dispatch(retrieveLoading(true));

        return fetch(id)
            .then(response =>
                response.json()
            )
            .then((retrieved) => {
                retrieved = normalize(retrieved, false);
                dispatch(retrieveLoading(false));
                dispatch(retrieveSuccess(retrieved));
            })
            .catch(e => {
                dispatch(retrieveLoading(false));
                dispatch(retrieveError(e.message));
            });
    };
}

export function updateError(updateError) {
    return {type: "RETAILER_UPDATE_UPDATE_ERROR", updateError};
}

export function updateLoading(updateLoading) {
    return {type: "RETAILER_UPDATE_UPDATE_LOADING", updateLoading};
}

export function updateSuccess(updated) {
    return {type: "RETAILER_UPDATE_UPDATE_SUCCESS", updated};
}

// deprecated use api/retailer/update.js
export function update(item, values) {
    values = normalizeTranslations(values);
    return dispatch => {
        dispatch(updateError(""));
        dispatch(error(""));
        dispatch(updateSuccess(null));
        dispatch(updateLoading(true));

        if (!!values.openingDays) {
            Object.keys(daysMap).forEach(day => {
                let hours = values.openingDays[day];
                if (Array.isArray(hours)) {
                    hours = hours.filter(hour => Array.isArray(hour) && hour.length === 2);
                    values.openingDays[day] = hours;
                }
            });
        }

        const data = {
            name: values.name,
            email: values.email,
            rent: values.rent && parseFloat(values.rent, 10),
            surface: values.surface && parseFloat(values.surface, 10),
            sellers: values.sellers && parseFloat(values.sellers, 10),
            pushActive: values.pushActive,
            sector: values.sector,
            address: values.address,
            phone: values.phone,
            stockThreshold: values.stockThreshold && parseFloat(values.stockThreshold, 10),
            translations: values.translations,
            clickAndCollectOnly: values.clickAndCollectOnly,
            whatsappPhone: values.whatsappPhone,
            smsPhone: values.smsPhone,
            city: values.city !== '' ? values.city : null,
            commissionRate: values.commissionRate,
            taxRateZero: values.taxRateZero,
            catalogOnly: values.catalogOnly,
            privateProducts: values.privateProducts,
            privatePrices: values.privatePrices,
            hiddenPrices: values.hiddenPrices,
            metaTitle: values.metaTitle,
            metaDescription: values.metaDescription,
            coniqLocationId: values.coniqLocationId,
            siret: values.siret,
            openingDays: values.openingDays,
            metadata: values.metadata,
            contactName: values.contactName !== '' ? values.contactName : null,
            paymentAccount: values.paymentAccount,
            allowedToSwitchProductParent: values.allowedToSwitchProductParent,
        };

        return fetch(item["@id"], {
            method: "PUT",
            headers: new Headers({"Content-Type": "application/ld+json"}),
            body: JSON.stringify(data)
        })
            .then(response =>
                response.json()
            )
            .then((retrieved) => {
                retrieved = normalize(retrieved);

                dispatch(updateLoading(false));
                dispatch(updateSuccess(retrieved));
            })
            .catch(e => {
                dispatch(updateLoading(false));
                dispatch(updateError(e));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "RETAILER_UPDATE_RESET"});
        dispatch({type: "RETAILER_DELETE_RESET"});
        dispatch(error(null));
        dispatch(loading(false));
        dispatch(createSuccess(null));
        dispatch(uploadLoading(false));
        dispatch(uploadError(null));
    };
}
