import React from "react";
import {Route} from "react-router-dom";
import Cookies from "js-cookie";

import RequireAuthentication from "hoc/authentication/requireAuthentication";

import OptionValues from "views/optionValues";
import Options from "views/options";

let routes = []
const token = Cookies.get("_f") ? JSON.parse(Cookies.get("_f").toString()) : null

if (token) {
    routes.push([
        <Route
            path="/option/:id"
            component={RequireAuthentication(OptionValues)}
            key="optionValues"
            exact
        />,
        <Route
            path="/options"
            component={RequireAuthentication(Options)}
            key="options"
            exact
        />,
    ])
}

export default routes
