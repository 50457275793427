import React, { useState, createContext, useReducer, useEffect } from 'react';
import {filterReducer} from "reducers/merchandising/filterReducer";
import {getFilterLabel} from "domain/helpers/filterTranslations";

export const FilterContext = createContext();
export const FilterDispatchContext = createContext();

export function FilterProvider({ children }) {

    const [filters, dispatch] = useReducer(filterReducer, []);
    const [filterParams, setFilterParams] = useState();

    useEffect(() => {
        let params = {
            filterRetailers: [],
            filterCorners: [],
            filterCategories: [],
            filterGenders: [],
            filterBrands: [],
            filterOptions: [],
            filterStatus: null,
            filterValidated: null,
            filterOnEshop: null,
        };

        filters.map((filter) => {
            switch (filter['@type']) {
                case 'Retailer': {
                    params.filterRetailers.push(filter.id);
                    break;
                }
                case 'Corner': {
                    params.filterCorners.push(filter.id);
                    break;
                }
                case 'Category': {
                    params.filterCategories.push(filter.id);
                    break;
                }
                case 'Gender': {
                    params.filterGenders.push(filter.id);
                    break;
                }
                case 'Brand': {
                    params.filterBrands.push(filter.id);
                    break;
                }
                case 'Status': {
                    params.filterStatus = (filter.id === 'active' ? true : false);
                    break;
                }
                case 'Validated': {
                    params.filterValidated = (filter.id === 'valid' ? true : false);
                    break;
                }
                case 'OnEshop': {
                    params.filterOnEshop = (filter.id === 'yes' ? true : false);
                    break;
                }
                case 'RetailerOptionValue':
                case 'OptionValue': {
                    params.filterOptions.push({'optionId': filter.optionId, 'retailerOptionValueId': filter.id});
                    break;
                }
                default: {
                    throw Error('Unknown filter type : ' + filter['@type']);
                }
            }
        });

        setFilterParams(params);

    }, [filters]);

    return (
        <FilterContext.Provider value={{filters, filterParams}}>
            <FilterDispatchContext.Provider value={dispatch}>
                {children}
            </FilterDispatchContext.Provider>
        </FilterContext.Provider>
    );
}
