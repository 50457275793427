import React from 'react';
import PropTypes from 'prop-types';

import {black500} from "assets/jss/main";

VariationVariant.propTypes = {
    optionName: PropTypes.string,
    retailerOptionValue: PropTypes.string
};

function VariationVariant({optionName, retailerOptionValue}) {
    return (
        <div style={{
            fontSize: '12px',
            fontWeight: 600,
            color: black500,
            lineHeight: '12px',
        }}>
            {optionName} : {retailerOptionValue}
        </div>
    );
}

export default VariationVariant;
