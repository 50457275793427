import React from "react";
import PropTypes from "prop-types";

import {Button} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import {styled} from '@mui/material/styles';

import {
    green500,
    blueColor,
    blue600,
    greyishBlue200,
    greyishBlue50,
} from "assets/jss/main";

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: blueColor,
    borderRadius: '8px',
    textTransform: 'none',
    padding:'12px 16px',
    borderColor: blueColor,
    lineHeight: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    height: '40px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%'
    },
    '&:hover': {
        backgroundColor: blue600,
    },
    '&.Mui-disabled' : {
        backgroundColor: greyishBlue50,
        color: greyishBlue200
    },
}))

SaveButton.propTypes = {
    helperText: PropTypes.string,
    showHelperText: PropTypes.bool
}

/** todo: create design system to merge SaveButton, CancelButton, DeleteButton in one Button component */
export default function SaveButton({showHelperText, helperText, loading = false, disabled = false, ...props}) {
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <StyledButton
                variant="contained"
                disableElevation
                disabled={disabled}
                {...props}
            >
                {props.children}
                {/* if we use @mui/lab -> https://mui.com/material-ui/react-button/#loading-button*/}
                {loading && (
                    <CircularProgress
                        size={16}
                        sx={{
                            marginLeft: '8px',
                            color: disabled ? greyishBlue200 : '#fff'
                        }}
                    />
                )}
            </StyledButton>
            {showHelperText && (
                <span style={{
                    color: green500,
                    fontSize: '12px'
                }}>
                    {helperText}
                </span>
            )}
        </div>
    )
}
