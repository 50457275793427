function isValidTextField(content, required) {
    // we return an error when a field is required and has no content
    return !(required && !content);
}

function isValidSelect(content, required) {
    return !(required && content.length < 1);
}

function hasMaxOptions(content) {
    // validate maximum 4 options
    let optionsNames = new Set();
    content.forEach(variation => {
        Object.keys(variation.options).forEach(optionName => optionsNames.add(optionName));
    });

    return optionsNames.size < 5;
}

function hasMinOptions(content) {
    // validate minimum 1 option
    for (let i = 0; i < content.length; i++) {
        const variation = content[i];
        if (!variation.options || Object.keys(variation.options).length === 0) {
            return false;
        }
    }

    return true;
}

function hasSameOptions(content) {
    const referenceOptionKeys = Object.keys(content[0].options).sort();
    for (let i = 1; i < content.length; i++) {
        const currentOptionKeys = Object.keys(content[i].options).sort();
        if (referenceOptionKeys.length !== currentOptionKeys.length ||
            !referenceOptionKeys.every((key, index) => key === currentOptionKeys[index])) {
            return false;
        }
    }

    return true;
}

function isUnique(array) {
    return new Set(array).size === array.length;

}

export function createValidation(name, value, valid, type, required = false, unique = false, active = true) {
    return {
        id: name,
        value: value,
        valid: valid,
        type: type,
        required: required,
        unique: unique,
        active: active
    }
}

export function getVariationsKeys(variations) {
    const options = [];

    variations.forEach(variation => {
        if (variation.selected) {
            options.push(JSON.stringify(variation.options));
        }
    })

    return options;
}

export function getVariationsSku(variations) {
    const barcodes = [];

    variations.forEach(variation => {
        if (variation.sku) {
            barcodes.push(variation.sku);
            variation.barcode = /[0-9]{13}/.test(variation.sku) ? variation.sku : variation.barcode;
        }
    })

    return barcodes;
}

export function createVariationsValidations(variations) {
    const validations = [];

    // we add a global validation for color name uniqueness
    validations.push(createValidation('options', getVariationsKeys(variations),true, 'global', false, false, true));
    validations.push(createValidation('sku', getVariationsSku(variations), true, 'global', false, false, true));
    validations.push(createValidation('options_max', variations, true, 'options_max', false, false, true));
    validations.push(createValidation('options_min', variations, true, 'options_min', false, false, true));
    validations.push(createValidation('options_same', variations, true, 'options_same', false, false, true));

    return validations;
}

export function getValidationById(id, validationsList) {
    if (!validationsList) {
        return;
    }

    let result = null;

    validationsList.forEach(validation => {
        if (validation.id === id) {
            result = validation
        }
    });

    return result;
}

export function validate(content, rules) {
    if (rules.type === 'text') {
        return isValidTextField(content, rules.required);
    } else if (rules.type === 'select') {
        return isValidSelect(content, rules.required);
    } else if (rules.type === 'options_max') {
        return hasMaxOptions(content);
    } else if (rules.type === 'options_min') {
        return hasMinOptions(content);
    } else if (rules.type === 'options_same') {
        return hasSameOptions(content);
    } else { // global
        return isUnique(content); // if it's global rule validation is computed in the step
    }
}

export function getUpdatedValidations(value, id, validations) {
    const field = getValidationById(id, validations);

    field.valid = validate(value, field)
    field.value = value;

    return validations;
}
