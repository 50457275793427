import React from 'react';
import PropTypes from "prop-types";

ActionsContainer.propTypes = {
   style: PropTypes.object
}

export default function ActionsContainer(props) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '12px',
                alignItems: 'baseline',
                ...props.style
            }}
        >
            {props.children}
        </div>
    );
}

