import React from 'react';
import PropTypes from 'prop-types';

import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";

OptionValueListButtonIcon.propTypes = {
    open: PropTypes.bool
};

function OptionValueListButtonIcon({open}) {
    if (open) {
        return <KeyboardArrowUp/>
    }

    return <KeyboardArrowDown/>
}

export default OptionValueListButtonIcon;
