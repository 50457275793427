import React from "react";
import {Route} from "react-router-dom";
import {List, Show} from "../../views/picking/order/index";
import RequireAuthentication from "../../hoc/authentication/requireAuthentication";
import RetailerUnloader from "../../hoc/retailer/retailerUnloader";
import Cookies from "js-cookie";

let routes = [];
const applications = Cookies.get("_a") ? JSON.parse(Cookies.get("_a").toString()) : null;

if (applications && applications["picking"]) {
    routes.push([
        <Route
            path="/picking/orders/:params"
            component={RequireAuthentication(RetailerUnloader(List))}
            exact
            key="order"
        />,
        <Route
            path="/picking/orders/show/:id"
            component={RequireAuthentication(RetailerUnloader(Show))}
            exact
            key="show"
        />,
    ])
}

export default routes;
