import {fetch, normalize} from "utils/dataAccess";

export default function create(productId, values) {
    values["product"] = productId;

    return new Promise((resolve, reject) => {
        fetch('/product_locks', {
            method: "POST",
            body: JSON.stringify(values),
        }).then(response => response.json()).then((retrieved) => {
            resolve(normalize(retrieved));
        }).catch(error => reject(error.message));
    });
}
