import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";

import Grid from "@mui/material/Grid";

import Detail from "./components/Detail";
import ProductName from "./components/ProductName";

ProductDetails.propTypes = {
    name: PropTypes.string,
    eshopId: PropTypes.string,
    sku: PropTypes.string,
    price: PropTypes.number,
    stock: PropTypes.number,
    updateAt: PropTypes.string
};

function ProductDetails({name, eshopId, sku, price, stock, updatedAt, ...props}) {
    return (
        <Grid container>
            <Grid item xs={12} paddingTop={3} paddingBottom={2}>
                <ProductName name={name} />
            </Grid>
            <Grid item container>
                <Detail
                    name={props.intl.formatMessage({id: 'product.show.resume.details.eshopId'})}
                    value={eshopId}
                />
                <Detail
                    name={props.intl.formatMessage({id: 'product.show.resume.details.sku'})}
                    value={sku}
                />
                <Detail
                    name={props.intl.formatMessage({id: 'product.show.resume.details.price'})}
                    value={price}
                />
                <Detail
                    name={props.intl.formatMessage({id: 'product.show.resume.details.stock'})}
                    value={stock}
                />
                <Detail
                    name={props.intl.formatMessage({id: 'product.show.resume.details.updatedAt'})}
                    value={updatedAt}
                />
            </Grid>
        </Grid>
    );
}

export default injectIntl(ProductDetails);
