import React from 'react';
import PropTypes from 'prop-types';

import {injectIntl} from "react-intl";

import Badge from "components/Badge";

VariationCount.propTypes = {
    count: PropTypes.number.isRequired
};

function VariationCount({count, ...props}) {
    const messageId = count > 1 ? 'product.show.variations.total.variations' : 'product.show.variations.total.variation'

    return (
        <Badge
            color={'outline'}
            label={`${count} ${props.intl.formatMessage({id: messageId})}`}
            rounded={true}
        />
    );
}

export default injectIntl(VariationCount);
