import React, { Component } from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {BeatLoader} from 'react-spinners';
import Cookies from "js-cookie";

// core components
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Card from "components/card/card";

// @mui/material components
import {
    TextField,
    Button
} from "@mui/material";

// @mui/icons-material
import {ReportProblemOutlined, CheckCircleOutline} from "@mui/icons-material";

// style
import signinStyle from "assets/jss/views/signinStyle.js";
import {primaryColor} from 'assets/jss/main'

// utils
import {fetch} from "utils/dataAccess";
import {withStyles} from "@mui/styles";

class Signin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            authenticatedUserData: JSON.parse(Cookies.get("_s").toString()),
            organizationIsValid: null,
            organizationId: '',
            organizationName: '',
            typingTimeout: 0,
            loading: false,
            success: false,
            error: '',
            sendLoading: false,
        };

        this.getOrganizationBySubdomain = this.getOrganizationBySubdomain.bind(this);
        this.sendInvitationRequest = this.sendInvitationRequest.bind(this);
    }

    async getOrganizationBySubdomain(subdomain) {
        this.setState({
            loading: true,
            organizationId: ''
        });

        await fetch("/organizations?subdomain="+subdomain, {method: "GET", headers: new Headers({"authorization": "Bearer " + this.state.authenticatedUserData.token})})
            .then(response => {
                return response.json();
            })
            .then(data => {
                this.setState({
                    loading: false,
                    organizationIsValid: data["hydra:member"].length > 0,
                    organizationId: data["hydra:member"].length > 0 ? data["hydra:member"][0]["@id"] : '',
                    organizationName: data["hydra:member"].length > 0 ? data["hydra:member"][0]["name"] : '',
                    error: data["hydra:totalItems"] === 0 && "login.signin.error.marketplace",
                })
            })
            .catch(e => {
                this.setState({
                    organizationIsValid: false,
                    loading: false,
                    error: e.message
                });
            });
    };

    sendInvitationRequest() {
        this.setState({sendLoading: true});

        let values = {
            "organization": this.state.organizationId,
            "user": `/users/${this.state.authenticatedUserData.user}`,
            "invitationEmail": this.state.authenticatedUserData.email,
            "state": "pending"
        };

        fetch("/members", {method: "POST", body: JSON.stringify(values), headers: new Headers({"authorization": "Bearer " + this.state.authenticatedUserData.token})})
            .then(response => {
                return response.json();
            })
            .then(() =>
                this.setState({
                    success: true,
                    sendLoading: false
                })
            )
            .catch(e => {
                this.setState({
                    error: e.errors.invitationEmail ? e.errors.invitationEmail : e.message,
                    sendLoading: false
                })
            });
    };

    handleSearch = (subdomain) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            typingTimeout: setTimeout(() => {
                this.getOrganizationBySubdomain(subdomain.toLowerCase());
            }, 1000)
        });
    };

    renderSuccess = (marketplace) => {
        const {classes} = this.props;

        return (
            <GridContainer spacing={2} className={classes.success}>
                <GridItem xs={12} className={classes.title}>
                    <CheckCircleOutline className={classes.successIcon}/>
                    <FormattedMessage id={"login.signin.success.title"} values={{marketplace: marketplace}}/>
                </GridItem>
                <GridItem xs={12} className={classes.subtitle}>
                    <FormattedMessage id={"login.signin.success.content"} values={{marketplace: marketplace}}/>
                </GridItem>
            </GridContainer>
        );
    };

    renderError = () => {
        const {classes} = this.props;

        return (
            <Card className={classes.cardError}>
                <GridContainer>
                    <GridItem xs={12} className={classes.error}>
                        <ReportProblemOutlined className={classes.errorIcon}/>
                        <FormattedMessage id={this.state.error}/>
                    </GridItem>
                </GridContainer>
            </Card>
        )
    };

    renderButton = () => {
        const {classes} = this.props;

        return (
            <Button
                disabled={!this.state.organizationIsValid}
                variant="contained"
                color="primary"
                className={classes.continue}
                onClick={this.sendInvitationRequest}
            >
                {this.state.loading ?
                    <BeatLoader sizeUnit={"px"} size={12} color={primaryColor} loading={this.state.loading}/>
                    :
                    <>
                        <FormattedMessage id={"login.signin.continue"}/>
                        {this.state.sendLoading &&
                        <BeatLoader sizeUnit={"px"} size={4} color={'#FFFFFF'} loading={this.state.sendLoading}/>
                        }
                    </>
                }

            </Button>
        )
    };

    render() {
        const {classes, intl} = this.props;

        return(
            <div className={classes.container}>
                <GridContainer direction="row" justifyContent="center" alignItems="center">
                    {this.state.error.length > 0 && this.renderError()}
                    <Card className={classes.card}>
                        {this.state.success && this.state.organizationName.length > 0 ?
                            this.renderSuccess(this.state.organizationName)
                            :
                            <GridContainer spacing={2}>
                                <GridItem xs={12} className={classes.title}>
                                    <FormattedMessage id={"login.signin.title"}/>
                                </GridItem>
                                <GridItem xs={12} className={classes.subtitle}>
                                    <FormattedMessage id={"login.signin.subtitle"}/>
                                </GridItem>
                                <GridItem xs={12}>
                                    <TextField
                                        className={classes.textfield}
                                        id="search-input"
                                        placeholder={intl.formatMessage({id: "login.signin.placeholder"})}
                                        onChange={(e) => e.target.value.length > 0 && this.handleSearch(e.target.value)}
                                        variant="outlined"
                                    />
                                    <span  className={classes.host}>.{process.env.REACT_APP_HOST}</span>
                                    {this.state.organizationIsValid &&  <CheckCircleOutline className={classes.successOrganizationIcon}/>}
                                </GridItem>
                                <GridItem xs={12}>
                                    {this.renderButton()}
                                </GridItem>
                            </GridContainer>
                        }
                    </Card>
                </GridContainer>
            </div>
        )
    }
}

export default (withStyles(signinStyle)(injectIntl(Signin)));
