import React from 'react';
import PropTypes from 'prop-types';

VariationImage.propTypes = {
    image: PropTypes.string
};

function VariationImage({image}) {
    return (
        <div style={{
            height: '40px',
            width: '40px',
            position: 'relative'
        }}>
            <img
                src={image}
                alt={'variation image'}
                style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    borderRadius: '8px'
                }}
            />
        </div>
    );
}

export default VariationImage;
