import {createContext} from "react";

export const ListContext = createContext({
    loading: false,
    setLoading: () => {},

    listItems: [],
    setListItems: () => {},

    selectedItem: null,
    setSelectedItem: () => {},

    setNotFilteredListItems: () => {},

    createBelow: {},
    showCreateBelow: () => {},
    hideCreateBelow: () => {},
    openCreateDialogViaCreateBelow: () => {},

    isCreateDialogOpen: false,
    openCreateDialog: () => {},
    closeCreateDialog: () => {},

    isDeleteDialogOpen: false,
    openDeleteDialog:() => {},
    closeDeleteDialog:() => {},

    showErrorNotification: () => {},

    moveListItem: () => {},
})
