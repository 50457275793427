import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import {Switch} from "@mui/material";

MappingFilter.propTypes = {
    messageId: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onClick: PropTypes.func
};

function MappingFilter({messageId, checked, onClick}) {
    return (
        <div>
            <FormattedMessage id={messageId} />
            <Switch
                value={true}
                inputProps={{'aria-label': 'secondary checkbox'}}
                checked={checked}
                onClick={onClick}
            />
        </div>
    );
}

export default MappingFilter;
