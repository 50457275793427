import React, {useContext, useEffect, useState} from 'react';
import {Draggable} from "react-beautiful-dnd";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";

import DragAndDropContext from "components/dragAndDrop/DragAndDropContext";
import ListElementContainer from "components/List/components/ListElementContainer";
import StyledLine from "components/List/components/StyledLine";
import DragAndDropIcon from "components/icons/DragAndDropIcon";
import ItemName from "components/List/components/ItemName";
import Date from "components/List/components/Date";
import Hour from "components/List/components/Hour";
import IconButton from "components/button/IconButton";
import EditIcon from "components/icons/EditIcon";

import CreateBelow from "../../../configuration/components/CreateBelow";
import Form from "./components/Form";
import Header from "./components/Header";

import {ListContext} from "components/List/context/ListContext";

import list from "api/option/list";
import {updatePosition} from "api/option/updatePosition";
import {getTranslation} from "../../../../domain/helpers/translations";

function Content(props) {
    const [lastPosition, setLastPosition] = useState(0);

    const {
        loading,
        setLoading,
        listItems,
        setListItems,
        selectedItem,
        setSelectedItem,
        setNotFilteredListItems,
        createBelow,
        showCreateBelow,
        hideCreateBelow,
        openCreateDialogViaCreateBelow,
        isCreateDialogOpen,
        openCreateDialog,
        closeCreateDialog,
        showErrorNotification,
        moveListItem,
    } = useContext(ListContext)

    // componentDidMount
    useEffect(() => {
        getOptions();
    }, []);

    function getOptions() {
        closeCreateDialog();

        setSelectedItem(null);

        setLoading(true);

        list().then((response) => {
            setListItems(response);
            setNotFilteredListItems(response);

            if (response.length > 0) {
                setLastPosition(response[response.length - 1].position)
            }
        }).catch(() => {
            showErrorNotification();
        }).finally(() => {
            setLoading(false);
        })
    }

    function moveOption(result) {
        // update on front
        moveListItem(result)

        // update on back
        const destinationOptionPosition = listItems[result.destination.index]?.position;

        if (!destinationOptionPosition) {
            return;
        }

        setLoading(true);

        updatePosition(result.draggableId, destinationOptionPosition)
            .then(() => getOptions())
            .catch(() => showErrorNotification())
            .finally(() => setLoading(false))
        ;
    }

    return (
        <Grid container>
            <Header />
            <Grid item container>
                <DragAndDropContext
                    onDragEnd={(result) => moveOption(result)}
                    droppableId={"optionList"}
                >
                    <ListElementContainer>
                        {listItems.map((option, index) => (
                            <Grid
                                item
                                container
                                key={option.id}
                                onMouseEnter={(event) => showCreateBelow(event, option.id)}
                                onMouseLeave={hideCreateBelow}
                                sx={{ position: 'relative'}}
                            >
                                <Draggable
                                    draggableId={option.id}
                                    index={index}
                                    isDragDisabled={loading}
                                >
                                    {(provided, snapshot) => (
                                        <StyledLine
                                            justifyContent={"space-between"}
                                            alignItems={"center"}
                                            isDragging={snapshot.isDragging}
                                            innerRef={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Grid
                                                item
                                                container
                                                xs={6}
                                                columnSpacing={2}
                                                flexWrap={"nowrap"}
                                            >
                                                <Grid
                                                    item
                                                    alignSelf={"center"}
                                                    display={"flex"}
                                                >
                                                    <DragAndDropIcon />
                                                </Grid>
                                                <Grid item zeroMinWidth>
                                                    <ItemName label={getTranslation(option).name} />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                xs={6}
                                                columnSpacing={2}
                                                justifyContent={"flex-end"}
                                                alignItems={"center"}
                                                flexWrap={"nowrap"}
                                            >
                                                <Grid item sx={{ width: '100px' }}>
                                                    <Date date={option.updatedAt} />
                                                </Grid>
                                                <Grid item sx={{ width: '60px' }}>
                                                    <Hour date={option.updatedAt} />
                                                </Grid>
                                                <Grid
                                                    item
                                                    container
                                                    justifyContent={"flex-end"}
                                                    sx={{ width: '80px' }}
                                                >
                                                    <Grid item>
                                                        <IconButton onClick={() => openCreateDialog(option)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </StyledLine>
                                    )}
                                </Draggable>
                                <CreateBelow
                                    isVisible={option.id === createBelow.listItemId}
                                    onClick={() => openCreateDialogViaCreateBelow(option.position)}
                                    style={{ bottom: '-28px' }}
                                />
                            </Grid>
                        ))}
                    </ListElementContainer>
                </DragAndDropContext>
            </Grid>
            <Dialog
                open={isCreateDialogOpen}
                onClose={() => closeCreateDialog()}
                maxWidth={'sm'}
                scroll={'body'}
            >
                <DialogContent>
                    <Form
                        refreshList={getOptions}
                        selectedOption={selectedItem}
                        createBelow={createBelow}
                        lastPosition={lastPosition}
                    />
                </DialogContent>
            </Dialog>
        </Grid>
    );
}

export default Content;
