import React from 'react';
import {FormattedTime} from "react-intl";
import PropTypes from 'prop-types';

import {greyishBlue400} from "assets/jss/main";

Hour.propTypes = {
    date: PropTypes.string.isRequired
};

function Hour({date, ...props}) {
    return (
        <span style={{
            color: greyishBlue400,
            fontWeight: 400,
            fontSize: '14px',
        }}>
            <FormattedTime value={date} />
        </span>
    );
}

export default Hour;
