import React from 'react';

function MappingListContainer(props) {
    return (
        <div style={{
            height: "calc(100vh - 280px)",
            padding: "0px 15px"
        }}>
            {props.children}
        </div>
    );
}

export default MappingListContainer;
