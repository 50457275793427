import React, {Component} from 'react';
import Button from "./button";
import {injectIntl} from "react-intl";

/**
 * @deprecated use src/components/button/CancelButtonStyled
 */
class CancelButton extends Component{
    getValue() {
        const {intl, step} = this.props;

        if (this.props.content) {
            return this.props.content;
        } else if (step >= 2) {
            return intl.formatMessage({id: "stockeasy.button.previous"});
        }  else {
            return intl.formatMessage({id: "button.cancel"})
        }
    }

    render() {
        const {onClick, color} = this.props;

        return (
            <Button
                fullWidth
                color={color ?? "primary"}
                simple
                onClick={onClick}
            >
                {this.getValue()}
            </Button>
        )
    }
}

export default (injectIntl(CancelButton));
