import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import VariationResume from "./components/VariationResume";
import VariationAccordion from "./components/VariationAccordion";

import {blue25, blueColor, greyishBlue100} from "assets/jss/main";

import {ProductShowContext} from "../../context/ProductShowContext";

import listOption from "api/option/list";

import {getTranslation} from "domain/helpers/translations";

import {getFirstProductImage} from "utils/getFirstProductImage";

VariationListing.propTypes = {
    variations: PropTypes.arrayOf(
        PropTypes.shape({
            retailerOptionValues: PropTypes.array,
            pictures: PropTypes.array,
            stock: PropTypes.number,
            sku: PropTypes.string
        })
    )
};

function VariationListing({variations}) {
    if (!variations) {
        return null;
    }

    const [groupedVariations, setGroupedVariation] = useState([]);

    const {switchVariation, selectedVariation} = useContext(ProductShowContext);

    useEffect(() => {
        listOption().then((options) => {
            setGroupedVariation(buildNestedStructure(options, variations));
        });
    }, [variations]);

    function sortRetailerOptionValueByOptionPosition(retailerOptionValues) {
        return retailerOptionValues.sort((a, b) => {
            return a.option.position - b.option.position;
        })
    }

    // do not ask me why pls
    // maybe do this in php
    function buildNestedStructure(options, variations) {
        const result = {};

        variations.forEach(variation => {
            let currentLevel = result;

            const sortedRetailerOptionValues =  sortRetailerOptionValueByOptionPosition(variation.retailerOptionValues);

            sortedRetailerOptionValues.forEach((retailerOptionValue, index) => {
                const optionName = getTranslation(retailerOptionValue.option).name;
                const retailerOptionValueName = getTranslation(retailerOptionValue).name;

                const key = `${optionName}-${retailerOptionValueName}`;

                // If it's the last option, only add optionName and value
                if (index === (sortedRetailerOptionValues.length - 1)) {
                    if (!currentLevel[key]) {
                        currentLevel[key] = {
                            optionName: optionName,
                            value: retailerOptionValueName,
                            ...variation
                        };
                    }
                    currentLevel[key].count++;
                } else {
                    // Otherwise, add optionName, value and initialize the variants array
                    if (!currentLevel[key]) {
                        currentLevel[key] = {
                            optionName: optionName,
                            value: retailerOptionValueName,
                            variants: [],
                            count: 0,
                            ...variation
                        };
                    }

                    currentLevel[key].count++;

                    // Move to the variants array for the next level
                    currentLevel = currentLevel[key].variants;
                }
            });
        });

        return result;
    }

    const renderVariants = (data) => {
        if (!data) return null;

        return Object.values(data).map((item, index) => (
            <div
                key={index}
                style={{ padding: '0px 0px 0px 24px' }}
            >
                {item.variants ? (
                    <VariationAccordion
                        retailerOptionValue={item.value}
                        optionName={item.optionName}
                        image={getFirstProductImage(item.pictures)}
                        variationCount={item.count}
                        open={Object.values(item.variants).some(obj => obj.id === selectedVariation?.id)}
                    >
                        {renderVariants(item.variants)}
                    </VariationAccordion>
                ) : (
                    <div
                        key={index}
                        onClick={() => switchVariation(item.id)}
                        style={{
                            padding: '8px 8px 12px 24px',
                            cursor: 'pointer',
                            ...selectedVariation?.id === item.id && {
                                border: `1px solid ${blueColor}`,
                                borderRadius: '4px',
                                backgroundColor: blue25
                            }
                        }}
                    >
                        <VariationResume
                            image={getFirstProductImage(item.pictures)}
                            stock={item.stock}
                            sku={item.sku}
                            retailerOptionValue={item.value}
                            optionName={item.optionName}
                        />
                    </div>
                )}
            </div>
        ));
    };

    // render first level of option
    return Object.values(groupedVariations).map((item) => {
        if (item.variants) {
            return (
                <div
                    key={item.id}
                    style={{
                        border: `1px solid ${greyishBlue100}`,
                        borderRadius:'8px',
                        marginBottom: '12px',
                        padding: '12px 8px'
                    }}
                >
                    <VariationAccordion
                        retailerOptionValue={item.value}
                        optionName={item.optionName}
                        image={getFirstProductImage(item.pictures)}
                        variationCount={item.count}
                        sku={item.sku}
                        open={Object.values(item.variants).some(obj => obj.id === selectedVariation?.id)}
                    >
                        {/* then the rest of options */}
                        {renderVariants(item?.variants)}
                    </VariationAccordion>
                </div>
            )
        } else { // variation with only one option
            return (
                <div
                    onClick={() => switchVariation(item.id)}
                    style={{
                        borderRadius: '8px',
                        marginBottom: '12px',
                        cursor: 'pointer',
                        padding: '12px 8px 12px 34px' ,
                        border: `1px solid ${greyishBlue100}`,
                        ...selectedVariation?.id === item.id && {
                            border: `1px solid ${blueColor}`,
                            borderRadius: '4px',
                            backgroundColor: blue25
                        }
                    }}
                >
                    <VariationResume
                        image={getFirstProductImage(item.pictures)}
                        stock={item.stock}
                        sku={item.sku}
                        retailerOptionValue={item.value}
                        optionName={item.optionName}
                    />
                </div>
            )
        }
    });
}

export default VariationListing;
