import React, {useContext, useEffect, useState} from 'react';
import {useFormikContext} from "formik";
import {FormattedMessage} from "react-intl";

import StyledReactSelect from "components/select/StyledReactSelect";
import SelectTitle from "components/radioGroup/SelectTitle";

import {ProductShowContext} from "../../../../../context/ProductShowContext";

import {getTranslation} from "domain/helpers/translations";

function CornerField(props) {
    const {values, setFieldValue} = useFormikContext();

    const {cornerList, orderFormattedSelectOptionsByLabel} = useContext(ProductShowContext);

    const [orderedCorners, setOrderedCorners] = useState([]);
    const [formattedCorners, setFormattedCorners] = useState([]);

    if (!cornerList) {
        return null;
    }

    useEffect(() => {
        const formattedCorners = formatCornersForReactSelect(cornerList);

        setFormattedCorners(formattedCorners);

        const orderedCorners = orderFormattedSelectOptionsByLabel(formattedCorners);

        setOrderedCorners(orderedCorners);
    }, [cornerList]);


    function formatCornersForReactSelect(cornerList) {
        return cornerList.map((key) => ({"value": key['@id'], "label": getTranslation(key).name}));
    }

    function onChange(selectedOptions) {
        let newSelected = [];

        if (!selectedOptions) {
            setFieldValue('corners', newSelected);
            return;
        }

        newSelected = selectedOptions.map((selected) => findCornerInRetrievedCorners(selected.value));

        setFieldValue('corners', newSelected)
    }

    function findCornerInRetrievedCorners(id) {
        return cornerList.find((corner) => corner['@id'] === id)
    }

    function getSelectedValuesName(values) {
        if (!values) return [];

        return values.map((value) => findCornerInFormattedCorners(value['@id']))
    }

    function findCornerInFormattedCorners(id) {
        return formattedCorners.find((corner) => corner.value === id)
    }

    return (
        <>
            <SelectTitle titleTranslationKey='product.show.product.form.field.corner' />
            <StyledReactSelect
                isMulti
                closeMenuOnSelect={false}
                placeholder={<FormattedMessage id='product.show.product.form.field.corner.placeholder' />}
                name={'corners'}
                value={getSelectedValuesName(values.corners)}
                options={orderedCorners}
                onChange={onChange}
            />
        </>
    );
}

export default CornerField;
