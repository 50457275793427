import {
    container,
    defaultFont,
    // Add your imported colors below
} from "../../main";

// used in src/components/translator/formTranslator.js
const showStyle = theme => ({
    containerWithSidebar: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "240px",
        }
    },
    container: {
        ...container,
        ...defaultFont,
        display: "flex",
        marginTop: "40px"
    },
    propagateLoader: {
        display: "flex",
        justifyContent: "center"
    },
    card: {
        marginTop: "0px!important",
    },
    cardTop: {
        marginBottom: "10px!important"
    },
    containerInLoading: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        height: "80vh",
        marginTop: "0"
    },
    variationCreateForm: {
        margin: '0 0 0 auto',
    },
    appBar: {
        background: "none !important",
        boxShadow: "none !important",
        border: "none !important",
    },
});

export default showStyle;
