export function formatOptions(options) {
    let formattedOptions = [];

    if (Array.isArray(options)) {
        options.forEach(name => {
            if('' !== name) {
                formattedOptions.push({
                    'value': name, 'label': name
                });
            }
        })
    } else if (typeof options === 'string' && '' !== options) {
        formattedOptions = [
            {
                value: options,
                label: options,
            }
        ]
    }

    return formattedOptions;
}

export function formatCornersOptions(options, cornerList, locale) {
    let formattedOptions = [];

    if (Array.isArray(options) && cornerList) {
        options.forEach(name => {
            if ('' !== name) {
                cornerList.forEach(corner => {
                    if (corner.id === name) {
                        formattedOptions.push({
                            'value': corner.id, 'label': corner.translations[locale].name
                        });
                    }
                });
            }
        })
    }

    return formattedOptions;
}