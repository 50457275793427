import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import Select from "react-select";

OptionValueList.propTypes = {
    valuesList: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
};

function OptionValueList({valuesList, id, onChange, onBlur}) {
    return (
        <Select
            defaultMenuIsOpen={true}
            autoFocus={true}
            placeholder={<FormattedMessage id={'mapper.select.placeholder'} />}
            id={`attribute_${id}`}
            options={valuesList}
            filterOption={(option, search) => option.label.toLowerCase().includes(search.toLowerCase())}
            onChange={(value) => onChange(value)}
            styles={{
                placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }),
            }}
        />
    );
}

export default OptionValueList;
