import React, {Component} from "react";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {Chip, IconButton, Switch, Tab, Tabs} from "@mui/material";
import {AddRounded, RemoveRounded} from "@mui/icons-material";
import {sortOn} from "../../utils/sortOn";
import {stockEasyOffer} from "../../domain/enum/offers";
import Button from "../button/button";
import {getTranslation} from "../../domain/helpers/translations";
import {BeatLoader} from "react-spinners";
import {primaryColor} from "../../assets/jss/main";
import {ProductFilter} from "./FilterEnum";

class FilterMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideFilter: true,
            filterMenuSelected: "Status",
            selectedFilters: [],
            childrenColumns: [],
            categoriesWithAllNodes: [],
            statusFilterSwitch: false,
            optionsRetrieved: false,
        }

        this.NONE_CATEGORY = 'none'
    }

    componentDidMount() {
        let statusFromUrl = (this.getParameterValue(decodeURIComponent(this.props.match.params.params), 'status'));
        this.setState({statusFilterSwitch: 'false' === statusFromUrl});
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.categoryRetrieved) {
            this.setState({
                'categoriesWithAllNodes': this.props.getCategoriesWithAllNodes(nextProps.categoryRetrieved),
            })
        }
    }

    getParameterValue = (params, paramName) => {
        let value = null;
        params = params.split("&");
        params.map((param) => {
            if (param.search(paramName) !== -1)
                value = param.split("=")[1];
        });

        return value;
    };

    renderFilterMenu = () => {
        const {classes, brandRetrieved, categoryRetrieved, genderRetrieved, intl, retailerOptionValuesList} = this.props;
        const {filterMenuSelected} = this.state;

        if (null === retailerOptionValuesList) {
            return;
        }

        const statusRetrieved = [
            {"@type": "Status", "id": "actif", "name": intl.formatMessage({id:'retailer.product.list.filter.status.yes'}), "value": true},
            {"@type": "Status", "id": "inactif", "name": intl.formatMessage({id:'retailer.product.list.filter.status.no'}), "value": false},
        ];

        const validatedRetrieved = [
            {"@type": "Validated", "id": "validated", "name": intl.formatMessage({id:'retailer.product.list.filter.validated.yes'}), "value": true},
            {"@type": "Validated", "id": "noValidated", "name": intl.formatMessage({id:'retailer.product.list.filter.validated.no'}), "value": false},
        ];

        const eshopIdRetrieved = [
            {"@type": "EshopId", "id": "withEshopId", "name": intl.formatMessage({id:'retailer.product.list.filter.eshopid.yes'}), "value": true},
            {"@type": "EshopId", "id": "withoutEshopId", "name": intl.formatMessage({id:'retailer.product.list.filter.eshopid.no'}), "value": false},
        ];

        const categoryRetrievedSorted = categoryRetrieved ? categoryRetrieved.sort(sortOn("name")) : false;
        const brandRetrievedSorted = brandRetrieved ? brandRetrieved.sort(sortOn("name")) : false;

        let filters={};

        if (statusRetrieved && statusRetrieved.length > 0) {
            let filtersSource = {
                "Status": [{"Status": statusRetrieved}, {"Validated": validatedRetrieved}, {'EshopId': eshopIdRetrieved}],
            }
            Object.assign(filters, filtersSource);
        }

        const lockFilter = [];

        for (let [key, value] of Object.entries(ProductFilter.lock.values)) {
            lockFilter.push({
                "@type":  ProductFilter.lock.type,
                "id": value,
                "name": intl.formatMessage({id: `retailer.product.list.filter.lock.${value}`}),
                "value": value
            });
        }

        Object.assign(filters,  {"Brand": brandRetrievedSorted});
        Object.assign(filters,  {"Gender": genderRetrieved});
        Object.assign(filters,  {"Category": categoryRetrievedSorted});
        Object.assign(filters, {[ProductFilter.lock.type]: lockFilter});
        Object.keys(retailerOptionValuesList).forEach(function(optionName){
            Object.assign(filters, {[optionName]: retailerOptionValuesList[optionName]});
        });

        return (
            <div>
                <div className={classes.filterMenu}>
                    <Tabs
                        variant="scrollable"
                        value={Object.keys(filters).indexOf(filterMenuSelected)}
                        classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
                    >
                        {Object.keys(filters).map(name => {
                            return (
                                <Tab
                                    key={name}
                                    onClick={
                                        () => {
                                            this.setState({filterMenuSelected: name});
                                            this.handleTabFilterChange(name);
                                        }
                                    }
                                    disableRipple
                                    classes={{root: classes.tabRoot, selected: classes.tabSelected}}
                                    label={<FormattedMessage
                                        id={"retailer.product.list.filter." + name.toLowerCase()}
                                        defaultMessage={name}
                                    />}
                                />
                            )
                        })}
                    </Tabs>
                </div>
                <div className={classes.filters}>
                    {this.renderFilterElement(filters)}
                </div>
            </div>
        );
    };

    handleTabFilterChange = value => {
        let retailer = decodeURIComponent(this.props.match.params.id);

        if ('Brand' === value && !this.props.brandRetrieved) {
            this.props.brandList(`pagination=false&retailer=${retailer.replace("/retailers/", "")}`);
        } else if ('Gender' === value && !this.props.genderRetrieved) {
            this.props.genderList(`draft=false&pagination=false&retailer=${retailer.replace("/retailers/", "")}`);
        } else if ('Category' === value && !this.props.categoryRetrieved) {
            this.props.categoryList(`draft=false&pagination=false&retailer=${retailer.replace("/retailers/", "")}`);
        }
    }

    renderFilterElement = (filters) => {
        const {classes} = this.props;
        const {filterMenuSelected} = this.state;

        if (filterMenuSelected === "Status") {
            return this.renderFilterStatusElement(filters[filterMenuSelected]);
        } else if (!filters[filterMenuSelected]) {
            return (
                <div className={classes.filterLoading}>
                    <BeatLoader
                        sizeUnit={"px"}
                        size={12}
                        color={primaryColor}
                        loading={true}
                    />
                </div>
            )
        } else if (filterMenuSelected === "Category") {
            return this.renderFilterCategoriesContainer();
        } else if (filters[filterMenuSelected]) {
            return filters[filterMenuSelected].map(element => {
                let translation = element;
                if (element.translations) {
                    translation = getTranslation(element);
                }
                return <Chip
                    label={translation.name || translation.value}
                    key={element.id}
                    onClick={() => this.handleAddSelectedFilter(element)}
                    variant={this.checkIfFilterExist(element) ? "default" : "outlined"}
                    classes={{root: classes.filterItem}}
                />
            })
        }
    };

    renderFilterStatusElement = (statusFilter) => {
        const {classes} = this.props;

        return (
            <div className={classes.statusStockFilter}>
                {statusFilter.map(filter => {
                    let filterTitle = Object.keys(filter)[0];
                    return (
                        <div className={classes.filterInColumn} key={filterTitle}>
                            <span className={classes.filterBlueTitle}>
                                {this.props.intl.formatMessage({id: "retailer.product.list.filter." + filterTitle.toLowerCase()})}
                            </span>
                            {filter[filterTitle].map(element => {
                                return <Chip
                                    label={element.name || element.value}
                                    key={element.id}
                                    onClick={() => this.handleAddSelectedFilter(element)}
                                    variant={this.checkIfFilterExist(element) ? "default" : "outlined"}
                                    classes={{root: classes.filterItem}}
                                />
                            })}
                        </div>
                    );
                })}
            </div>
        );
    };

    checkIfNoneCategoryFilterIsSelected() {
        const {selectedFilters} = this.state;

        for (let i = 0; i < selectedFilters.length; i++) {
            if (selectedFilters[i].id === this.NONE_CATEGORY) {
                return true;
            }
        }

        return false;
    }

    checkIfFilterExist = (element) => {
        const {selectedFilters} = this.state;

        for (let i = 0; i < selectedFilters.length; i++) {
            if (selectedFilters[i].id === element.id) {
                return true
            }
        }

        return false;
    };

    handleAddSelectedFilter = element => {
        if (!this.checkIfFilterExist(element)) {
            this.setState({selectedFilters: [...this.state.selectedFilters, element]}, () => this.handleUpdateAddFilter(element, this.state.selectedFilters));
        }
    };

    handleUpdateAddFilter(element, selectedFilters) {
        let params = this.props.handlePageReset(decodeURIComponent(this.props.match.params.params));
        let newParams = "";

        // this prevents duplication of filter in url
        params.map((param) => {
            if ((param.search("status") === -1 || element["@type"] !== "Status") &&
                (param.search("validated") === -1 || element["@type"] !== "Validated") &&
                (param.search("eshopId") === -1 || element["@type"] !== "EshopId") &&
                (param.search("categories") === -1 || element["@type"] !== "Category") &&
                (param.search("brand") === -1 || element["@type"] !== "Brand") &&
                (param.search("gender") === -1 || element["@type"] !== "Gender") &&
                (param.search("options") === -1 || element["@type"] !== "RetailerOptionValue") &&
                (param.search(ProductFilter.lock.property) === -1 || element["@type"] !== ProductFilter.lock.type)) {
                newParams += `${param}&`;
            }

            return true;
        });

        switch (element["@type"]) {
            case "Brand":
                let newBrands = [];
                selectedFilters.filter(filter => filter["@type"] === "Brand").forEach(brand => {
                    newBrands.push(`"${brand.id}"`);
                });
                newParams += `brand.id=[${newBrands}]`;
                break;
            case "Category":
                let newCategories = [];
                selectedFilters.filter(filter => filter["@type"] === "Category").forEach(category => {
                    newCategories.push(category.id);
                });
                newParams += `categories.id={${newCategories.toString()}}`;
                break;
            case "Gender":
                let newGenders = [];
                selectedFilters.filter(filter => filter["@type"] === "Gender").forEach(gender => {
                    newGenders.push(`"${gender.id}"`);
                });
                newParams += `gender.id=[${newGenders}]`;
                break;
            case "Status":
                newParams += `status=${element.value}`;
                selectedFilters.map((filter) => {
                    if (filter["@type"] === "Status" && element.value !== filter["value"]) {
                        let oldStockFilterIndex = selectedFilters.indexOf(filter);
                        selectedFilters.splice(oldStockFilterIndex, 1);
                        this.setState({selectedFilters: selectedFilters});
                    }

                    return true;
                });

                break;
            case "Validated":
                newParams += `validated=${element.value}`;
                selectedFilters.map((filter) => {
                    if (filter["@type"] === "Validated" && element.value !== filter["value"]) {
                        let oldValidatedFilterIndex = selectedFilters.indexOf(filter);
                        selectedFilters.splice(oldValidatedFilterIndex, 1);
                        this.setState({selectedFilters: selectedFilters});
                    }

                    return true;
                });

                break;
            case "EshopId":
                newParams += `exists[eshopId]=${element.value}`;
                selectedFilters.map((filter) => {
                    if (filter["@type"] === "EshopId" && element.value !== filter["value"]) {
                        let oldEshopIdFilterIndex = selectedFilters.indexOf(filter);
                        selectedFilters.splice(oldEshopIdFilterIndex, 1);
                        this.setState({selectedFilters: selectedFilters});
                    }

                    return true;
                });

                break;
            case ProductFilter.lock.type:
                const selectedLockFilters = selectedFilters.filter(filter => filter["@type"] === ProductFilter.lock.type);
                const combinedFilters = [ProductFilter.lock.values.ALL, ProductFilter.lock.values.PRODUCT];

                let lockFiltersToRemove = [];
                let newLockFilter;

                if (combinedFilters.includes(element.value)) {
                    // if the filter is a combined filter
                    // keep only the new selected lock filter as the only lock filter
                    lockFiltersToRemove = selectedLockFilters.filter(filter => element.value !== filter["value"]);

                    newLockFilter = element.value;
                } else {
                    // remove combined lock filter
                    lockFiltersToRemove = selectedLockFilters.filter(filter => combinedFilters.includes(filter["value"]))

                    newLockFilter = selectedLockFilters.map(lock => lock.value);
                }

                if (newLockFilter) {
                    newParams += `${ProductFilter.lock.property}=${newLockFilter}`;
                }

                this.removeFiltersFromSelectedFilters(lockFiltersToRemove)
                break;
            case "RetailerOptionValue":
                let newOptions = [];
                selectedFilters.filter(filter => filter["@type"] === "RetailerOptionValue").forEach(option => {
                    newOptions.push(`"${option.id}"`);
                });
                newParams += `options.id=[${newOptions}]`;
                break;
            default:
                newParams = ""
        }

        this.props.history.push(`${this.props.baseParamsUrl + this.props.match.params.id}/products/${encodeURIComponent(newParams)}`);
    }

    // remove several filters at once
    removeFiltersFromSelectedFilters(filters) {
        const {selectedFilters} = this.state;

        if (filters.length === 0) {
            return;
        }

        filters.forEach((filter) => {
            let oldFilterIndex = selectedFilters.indexOf(filter);
            selectedFilters.splice(oldFilterIndex, 1);
        });

        this.setState({selectedFilters: selectedFilters});
    }

    // remove one filter at once
    removeFilterValueFromSelectedFilters(filter) {
        const {selectedFilters} = this.state;

        let oldFilterIndex = selectedFilters.indexOf(filter);
        selectedFilters.splice(oldFilterIndex, 1);

        this.setState({selectedFilters: selectedFilters});
    }

    renderSelectedFilter = () => {
        const {selectedFilters} = this.state;
        const {classes} = this.props;

        return (
            <div className={classes.filterSelectedContainer}>
                {selectedFilters.map(element => {
                    let name = element.name;
                    if (!element.name && 'Gender' === element["@type"]) {
                        name = getTranslation(element).value;
                    } else if (!element.name) {
                        name = getTranslation(element).name;
                    }
                    return <Chip
                        key={element.id}
                        label={name}
                        color="primary"
                        onDelete={() => this.handleDeleteSelectedFilter(element)}
                        className={classes.filterSelectedItem}
                    />
                })}
            </div>
        );
    };

    handleDeleteSelectedFilter = element => {
        let selectedFilters = this.state.selectedFilters;

        if (this.checkIfFilterExist(element)) {
            let index = selectedFilters.indexOf(element);

            selectedFilters.splice(index, 1);
            this.setState({selectedFilters});

            let params = this.props.handlePageReset(decodeURIComponent(this.props.match.params.params));
            let newParams = "";

            params.map((param) => {
                // this prevents duplication of filter in url
                if ((param.search("status") === -1 || element["@type"] !== "Status") &&
                    (param.search("validated") === -1 || element["@type"] !== "Validated") &&
                    (param.search("eshopId") === -1 || element["@type"] !== "EshopId") &&
                    (param.search("brand") === -1 || element["@type"] !== "Brand") &&
                    (param.search("gender") === -1 || element["@type"] !== "Gender") &&
                    (param.search("options") === -1 || element["@type"] !== "RetailerOptionValue") &&
                    (param.search("categories") === -1 || element["@type"] !== "Category") &&
                    (param.search(ProductFilter.lock.property) === -1 || element["@type"] !== ProductFilter.lock.type)) {

                    newParams += `${param}&`;
                }

                // but all the code above duplicates the urls :(
                // all these if conditions must be at the same level as the 'Lock' filter.
                // this will be fixed later
                if (param.search("categories") >= 0 && element["@type"] === "Category") {
                    let newCategories = [];
                    selectedFilters.filter(filter => filter["@type"] === "Category").forEach(category => {
                        newCategories.push(category.id);
                    });
                    newParams += `categories.id={${newCategories.toString()}}&`
                }

                if (param.search("gender") >= 0 && element["@type"] === "Gender") {
                    let newGenders = [];
                    selectedFilters.filter(filter => filter["@type"] === "Gender").forEach(gender => {
                        newGenders.push(`"${gender.id}"`);
                    });
                    newParams += `gender.id=[${newGenders}]&`;
                }

                if (param.search("brand") >= 0 && element["@type"] === "Brand") {
                    let newBrands = [];
                    selectedFilters.filter(filter => filter["@type"] === "Brand").forEach(brand => {
                        newBrands.push(`"${brand.id}"`);
                    });
                    newParams += `brand.id=[${newBrands}]&`;
                }

                if (param.search("option") >= 0 && element["@type"] === "RetailerOptionValue") {
                    let newOptions = [];
                    selectedFilters.filter(filter => filter["@type"] === "RetailerOptionValue").forEach(option => {
                        newOptions.push(`"${option.id}"`);
                    });
                    newParams += `options.id=[${newOptions}]&`;
                }

                return true;
            });

            if (element["@type"] === ProductFilter.lock.type) {
                const newLockFilter = selectedFilters
                    .filter(filter => filter["@type"] === ProductFilter.lock.type)
                    .map(lock => lock.value)
                ;

                newParams += `${ProductFilter.lock.property}=${newLockFilter}&`;
            }

            if (newParams.substr(newParams.length - 1) === "&") {
                newParams = newParams.substring(0, newParams.length - 1);
            }

            this.props.history.push(`${this.props.baseParamsUrl + this.props.match.params.id}/products/${encodeURIComponent(newParams)}`);
        }
    };

    initialize = () => {
        // we empty the search field, reset the selectedFilters State and clean the url
        document.getElementById("search-input").value = "";
        this.setState({selectedFilters: []});
        this.props.history.push(`${this.props.baseParamsUrl + this.props.match.params.id}/products/${encodeURIComponent(`retailer.id=${decodeURIComponent(this.props.match.params.id).replace("/retailers/", "")}&order[translations.name]=asc&itemsPerPage=${this.props.itemsPerPage}&page=1`)}`);
    }

    renderResetButton = () => {
        const {classes} = this.props;
        return (
            <div align="right" className={classes.resetBar}>
                <Button
                    color={"info"}
                    onClick={this.initialize}
                    className={classes.resetSelected}
                >
                    <FormattedMessage id={"retailer.product.list.filter.selected.reset"}/>
                </Button>
            </div>
        );
    };

    getCategoryParent = (category) => {
        if (category.parent) {
            return this.getCategoryParent(category.parent);
        } else {
            return category;
        }
    }

    createNoneCategory(availableTranslations) {
        const {intl} = this.props;

        if (!availableTranslations || Object.keys(availableTranslations).length === 0) {
            return null;
        }

        const noneCategory = {
            "@type": "Category",
            "@id": this.NONE_CATEGORY,
            "id": this.NONE_CATEGORY,
            "parent": null,
            "translations": {}
        }

        for (let locale in availableTranslations) {
            noneCategory.translations[locale] = {
                "name": intl.formatMessage({id: 'retailer.product.list.filter.category.none'}),
                "locale": locale
            }
        }

        return noneCategory;
    }

    // categories handling
    renderFilterCategoriesContainer = () => {
        const {classes,categoryRetrieved} = this.props;
        const {categoriesWithAllNodes} = this.state

        let categoryParentsFromRetrieved = [];
        let categoryParents = [];
        let alreadyListedCategory = [];
        if (categoryRetrieved) {
            categoryRetrieved.forEach(category => {
                let parent = this.getCategoryParent(category);
                categoryParentsFromRetrieved.push(parent);
            });
            categoryParentsFromRetrieved.forEach(element => {
                if (alreadyListedCategory.indexOf(element.id) < 0) {
                    categoryParents.push(element);
                    alreadyListedCategory.push(element.id);
                }
            });
        }

        const noneCategory = this.createNoneCategory(categoryParents[0]?.translations);

        if (noneCategory) {
            categoryParents.push(noneCategory);
        }

        return (
            <div className={classes.categoriesFilterContainer}>
                <div className={classes.categoriesFilterColumn}>
                    {categoryRetrieved && categoryParents.map(category => {
                        return (
                            <Chip
                                label={getTranslation(category).name}
                                key={category.id}
                                onClick={() => this.handleAddCategorySelectedFilter(category, 0)}
                                variant={this.checkIfFilterExist(category) ? "default" : "outlined"}
                                classes={{root: classes.filterItem}}
                                disabled={
                                    (category.id !== this.NONE_CATEGORY && this.checkIfNoneCategoryFilterIsSelected())
                                    || (category.id === this.NONE_CATEGORY && this.state.selectedFilters.length > 0)
                                }
                            />
                        )
                    })}
                </div>
                {categoriesWithAllNodes && this.state.childrenColumns.map((id,index) => {
                    return(
                        <div key={id} className={classes.categoriesFilterColumn}>
                            {this.renderFilterCategoriesElement(categoriesWithAllNodes.filter(cat => cat.parent && cat.parent.id === id), index)}
                        </div>
                    );
                })}
            </div>
        );
    };

    renderFilterCategoriesElement = (childs,index) => {
        const {classes} = this.props;

        return childs.map(category => {
            return (
                <Chip
                    label={getTranslation(category).name}
                    key={category.id}
                    onClick={() => this.handleAddCategorySelectedFilter(category, index + 1)}
                    variant={this.checkIfFilterExist(category) ? "default" : "outlined"}
                    classes={{root: classes.filterItem}}
                />
            )
        })
    };

    renderStatusFilterSwitch = () => {
        const {classes} = this.props;

        return (
            <div>
                <p><FormattedMessage id={"retailer.product.list.filter.stockeasy.status"} /></p>
                <FormattedMessage id={"retailer.product.list.label.active"} />
                <Switch
                    checked={this.state.statusFilterSwitch}
                    onChange={(e) => this.toggleStatusFilter(e.target.checked)}
                    classes={{
                        switchBase: classes.colorSwitchBase,
                        checked: classes.colorChecked,
                        track: classes.colorBar,
                    }}
                />
                <FormattedMessage id={"retailer.product.list.label.inactive"} />
            </div>
        );
    }

    toggleStatusFilter = (switchValue) => {
        // switchValue at false is when the switch is on the left
        // when switchValue is false, that mean we need to show product with status at true
        this.setState({
            statusFilterSwitch: switchValue
        });

        if (switchValue === false) {
            this.handleAddSelectedFilter({ "@type": "Status", id: "actif", name: "Actif", value: true });
        } else {
            this.handleAddSelectedFilter({ "@type": "Status", id: "inactif", name: "Inactif", value: false });
        }
    }

    handleAddCategorySelectedFilter = (category, index) => {
        const {selectedFilters} = this.state;
        let indexToDelete = [];

        selectedFilters.forEach(filter => {
            // if selected filters list contains the parent of the category clicked
            // delete the parent from filter and replace by the category clicked on
            if (category.parent && category.parent.id === filter.id) {
                let indexParentToDelete = selectedFilters.indexOf(filter);

                if (indexParentToDelete > -1) {
                    indexToDelete.push(indexParentToDelete);
                }
            }

            // if selected filters list contains children
            // find if these children are children of the category clicked
            // and then delete and replace them by the category clicked on
            else {
                if (filter.parent && filter.parent !== null ) {
                    let indexChildToDelete;

                    // if the filter is a child of the category clicked
                    if (filter.parent.id === category.id) {
                        indexChildToDelete = selectedFilters.indexOf(filter);
                    } else {
                        // get children of the categoruy until match with the filter
                        indexChildToDelete = this.props.getCategoryChildren(category.id, filter.id);
                    }

                    if (indexChildToDelete > -1) {
                        indexToDelete.push(indexChildToDelete);
                    }
                }
            }
        });

        // get all index of filters and delete them
        for (var i = indexToDelete.length -1; i >= 0; i--) {
            selectedFilters.splice(indexToDelete[i],1);
        }

        this.setState({selectedFilters}, () => {
            this.handleAddSelectedFilter(category);
            this.handleShowChildrenFilterColumn(category.id, index)
        });
    };

    handleShowChildrenFilterColumn = (id,index) => {
        const {childrenColumns} = this.state;

        childrenColumns[index] = id;

        if (this.props.hasChildren(id) && childrenColumns[index + 1] !== null) {
            childrenColumns.length = index +1
        } else {
            childrenColumns.length = index;
        }

        this.setState({childrenColumns});
    };

    render() {
        const {classes} = this.props;
        const {hideFilter, selectedFilters} = this.state;

        if (this.props.offer === stockEasyOffer) {
            return this.renderStatusFilterSwitch();
        } else {
            return (
                <div className={classes.filterBarContainer}>
                    <FormattedMessage id={"retailer.product.list.filter.title"} />
                    <IconButton
                        className={classes.filterButton}
                        onClick={() => this.setState({hideFilter: !hideFilter})}
                        size="large">
                        {hideFilter ? <AddRounded/> : <RemoveRounded/>}
                    </IconButton>
                    <div hidden={hideFilter}>
                        <div className={classes.filter}>
                            {this.renderFilterMenu()}
                            {selectedFilters.length > 0 && this.renderSelectedFilter()}
                            {selectedFilters.length > 0 && this.renderResetButton()}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

FilterMenu.propTypes = {
    classes: PropTypes.object,
    brandList: PropTypes.func,
    brandRetrieved: PropTypes.any, // can be false or an array
    genderList: PropTypes.func,
    genderRetrieved: PropTypes.any, // can be false or an array
    retailerOptionValuesList: PropTypes.func, // can be false or an array
    categoryList: PropTypes.func,
    categoryRetrieved: PropTypes.any, // can be false or an array
    offer: PropTypes.string,
    match: PropTypes.object,
    history: PropTypes.object,
    baseParamsUrl: PropTypes.string,
    hasChildren: PropTypes.func,
    itemsPerPage: PropTypes.string,
    handlePageReset: PropTypes.func,
    getCategoryChildren: PropTypes.func,
    getCategoriesWithAllNodes: PropTypes.func,
}

export default (injectIntl(FilterMenu));
