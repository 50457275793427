import React from 'react';
import PropTypes from 'prop-types';

import Grid from "@mui/material/Grid";

import {greyishBlue100, greyishBlue500} from "assets/jss/main";

StyledLine.propTypes = {
    isDragging: PropTypes.bool,
    innerRef: PropTypes.func,
};

function StyledLine({isDragging = false, innerRef, ...props}) {
    return (
        <Grid
            item
            container
            ref={innerRef}
            sx={{
                border: `1px solid ${greyishBlue100}`,
                borderRadius: '8px',
                background: '#FFFFFF',
                padding: '6px 12px',
                ':hover': {
                    borderColor: greyishBlue500
                },
                ...isDragging && {
                    borderColor: greyishBlue500,
                    boxShadow: '0px 20px 40px 0px rgba(0, 0, 0, 0.25)'
                },
            }}
            {...props}
        >
            {props.children}
            {props.placeholder}
        </Grid>
    );
}

export default StyledLine;
