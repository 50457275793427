import React, {useContext} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';

import FileInfosText from "./FileInfosText";

import {ImageDropFieldContext} from "../../../context/ImageDropFieldContext";

FileSupportTypesText.propTypes = {
    types: PropTypes.array.isRequired,
};

export default function FileSupportTypesText({types}) {
    const {disabled} = useContext(ImageDropFieldContext);

    if (types.length === 0) {
        return null
    }

    return (
        <FileInfosText disabled={disabled}>
            <FormattedMessage id="components.imageDropField.fileSupportTypes" />
            {types.join(', ')}
        </FileInfosText>
    );
}
