import React, {useContext, useEffect, useState} from 'react';
import {useFormikContext} from "formik";

import SelectTitle from "components/radioGroup/SelectTitle";
import RadioGroup from "components/radioGroup/RadioGroup";
import FormControlLabel from "components/radioGroup/FormControlLabel";

import LockFieldButton from "../../../../LockFieldButton";

import {ProductShowContext} from "../../../../../context/ProductShowContext";

import {getTranslation} from "domain/helpers/translations";
import {FormattedMessage} from "react-intl";

function GenderField(props) {
    const {values, setFieldValue} = useFormikContext();

    const {genderList, orderFormattedSelectOptionsByLabel} = useContext(ProductShowContext);

    const [orderedGenders, setOrderedGenders] = useState([]);

    useEffect(() => {
        const formattedGenders = formatGendersForSelect(genderList)

        const orderedGenders = orderFormattedSelectOptionsByLabel(formattedGenders);

        setOrderedGenders(orderedGenders);
    }, [genderList]);


    function formatGendersForSelect(genderList) {
        return genderList.map((key) => ({"value": key['@id'], "label": getTranslation(key)?.value}));
    }

    function getGenderTranslationById(id) {
        const gender = genderList.find((gender) => gender['@id'] === id);

        if (!gender) return null;

        return getTranslation(gender).value;
    }

    return (
        <>
            <SelectTitle titleTranslationKey='product.show.product.form.field.gender' />
            <RadioGroup
                alignListWithButton
                selectedValueLabel={getGenderTranslationById(values.gender)}
                name={'gender'}
                value={values.gender}
                placeholder={(<FormattedMessage id='product.show.product.form.field.gender.placeholder' />)}
                endAdornment={(
                    <LockFieldButton
                        isLocked={values.productLock?.gender}
                        onClick={() => setFieldValue('productLock.gender', !values.productLock?.gender)}
                    />
                )}
                onChange={(event) => {
                    setFieldValue('gender', event.currentTarget.value)
                }}
            >
                {orderedGenders.map((gender, index) => (
                    <FormControlLabel
                        key={index}
                        value={gender.value}
                        label={gender.label}
                    />
                ))}
            </RadioGroup>
        </>
    );
}

export default GenderField;
