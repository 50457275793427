import {useEffect, useState} from "react";
import StatusFilterPanel from "views/marketplace/merchandising/filter/statusFilterPanel";

export default function useStatus() {

    const [statusFilters, setStatusFilters] = useState(null);
    const [statusLoaded, setStatusLoaded] = useState(false);

    useEffect(() => {

        // Mimic format of fetched data from other filters
        const data = [
            {"tabTitle": "status", "tabContent": {items: [
                {id: 'active', name: 'active', '@type': 'Status'}, 
                {id: 'inactive', name: 'inactive', '@type': 'Status'}, 
            ]}},
            {"tabTitle":"validity", "tabContent": {items: [
                {id: 'valid', name: 'valid', '@type': 'Validated'}, 
                {id: 'invalid', name: 'invalid', '@type': 'Validated'}, 
            ]}},
            {"tabTitle":"oneshop", "tabContent": {items: [
                {id: 'yes', name: 'yes', '@type': 'OnEshop'}, 
                {id: 'no', name: 'no', '@type': 'OnEshop'}, 
            ]}},
        ];

        setStatusFilters(
            {
                "tabTitle": "status",
                "tabContent": {
                    component: StatusFilterPanel,
                    items: data,
                }
            }
        );

        setStatusLoaded(true);
    }, []);

    return {
        statusLoaded,
        statusFilters
    };
}
