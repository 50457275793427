import React, {useContext} from 'react';
import {FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import Grid from "@mui/material/Grid";

import CustomSwitch from "components/switch/CustomSwitch";

import {ProductShowContext} from "../../../context/ProductShowContext";

import {Authorizations, hasAuthorization} from "utils/authorizations";

function CatalogSettingsFields(props) {
    const {isStockEasyUser, userAuthorizations} = useContext(ProductShowContext);
    const {values, handleChange} = useFormikContext()

    if (isStockEasyUser && !hasAuthorization(userAuthorizations, Authorizations.STOCK_EASY_CATALOG_PRODUCT_SETTINGS)) {
        return null;
    }

    return (
        <>
            <Grid item xs={12}>
                <CustomSwitch
                    onClick={handleChange}
                    name='privateProduct'
                    checked={values.privateProduct}
                />
                <FormattedMessage id='product.show.preferences.catalogSettings.privateProduct' />
            </Grid>
            <Grid item xs={12}>
                <CustomSwitch
                    onClick={handleChange}
                    name='privatePrice'
                    checked={values.privatePrice}
                />
                <FormattedMessage id='product.show.preferences.catalogSettings.privatePrice' />
            </Grid>
            <Grid item xs={12}>
                <CustomSwitch
                    onClick={handleChange}
                    name='catalogOnly'
                    checked={values.catalogOnly}
                />
                <FormattedMessage id='product.show.preferences.catalogSettings.catalogOnly' />
            </Grid>
            <Grid item xs={12}>
                <CustomSwitch
                    onClick={handleChange}
                    name='hiddenPrice'
                    checked={values.hiddenPrice}
                />
                <FormattedMessage id='product.show.preferences.catalogSettings.hiddenPrice' />
            </Grid>
        </>
    );
}

export default CatalogSettingsFields;
