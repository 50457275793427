import React from 'react';
import {injectIntl, FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import InputAdornment from "@mui/material/InputAdornment";

import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";

import LockFieldButton from "../../../../LockFieldButton";

function PriceField(props) {
    const {values, handleChange, touched, errors, handleBlur, setFieldValue} = useFormikContext();

    return (
        <>
            <Label required htmlFor='price'>
                <FormattedMessage id='product.show.variation.form.field.price' />
            </Label>
            <InputTextField
                name='price'
                onChange={handleChange}
                value={values.price ?? ''}
                placeholder={props.intl.formatMessage({id: 'product.show.variation.form.field.price'})}
                fullWidth
                onBlur={handleBlur}
                error={touched.price && Boolean(errors.price)}
                helperText={touched.price && errors.price}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <LockFieldButton
                                isLocked={values.variationLock?.price}
                                onClick={() => setFieldValue('variationLock.price', !values.variationLock?.price)}
                            />
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
}

export default injectIntl(PriceField);
