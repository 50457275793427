import React from 'react';
import {injectIntl, FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import InputAdornment from "@mui/material/InputAdornment";

import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";

import LockFieldButton from "../../../../LockFieldButton";

function StockField(props) {
    const {values, handleChange, touched, errors, handleBlur, setFieldValue} = useFormikContext();

    return (
        <>
            <Label required htmlFor='stock'>
                <FormattedMessage id='product.show.variation.form.field.stock' />
            </Label>
            <InputTextField
                name={`stock`}
                onChange={handleChange}
                value={values.stock ?? ''}
                placeholder={props.intl.formatMessage({id: 'product.show.variation.form.field.stock'})}
                fullWidth
                onBlur={handleBlur}
                error={touched.stock && Boolean(errors.stock)}
                helperText={touched.stock && errors.stock}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <LockFieldButton
                                isLocked={values.variationLock?.stock}
                                onClick={() => setFieldValue('variationLock.stock', !values.variationLock?.stock)}
                            />
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
}

export default injectIntl(StockField);
