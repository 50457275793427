import React, {useState} from 'react';
import Select, { components }from "react-select";
import {
    black500,
    blue50,
    blueColor,
    borderGrayColor,
    grey300,
    greyColorHover,
    greyishBlue200,
    greyishBlue50,
    greyishBlue500,
    infoColor
} from "assets/jss/main";

import {ReactComponent as ArrowUp} from "assets/img/arrow-up.svg";
import {ReactComponent as ArrowDown} from "assets/img/arrow-down.svg";

// react-select can be replace by https://mui.com/material-ui/react-select/#chip
// but without search or creation
function StyledReactSelect(props) {
    const [isOpen, setIsOpen] = useState(false);

    const DropdownIndicator = dropdownIndicatorProps => {
        return (
            <div
                // this is to not trigger menu when click on endAdornment
                onMouseDown={(e) => e.stopPropagation()}
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <components.DropdownIndicator {...dropdownIndicatorProps}>
                    {isOpen ? <ArrowUp />: <ArrowDown />}
                </components.DropdownIndicator>
                <div style={{marginRight: '8px'}}>
                    {props.endAdornment && props.endAdornment}
                </div>
            </div>

        );
    };

    return (
        <Select
            components={{DropdownIndicator}}
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: '14px',
                    border: `1px solid ${state.isFocused ? blueColor : greyishBlue200}`,
                    borderRadius: '8px',
                    '&:hover': {
                        borderColor: state.isFocused ? blueColor : greyishBlue500,
                    },
                    boxShadow: state.isFocused && `0px 0px 0px 2px ${blue50}`,
                }),
                valueContainer: (baseStyle) => ({
                    ...baseStyle,
                    padding: '10px 16px',
                    gap: '8px'
                })
                ,
                multiValue: (baseStyle) =>({
                    ...baseStyle,
                    background: grey300,
                    borderRadius: '4px',
                    padding: '4px',
                    margin: 0,
                }),
                multiValueLabel: (styles, { data }) => ({
                    fontSize: '14px',
                    color: black500,
                    padding: 0,
                    paddingLeft: '12px'
                }),
                multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    color: black500,
                    padding: 0,
                    marginLeft: '4px',
                    marginRight: '4px',
                    ':hover': {
                        background: 'white',
                        cursor: 'pointer',
                    },
                    '> svg': {
                        height: '16px',
                        width: '16px',
                    }
                }),
                option: (baseStyles, { data, isDisabled, isFocused, isSelected }) => ({
                    ...baseStyles,
                    fontSize: '14px',
                    color: black500,
                    padding: '24px 16px',
                    border: `1px solid ${borderGrayColor}`,
                    borderBottom: '1px solid white',
                    background: 'white',
                    fontWeight: 400,
                    '&:first-of-type': {
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                    },
                    '&:last-child': {
                        borderBottomColor: borderGrayColor,
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                    },
                    '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: greyColorHover,
                        border: `1px solid ${infoColor}`,
                    },
                }),
                menu: () => ({
                    marginTop: '12px',
                    position: 'absolute',
                    zIndex: '2',
                    width: '100%'
                }),
                menuList: () => ({
                    boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.06)',
                    maxHeight: '285px',
                    overflow: 'scroll'
                }),
                noOptionsMessage: () => ({
                    display: 'none',
                }),
                indicatorSeparator: () => ({
                    display:'none'
                }),
                dropdownIndicator: (baseStyles) => ({
                    ...baseStyles,
                    ...props.endAdornment && {
                        paddingRight: 'unset',
                        width: '32px',
                        height: '32px',
                        cursor: 'pointer',
                    },
                    '&:hover': {
                        backgroundColor: greyishBlue50,
                        borderRadius: '50%',
                    },
                    'svg': {
                        color: black500
                    }
                }),
                indicatorsContainer: (baseStyle) => ({
                    ...baseStyle,
                    alignItems: 'flex-start',
                    marginTop: 'calc(1vh + 2px)',
                })
            }}
            {...props}
        />
    );
}

export default StyledReactSelect;
