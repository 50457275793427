import React, {useContext} from 'react';
import {useFormikContext} from "formik";

import Grid from "@mui/material/Grid";

import FormBloc from "components/form/FormBloc";

import RetailerOptionValueField from "./components/RetailerOptionValueField";
import ErrorMessage from "./components/ErrorMessage";

import {ProductShowContext} from "../../../../context/ProductShowContext";

function VariationOptions(props) {
    const {values, touched, errors} = useFormikContext();

    const {retailerOptionsValueList} = useContext(ProductShowContext);

    return (
        <FormBloc titleId='product.show.variation.form.options.title'>
            {touched.retailerOptionValues && errors.retailerOptionValues && (
                <ErrorMessage message={errors.retailerOptionValues} />
            )}
            <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <Grid container direction='column' rowSpacing={3}>
                        <Grid item container columnSpacing={2} rowSpacing={3}>
                            {Object.keys(retailerOptionsValueList).map((optionId, index) => (
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    key={optionId}
                                >
                                    <RetailerOptionValueField
                                        required={index === 0}
                                        variationRetailerOptionValue={values.retailerOptionValues.find((rov) => rov.option.id === optionId)}
                                        option={retailerOptionsValueList[optionId][0].option}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FormBloc>
    );
}

export default VariationOptions;
