import React from 'react';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

import Button from "components/button/button";

BackButton.propTypes = {
    retailerId: PropTypes.string.isRequired,
};

function BackButton({retailerId}) {
    return (
        <div style={{ textAlign: "right" }}>
            <Button
                color={"info"}
                simple
                component={Link}
                to={`/retailers/show/${encodeURIComponent('/retailers/'+retailerId)}/feeds/`}
            >
                <ArrowBackIos/>
                <FormattedMessage id={"feed.attribute.form.back"}/>
            </Button>
        </div>
    );
}

export default BackButton;
