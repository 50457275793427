import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";

// @mui/material components
import {Chip, InputAdornment, TextField} from "@mui/material";

// icons
import {Edit} from "@mui/icons-material";

// core components
import Card from "components/card/card";
import CardBody from "components/card/cardBody";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import ButtonsWrapper from "../button/buttonsWrapper";

// styles
import CreateProductFormStyle from "assets/jss/views/stockeasy/product/create/form/createProductFormStyle";

// utils
import {injectIntl} from "react-intl";

import localStorageSE from "../localStorageManager/localStorageSE";
import {withStyles} from "@mui/styles";
import {getTranslation} from "../../../../../../domain/helpers/translations";

class StockStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productData: localStorageSE.getProductData(),
            isStepValid: true,
        }
    }

    updateDeclinationStock(variationIndex, newStock) {
        const {productData} = this.state;
        productData.variations[variationIndex].stock = newStock;
        this.setState({productData});
    }

    getOptionTitle(optionId) {
        const {options} = this.props;

        const option = Object.keys(options).find(item => options[item].option.id === optionId);
        if (option) {
            return getTranslation(options[option].option).name;
        }
    }

    displayOptions(variation) {
        const {classes} = this.props;

        return (
            Object.keys(variation.options).map(item => {

                return (
                    <div className={classes.stockOptionTitle}>
                        <span className={classes.strong}>{this.getOptionTitle(item)}</span> : <strong>{variation.options[item]}</strong>
                    </div>
                );
            })
        )
    }

    renderVariationStock(variation, variationIndex) {
        const {classes} = this.props;

        if (variation.selected) {
            return (
                <div key={`stock_${variationIndex}`} className={classes.variation}>
                    <GridContainer
                        direction="row"
                        justifyContent="left"
                        alignItems="start"
                    >
                        <GridItem className={classes.chipContainer} xs={6} md={7} lg={7}>
                            {this.displayOptions(variation)}
                        </GridItem>
                        <GridItem xs={5} md={4} lg={4}>
                            <TextField
                                placeholder={"Stock"}
                                id={"stock"}
                                variant="outlined"
                                type={"number"}
                                fullWidth
                                onChange={(e) => this.updateDeclinationStock(variationIndex, e.target.value)}
                                value={variation.stock && variation.stock > 0 ? variation.stock : ''}
                                InputLabelProps={{shrink: true}}
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Edit fontSize={"small"}/>
                                        </InputAdornment>
                                    ),
                                    min: 0,
                                    type: 'number'
                                }}
                                className={classes.textField}
                                onInput={(e) => {
                                    if (!e.target.value) {
                                        e.target.value = 0;
                                    }
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9);
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </div>
            )
        }
    }

    handleConfirmStep() {
        localStorageSE.updateProductData(this.state.productData);
        this.props.goToNextStep(); // redirect to product step
    }

    render() {
        const {classes, intl} = this.props;
        const {productData} = this.state;

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardBody>
                            <section className={classes.stockHeader}>
                                <h2>{productData.name}</h2>
                                <h3>{productData.brand}</h3>
                            </section>

                            {
                                productData.variations
                                    .map((variation, index) => this.renderVariationStock(variation, index))
                            }
                            <GridContainer>
                                <ButtonsWrapper
                                    goToPreviousStep={this.props.goToPreviousStep}
                                    removeDraft={this.props.removeDraft}
                                    firstButtonContent={intl.formatMessage({id: "stockeasy.button.continue"})}
                                    submit={() => this.handleConfirmStep()}
                                    isFormValid = {this.state.isStepValid}
                                    step={4}
                                />
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        product: state.stockEasy.request.retrieved,
    };
};

export default connect(
    mapStateToProps
)(withStyles(CreateProductFormStyle)(injectIntl(StockStep)));
