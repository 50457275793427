import {createContext} from "react";

export const BadgeContext = createContext({
    hasLabel: false,
    rounded: false,
    color: 'default',
    size: 'default',
    icon: false,
    indicator: false,
    backgroundColor: 'default',
    indicatorColor: 'default',
    iconColor: 'default',
    labelColor: 'default',
})
