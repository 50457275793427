import React, {useEffect} from 'react';
import {Redirect} from "react-router-dom";
import {injectIntl} from "react-intl";

import store from "store";

import List from "components/List";

import Content from "./components/Content";

import {Authorizations, hasAuthorization} from "utils/authorizations";

function Index(props) {
    const authorizations = store.getState().authentication?.authorizations;

    // componentDidMount
    useEffect(() => {
        if (false === hasAuthorization(authorizations, Authorizations.GLOBAL_ORGANIZATION_ESHOP_MANAGEMENT)) {
            return <Redirect to={'/'} />;
        }
    }, []);

    return (
        <List title={props.intl.formatMessage({id: 'option.title'})}>
            {/* to use ListContext we need to move content into another component :( */}
            <Content />
        </List>
    );
}

export default injectIntl(Index);
