import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import Grid from "@mui/material/Grid";

import NotificationsActive from "@mui/icons-material/NotificationsActive";

import SaveButton from "components/button/SaveButton";
import Page from "components/page/Page";
import Snackbar from "components/snackbar/snackbar";
import AddIcon from "components/icons/AddIcon";

import SearchBar from "./components/SearchBar";

import {ListContext} from "./context/ListContext";

import {getTranslation} from "domain/helpers/translations";

Index.propTypes = {
    title: PropTypes.string.isRequired,
};

function Index({title, ...props}) {
    const [loading, setLoading] = useState(false);

    const [listItems, setListItems] = useState([]);
    const [notFilteredListItems, setNotFilteredListItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [createBelow, setCreateBelow] = useState({
        listItemId: null,
        position: null
    });

    const [isErrorNotificationDisplayed, setErrorNotificationDisplayed] = useState(false);

    function showCreateBelow(event, listItemId) {
        setCreateBelow({
            listItemId: listItemId,
            position: null,
        });
    }

    function hideCreateBelow() {
        setCreateBelow({
            listItemId: null,
            position: null,
        });
    }

    function openCreateDialogViaCreateBelow(listItemPosition) {
        setCreateBelow({position: listItemPosition + 1})
        openCreateDialog()
    }

    function reorder(listItems, startIndex, endIndex) {
        const result = Array.from(listItems);
        const [removed] = result.splice(startIndex, 1);

        result.splice(endIndex, 0, removed);

        return result;
    }

    function moveListItem(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        // update the order on front before retrieved from back
        const reorderedResult = reorder(listItems, result.source.index, result.destination.index);

        setListItems(reorderedResult);
    }

    function openCreateDialog(item = null) {
        setSelectedItem(item);
        setCreateDialogOpen(true);
    }

    function closeCreateDialog() {
        setCreateDialogOpen(false);
        setSelectedItem(null);
    }

    function openDeleteDialog(item) {
        setSelectedItem(item);
        setDeleteDialogOpen(true)
    }

    function closeDeleteDialog() {
        setDeleteDialogOpen(false)
        setSelectedItem(null);
    }

    /* should find a fix to search in not translated entities */
    function onSearch(value) {
        const filteredListItems = notFilteredListItems.filter((item) => (
            getTranslation(item).name.toLowerCase().includes(value.toLowerCase())
        ));

        setListItems(filteredListItems);
    }

    return (
        <ListContext.Provider value={{
            loading,
            setLoading,
            listItems,
            setListItems,
            selectedItem,
            setSelectedItem,
            setNotFilteredListItems,
            createBelow,
            showCreateBelow,
            hideCreateBelow,
            openCreateDialogViaCreateBelow,
            isCreateDialogOpen,
            openCreateDialog,
            closeCreateDialog,
            isDeleteDialogOpen,
            openDeleteDialog,
            closeDeleteDialog,
            moveListItem,
            showErrorNotification: () => setErrorNotificationDisplayed(true),
        }}>
            <Page
                titleId={title}
                fullPage
                loading={loading}
                rightContent={(
                    <Grid
                        container
                        columnSpacing={1}
                        justifyContent={"flex-end"}
                    >
                        <Grid
                            item
                            xs
                            textAlign={'right'}
                        >
                            <SearchBar onChange={(value) => onSearch(value)} />
                        </Grid>
                        <Grid item>
                            <SaveButton onClick={() => openCreateDialog()} startIcon={<AddIcon />}>
                                <FormattedMessage id={"list.button.create"} />
                            </SaveButton>
                        </Grid>
                    </Grid>
                )}
            >
                {props.children}
            </Page>
            <Snackbar
                open={isErrorNotificationDisplayed}
                close
                closeNotification={() => setErrorNotificationDisplayed(false)}
                place={"bl"}
                color={"danger"}
                icon={() => <NotificationsActive />}
                message={<FormattedMessage id={"list.error"} />}
            />
        </ListContext.Provider>
    );
}

export default Index;
