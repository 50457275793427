import React from 'react';

function ActionsContainer({isMulti = false, ...props}) {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '12px',
            position: 'absolute',
            top: isMulti ? '8px' : '12px',
            right: isMulti ? '8px' : '24px'
        }}>
            {props.children}
        </div>
    );
}

export default ActionsContainer;
