import React from 'react';
import PropTypes from 'prop-types';

import {styled} from "@mui/material/styles";

import {ReactComponent as Search} from "assets/img/search.svg";
import {greyishBlue300} from "assets/jss/main";

SearchIcon.propTypes = {
    color: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.number
};

const StyledSvg = styled(Search)(({ theme, color, disabled, size}) => ({
    ...size && {
        width: size,
        height: size
    },
    'path': {
        ...color !== null && {
            stroke: color
        },
        ...disabled === true &&  {
            stroke: greyishBlue300
        }
    },
}));

export default function SearchIcon(props) {
    return <StyledSvg {...props} />
}
