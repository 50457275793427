import React from 'react';

function ArrowDown(props) {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.7176 2.99421L6.30237 8.97086C5.72652 9.97858 4.27348 9.97858 3.69764 8.97086L0.282407 2.99421C-0.289013 1.99422 0.433037 0.75 1.58477 0.75L8.41523 0.750001C9.56697 0.750001 10.289 1.99422 9.7176 2.99421Z" fill="#333333"/>
        </svg>
    );
}

export default ArrowDown;
