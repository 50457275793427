import React from 'react';
import PropTypes from 'prop-types';

import {black500} from "assets/jss/main";
import {Tooltip} from "@mui/material";

VariationSku.propTypes = {
    sku: PropTypes.string
};

function VariationSku({sku}) {
    return (
        <span style={{
            fontSize: '12px',
            fontWeight: 400,
            color: black500,
            lineHeight: '12px',
            textOverflow: 'ellipsis',
        }}>
            <Tooltip title={sku}>
                {sku}
            </Tooltip>
        </span>
    );
}

export default VariationSku;
