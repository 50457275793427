import React from 'react';
import {injectIntl, FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import InputAdornment from "@mui/material/InputAdornment";

import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";

import LockFieldButton from "../../../../LockFieldButton";

function WeightField(props) {
    const {values, handleChange, touched, errors, handleBlur, setFieldValue} = useFormikContext();

    return (
        <>
            <Label required htmlFor='weight'>
                <FormattedMessage id='product.show.variation.form.field.weight' />
            </Label>
            <InputTextField
                name='weight'
                onChange={handleChange}
                value={values.weight ?? ''}
                placeholder={props.intl.formatMessage({id: 'product.show.variation.form.field.weight.placeholder'})}
                fullWidth
                onBlur={handleBlur}
                error={touched.weight && Boolean(errors.weight)}
                helperText={touched.weight && errors.weight}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <LockFieldButton
                                isLocked={values.variationLock?.weight}
                                onClick={() => setFieldValue('variationLock.weight', !values.variationLock?.weight)}
                            />
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
}

export default injectIntl(WeightField);
