import React from 'react';
import PropTypes from 'prop-types';
import {FormattedDate} from "react-intl";

import {black500} from "assets/jss/main";

Date.propTypes = {
    date: PropTypes.string.isRequired
};

function Date({date, ...props}) {
    return (
        <span style={{
            color: black500,
            fontWeight: 600,
            fontSize: '14px'
        }}>
            <FormattedDate value={date} />
        </span>
    );
}

export default Date;
