import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from "react-intl";
import {Formik, Form as FormikForm} from "formik";
import * as yup from "yup";
import {lazy} from "yup";
import mapValues from "lodash/mapValues";

import Grid from "@mui/material/Grid";

import FormBloc from "components/form/FormBloc";
import FormTranslator from "components/translator/formTranslator";
import Label from "components/input/new/Label";
import InputTextField from "components/input/new/InputTextField";
import ActionsContainer from "components/containers/ActionsContainer";
import CancelButtonStyled from "components/button/CancelButtonStyled";
import SaveButton from "components/button/SaveButton";
import {ListContext} from "components/List/context/ListContext";
import CustomSwitch from "components/switch/CustomSwitch";

import getTranslationInitialValues from "utils/getTranslationInitialValues";

import update from "api/option/update";
import {create, createBelowPosition} from "api/option/create";

Form.propTypes = {
    selectedOption: PropTypes.object,
    refreshList: PropTypes.func,
    createBelow: PropTypes.object,
    lastPosition: PropTypes.number.isRequired,
};

function Form({selectedOption, refreshList, createBelow, lastPosition, ...props}) {
    const {loading, closeCreateDialog} = useContext(ListContext);

    const validationSchema = yup.object({
        mapped: yup.bool(),
        code: yup.string().required(props.intl.formatMessage({id: 'list.form.value.required'})),
        translations: lazy(obj => yup.object(
            mapValues(obj, () => yup.object({
                name: yup.string().required(props.intl.formatMessage({id: 'list.form.value.required'}))
            }))
        ))
    });

    const initialValues = {
        'code': selectedOption?.code || '',
        'mapped': selectedOption?.mapped || false,
        'translations': selectedOption?.translations || getTranslationInitialValues()
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={true}
            validateOnMount={true}
            onSubmit={(values, {setSubmitting}) => {
                let promise;

                if (selectedOption) { // edit
                    promise = update(selectedOption.id, values);
                } else { // create
                    if (createBelow?.position) {
                        values.position = createBelow.position;
                        promise = createBelowPosition(values);
                    } else {
                        values.position = lastPosition + 1;
                        promise = create(values);
                    }
                }

                promise
                    .then(() => refreshList())
                    .finally(() => setSubmitting(false))
                ;
            }}
        >
            {({
                  isSubmitting,
                  isValid,
                  values,
                  handleBlur,
                  touched,
                  errors,
                  handleChange,
                }) => (
                <FormikForm>
                    <Grid container rowSpacing={6}>
                        <FormBloc
                            titleId={selectedOption ? 'option.form.title.edit' : 'option.form.title'}
                            subtitleId='list.form.translations.required'
                        >
                            <Grid container rowSpacing={3}>
                                <Grid item xs={12}>
                                    <CustomSwitch
                                        onClick={handleChange}
                                        name="mapped"
                                        checked={values.mapped}
                                    />
                                    <FormattedMessage id="option.form.mapped" />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTranslator formGenerator={(index, code) => (
                                        <>
                                            <Label htmlFor={`translations.${code}.name`}>
                                                <FormattedMessage id="option.form.name.label" />
                                            </Label>
                                            <InputTextField
                                                name={`translations.${code}.name`}
                                                onChange={handleChange}
                                                value={values.translations[code]?.name ?? ''}
                                                placeholder={props.intl.formatMessage({id: 'option.form.name.placeholder'})}
                                                fullWidth
                                                autoFocus
                                                onBlur={handleBlur}
                                                error={touched.translations?.[code]?.name && Boolean(errors.translations?.[code]?.name)}
                                                helperText={touched.translations?.[code]?.name && errors.translations?.[code]?.name}
                                            />
                                        </>
                                    )} />
                                </Grid>
                            </Grid>
                        </FormBloc>
                        <FormBloc titleId='option.form.code.title'>
                            <Grid container rowSpacing={3}>
                                <Grid item xs={12}>
                                    <Label htmlFor={`code`}>
                                        <FormattedMessage id="option.form.code.label" />
                                    </Label>
                                    <InputTextField
                                        name={`code`}
                                        onChange={handleChange}
                                        value={values.code}
                                        placeholder={props.intl.formatMessage({id: 'option.form.code.placeholder'})}
                                        fullWidth
                                        disabled={selectedOption !== null}
                                        onBlur={handleBlur}
                                        error={touched.code && Boolean(errors.code)}
                                        helperText={touched.code && errors.code}
                                    />
                                </Grid>
                            </Grid>
                        </FormBloc>
                        <Grid item xs={12}>
                            <ActionsContainer>
                                <CancelButtonStyled
                                    disabled={isSubmitting}
                                    onClick={closeCreateDialog}
                                >
                                    <FormattedMessage id="list.button.cancel" />
                                </CancelButtonStyled>
                                <SaveButton
                                    type={"submit"}
                                    disabled={loading || !isValid || isSubmitting}
                                >
                                    {selectedOption ? (
                                        <FormattedMessage id="list.button.edit" />
                                    ) : (
                                        <FormattedMessage id="list.button.add" />
                                    )}
                                </SaveButton>
                            </ActionsContainer>
                        </Grid>
                    </Grid>
                </FormikForm>
            )}
        </Formik>
    );
}

export default injectIntl(Form);
