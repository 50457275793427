import React from 'react';
import PropTypes from 'prop-types';

import Fab from "@mui/material/Fab";

import {primaryColor} from "assets/jss/main";

OptionValueListButton.propTypes = {
    onClick: PropTypes.func
};

function OptionValueListButton({onClick, ...props}) {
    return (
        <Fab
            color="default"
            onClick={onClick}
            sx={{
                background: "white",
                zIndex: '10',
                "& span svg:nth-of-type(1)": {
                    fill: primaryColor,
                    fontSize: "35px"
                },
            }}
        >
            {props.children}
        </Fab>
    );
}

export default OptionValueListButton;
