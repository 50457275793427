import React from 'react';
import PropTypes from 'prop-types';

import {
    black500,
    greyishBlue300,
    red500
} from "assets/jss/main";

Label.propTypes = {
    htmlFor: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

function Label(props) {
    return (
        <label
            htmlFor={props.htmlFor}
            aria-disabled={props.disabled}
            style={{
                fontSize: '12px',
                fontWeight: '600',
                color: props.disabled ? greyishBlue300 : black500,
            }}
            {...props}
        >
            {props.children}
            {props.required && <span style={{color: red500}}>*</span>}
        </label>
    );
}

export default Label;
