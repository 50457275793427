import React from 'react';
import PropTypes from 'prop-types';

import {primaryColor} from "assets/jss/main";

RetailerOptionValueName.propTypes = {
    retailerOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    retailerOptionValueId: PropTypes.string
};

function RetailerOptionValueName({retailerOptionValue, retailerOptionValueId}) {
    // if not string, it's category array
    const isString = typeof retailerOptionValue === "string";

    return (
        <label
            htmlFor={`attribute_${retailerOptionValueId}`}
            style={{
                color: primaryColor,
                fontWeight: "bold",
                alignSelf: "center"
            }}
        >
            {isString ? retailerOptionValue : retailerOptionValue.join('->')}
        </label>
    );
}

export default RetailerOptionValueName;
