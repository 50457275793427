import React from 'react';
import PropTypes from 'prop-types';

import StatusBadge from "./components/StatusBadge";

ProductImage.propTypes = {
    image: PropTypes.string,
    status: PropTypes.bool,
};

function ProductImage({image, status}) {
    return (
        <div style={{ height: '336px', position: 'relative' }}>
            <StatusBadge status={status} />
            <img
                src={image}
                alt={'product image'}
                style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    borderRadius: '8px'
                }}
            />
        </div>
    );
}

export default ProductImage;
