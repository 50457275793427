import normalizeTranslations from "../../actions/translations";
import {fetch, normalize} from "../../utils/dataAccess";

export function create(optionId, values) {
    values = normalizeTranslations(values);

    values.option = `/options/${optionId}`

    return new Promise((resolve, reject) => {
        fetch('/option_values', {
            method: "POST",
            body: JSON.stringify(values),
        }).then(response => response.json()).then((retrieved) => {
            resolve(normalize(retrieved));
        }).catch(error => reject(error.message));
    });
}

export function createBelowPosition(optionId, values) {
    values = normalizeTranslations(values);

    values.option = optionId

    return new Promise((resolve, reject) => {
        fetch('/option_values/add_below_position', {
            method: "POST",
            body: JSON.stringify(values),
        }).then(response => response.json()).then((retrieved) => {
            resolve(normalize(retrieved));
        }).catch(error => reject(error.message));
    });
}
