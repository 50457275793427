import React, {useContext} from 'react';

import styled from "@mui/styles/styled";

import {BadgeContext} from "../BadgeContext";

const Container = styled('div')(({ theme, context }) => {
    const iconPadding = theme.padding.noLabel.hasIndicator[context.size];
    const noLabelPadding = theme.padding.noLabel[context.size];

    const color  = context.backgroundColor ?? context.color;

    const hasIcon = !!context.icon;

    return ({
        display: 'flex',
        alignItems: hasIcon ? 'center' : 'center',
        gap: '4px',
        width: 'fit-content',
        backgroundColor: theme.palette[color]?.light ?? context.color,
        padding: context.hasLabel ? theme.padding[context.size] : hasIcon ? iconPadding : noLabelPadding,
        borderRadius: context.rounded ? theme.radius.rounded : theme.radius.default,
        border: `1px solid ${theme.border[color]?.main}`,
        height: '20px',
    })
});

function BadgeContainer(props) {
    return (
        <Container context={useContext(BadgeContext)}>
            {props.children}
        </Container>
    );
}

export default BadgeContainer;
