import store from "../store";

// this is for formik initialValues with translatable entities
export default function getTranslationInitialValues() {
    const locales = store.getState().currentOrganization?.retrieved?.locales;

    if (!locales) {
        return {}
    }

    const translations = {};

    locales.forEach(locale => translations[locale] = {
        name: '',
    });

    return translations;
}
