import React, {useState} from 'react';
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import VariationImage from "../VariationResume/components/VariationImage";
import VariationVariant from "../VariationResume/components/VariationVariant";
import VariationSku from "../VariationResume/components/VariationSku";

import VariationCount from "./components/VariationCount";
import AccordionArrow from "./components/AccordionArrow";

import {greyishBlue100} from "assets/jss/main";

VariationAccordion.propTypes = {
    image: PropTypes.string,
    optionName: PropTypes.string,
    retailerOptionValue: PropTypes.string,
    variationCount: PropTypes.number,
    sku: PropTypes.string
}

function VariationAccordion({image, optionName, retailerOptionValue, variationCount, sku, open, ...props}) {
    const [isOpen, setOpen] = useState(false);

    return (
        <div>
            <Grid
                container
                flexWrap={'nowrap'}
                sx={{marginBottom: '8px', paddingBottom: 0}}
            >
                <Grid
                    item
                    container
                    xs={12}
                    flexWrap={'nowrap'}
                >
                    <Grid
                        item
                        alignSelf='center'
                    >
                        <AccordionArrow
                            isOpen={isOpen || open}
                            setOpen={setOpen}
                            hasVariants={variationCount}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        columnSpacing={1}
                        sx={{
                            paddingBottom: '8px',
                            paddingTop: '8px',
                            borderBottom: isOpen ? `1px solid ${greyishBlue100}` : '0'
                        }}
                    >
                        <Grid item>
                            <VariationImage image={image} />
                        </Grid>
                        <Grid
                            item
                            container
                            xs
                            direction={'column'}
                            rowSpacing={0.5}
                        >
                            <Grid item>
                                <VariationVariant
                                    optionName={optionName}
                                    retailerOptionValue={retailerOptionValue}
                                />
                            </Grid>
                            <Grid item>
                                {variationCount ? (
                                    <VariationCount count={variationCount} />
                                ) : (
                                    <VariationSku sku={sku} />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {(isOpen || open) && props.children}
        </div>
    );
}

export default VariationAccordion;
