import {fetch} from "utils/dataAccess";

export default function deleteOptionValue(optionValue) {
    return new Promise((resolve, reject) => {
        fetch(optionValue['@id'], {
            method: 'DELETE',
        }).then(() => {
            resolve(true)
        }).catch(error => reject(error));
    });
}
