import React from 'react';
import {FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import fr from "date-fns/locale/fr";

import Label from "components/input/new/Label";
import DatePicker from "components/date/DatePicker";

function UnavailableBeforeField(props) {
    const {values, setFieldValue} = useFormikContext();

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <Label htmlFor='unavailableBefore'>
                <FormattedMessage id='product.show.variation.form.field.unavailableBefore' />
            </Label>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                <DatePicker
                    disablePast
                    onChange={(date) => setFieldValue('unavailableBefore', date)}
                    value={values.unavailableBefore && new Date(values.unavailableBefore)}
                />
            </LocalizationProvider>
        </div>
    );
}

export default UnavailableBeforeField;
