import React, {useContext, useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import {useFormikContext} from "formik";

import StyledReactSelect from "components/select/StyledReactSelect";
import SelectTitle from "components/radioGroup/SelectTitle";

import LockFieldButton from "../../../../LockFieldButton";

import {ProductShowContext} from "../../../../../context/ProductShowContext";

import {getTranslation} from "domain/helpers/translations";

function CategoryField(props) {
    const {values, setFieldValue} = useFormikContext();

    const {categoryList, orderFormattedSelectOptionsByLabel} = useContext(ProductShowContext);

    const [orderedCategories, setOrderedCategories] = useState([]);
    const [formattedCategories, setFormattedCategories] = useState([]);

    if (!categoryList) {
        return null;
    }

    useEffect(() => {
        const formattedCategories = formatCategoriesForReactSelect(categoryList);

        setFormattedCategories(formattedCategories);

        const orderedCategories = orderFormattedSelectOptionsByLabel(formattedCategories);

        setOrderedCategories(orderedCategories);
    }, [categoryList]);


    function formatCategoriesForReactSelect(categoryList) {
        return categoryList.map((key) => ({"value": key['@id'], "label": getTranslation(key).name}));
    }

    function onChange(selectedOptions) {
        let newSelected = [];

        if (!selectedOptions) {
            setFieldValue('categories', newSelected);

            return;
        }

        newSelected = selectedOptions.map((selected) => findCategoryInRetrievedCategories(selected.value));

        setFieldValue('categories', newSelected)
    }

    function findCategoryInRetrievedCategories(id) {
        return categoryList.find((category) => category['@id'] === id)
    }

    function getSelectedValuesName(values) {
        if (!values) return [];

        return values.map((value) => findCategoryInFormattedCategories(value['@id']))
    }

    function findCategoryInFormattedCategories(id) {
        return formattedCategories.find((category) => category.value === id)
    }

    return (
        <>
            <SelectTitle titleTranslationKey='product.show.product.form.field.category' />
            <StyledReactSelect
                isMulti
                closeMenuOnSelect={false}
                placeholder={(<FormattedMessage id='product.show.product.form.field.category.placeholder' />)}
                name={'categories'}
                value={getSelectedValuesName(values.categories)}
                options={orderedCategories}
                onChange={onChange}
                endAdornment={(
                    <LockFieldButton
                        isLocked={values.productLock?.categories}
                        onClick={(e) => {
                            e.stopPropagation();
                            setFieldValue('productLock.categories', !values.productLock?.categories)
                        }}
                    />
                )}
            />
        </>

    );
}

export default CategoryField;
