import React from "react";
import PropTypes from "prop-types";

import {Button} from "@mui/material";

import {styled} from '@mui/material/styles';

import {
    black100,
    black500,
    borderGrayColor,
    grey200,
} from "assets/jss/main";

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    textTransform: 'none',
    padding:'12px 16px',
    borderColor: borderGrayColor,
    color: black500,
    lineHeight: 'normal',
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%'
    },
    '&:hover': {
        backgroundColor: grey200,
        borderColor: grey200,
    },
    '&.Mui-disabled' : {
        backgroundColor: '#ffffff',
        color: black100,
    },
}));

CancelButtonStyled.propTypes = {
    outlined: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
}

export default function CancelButtonStyled({outlined = false, onClick, disabled = false, ...props}) {
    return (
        <StyledButton
            variant={outlined ? "outlined" : "contained"}
            disableElevation
            onClick={onClick}
            disabled={disabled}
            {...props}
        >
            {props.children}
        </StyledButton>
    )
}
