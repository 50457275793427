import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import {dangerColor, primaryColor} from "assets/jss/main";

import IsMappedLineDot from "./IsMappedLineDot";

IsMappedLine.propTypes = {
    isMapped: PropTypes.bool
};

function IsMappedLine({isMapped}) {
    const lineColor = isMapped ? primaryColor : dangerColor;

    return (
        <div>
            {isMapped ?
                <FormattedMessage id={'mapper.label.configured'} /> :
                <FormattedMessage id={'mapper.label.notConfigured'} />
            }
            <div style={{
                borderBottom: `2px solid ${lineColor}`,
                width: "100%",
                height: "47px",
                position: "absolute",
                marginTop: "-40px",
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <IsMappedLineDot color={lineColor} />
                    <IsMappedLineDot color={lineColor} />
                </div>
            </div>
        </div>
    );
}

export default IsMappedLine;
