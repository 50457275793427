import React, {useContext} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';

import GalleryAddIcon from "components/icons/GalleryAddIcon";

import ImportButton from "./components/ImportButton";
import RecommendedFormatText from "./components/RecommendedFormatText";
import FileSupportTypesText from "./components/FileSupportTypesText";

import Loading from "../Loading";

import {blueColor} from "assets/jss/main";

import {ImageDropFieldContext} from "../../context/ImageDropFieldContext";
import StyledImportZone from "./components/StyledImportZone";

Index.propTypes = {
    isMulti: PropTypes.bool,
    fileSupportTypes: PropTypes.array,
    recommendedFormat: PropTypes.string,
};

function Index({isMulti = false, fileSupportTypes = [], recommendedFormat}) {
    const {
        inputProps,
        rootProps,
        disabled,
        importLoading,
        fileRejections
    } = useContext(ImageDropFieldContext);

    return (
        <StyledImportZone
            isMulti={isMulti}
            isValid={fileRejections.length === 0}
            disabled={disabled}
            {...rootProps}
        >
            <div style={{
                textAlign: 'center',
                fontWeight: 400,
                padding: '16px 24px',
            }}>
                <input {...inputProps} />
                <GalleryAddIcon color={blueColor} disabled={disabled} />
                <p>
                    <FormattedMessage id="components.imageDropField.drop" />
                    <ImportButton />
                    <FormattedMessage id="components.imageDropField.yourPicture" />
                    <br/>
                    <FileSupportTypesText types={fileSupportTypes} />
                    <br />
                </p>
                {recommendedFormat && (
                    <RecommendedFormatText format={recommendedFormat} />
                )}
                <Loading loading={importLoading} />
            </div>
        </StyledImportZone>
    );
}

export default Index;
