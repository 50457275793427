import React, {useContext} from 'react';
import PropTypes from "prop-types";

import ProductImage from "./components/ProductImage";
import ProductDetails from "./components/ProductDetails";

import {ProductShowContext} from "../../context/ProductShowContext";

ProductResume.propTypes = {
    image: PropTypes.string,
    status: PropTypes.bool,
    name: PropTypes.string,
    eshopId: PropTypes.string,
    sku: PropTypes.string,
    price: PropTypes.number,
    stock: PropTypes.number,
    updateAt: PropTypes.string
};

function ProductResume({image, status, name, eshopId, sku, price, stock, updatedAt}) {
    const {switchVariation} = useContext(ProductShowContext);

    return (
        <div
            style={{ cursor: 'pointer' }}
            onClick={() => switchVariation(null)}
        >
            <ProductImage image={image} status={status} />
            <ProductDetails
                name={name}
                eshopId={eshopId}
                sku={sku}
                price={price}
                stock={stock}
                updatedAt={updatedAt}
            />
        </div>
    );
}

export default ProductResume;
