import React, {Component} from "react";

// @mui/material components
import {withStyles} from "@mui/styles";
import {} from "@mui/material";
import connect from "react-redux/es/connect/connect";

// styles
import indexStyle from "../../../../assets/jss/views/stockeasy/product/create/index";
import StockEasyStepManager from "../../../../domain/components/stockeasy/StockEasyStepManager";
import {list} from "../../../../actions/dictionaries/list";
import {categoryList as listOrganizationCategories} from "../../../../actions/organizationCategory";
import {cornerList} from "../../../../api/corner/list";
import { getOptionsWithRetailerOptionValues } from "../../../../api/retailerOptionValues/list";
import {retrieve as getRetailer} from "../../../../actions/retailer/show";
import {injectIntl} from "react-intl";
import {PropagateLoader} from "react-spinners";
import {primaryColor} from "../../../../assets/jss/main";

class Create extends Component {
    retailerId = null;

    constructor(props) {
        super(props);

        const path = window.location.pathname;
        const regex = /\/stockeasy\/retailers\/(.*)\/product\/create/g;
        const matches = regex.exec(path);

        if (matches.length < 1) {
            window.location.href = '/'; // retailer is mandatory in the url
        }

        this.state = {
            corners: null,
            options: null,
        }

        this.retailerId = matches[1];
        this.loadRetailer(this.retailerId);
        this.loadDictionaries();
        this.props.loadCategories();
    }

    componentDidMount() {
        cornerList('enabled=true&public=true&order[name]=asc').then(response => {
            this.setState({corners : response});
        });
        getOptionsWithRetailerOptionValues(this.retailerId).then(response => {
            this.setState({options: response});
        })
    }

    loadDictionaries() {
        const types = ['category', 'brand', 'taxRate'];
        this.props.loadDictionaries(this.retailerId, types);
    }

    loadRetailer(retailerId) {
        this.props.loadRetailer(retailerId);
    }

    render() {
        const {classes} = this.props;
        const loading = !!this.props.loading;
        const loadingRetailer = !!this.props.loadingRetailer;
        const loadingCornersList = null === this.state.corners;
        const loadingOptionsList = null === this.state.options;

        if (loadingRetailer || loading || loadingCornersList || loadingOptionsList) {
            return (
                <div className={classes.containerWithSidebar}>
                    <PropagateLoader
                        size="20"
                        sizeUnit="px"
                        color={primaryColor}
                        css="top: 40px; left: 50%; margin-left: -10px;"
                        loading
                    />
                </div>
            );
        }

        return (
            <div className={classes.containerWithSidebar}>
                <StockEasyStepManager
                    retailerId={this.retailerId}
                    classes={this.props.classes}
                    location={this.props.location}
                    history={this.props.history}
                    retailer={this.props.retailer}
                    corners={this.state.corners}
                    options={this.state.options}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    loadDictionaries: (retailerId, types) => dispatch(list(retailerId, types)),
    loadCategories: () => dispatch(listOrganizationCategories('&order[name]=asc')),
    loadRetailer: (id) => dispatch(getRetailer(id)),
});


const mapStateToProps = (state) => {
    return {
        loading: state.organizationCategory.list.loading || state.dictionaries.list.loading,
        loadingRetailer: state.retailer.show.loading,
        retailer: state.retailer.show.retrieved,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(indexStyle)(injectIntl(Create)));
