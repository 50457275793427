import React, {useContext, useEffect, useState} from 'react';
import {useFormikContext} from "formik";

import SelectTitle from "components/radioGroup/SelectTitle";
import RadioGroup from "components/radioGroup/RadioGroup";
import FormControlLabel from "components/radioGroup/FormControlLabel";

import {ProductShowContext} from "../../../../../context/ProductShowContext";

import LockFieldButton from "../../../../LockFieldButton";

function BrandField(props) {
    const {values, setFieldValue} = useFormikContext();

    const {brandList, orderFormattedSelectOptionsByLabel} = useContext(ProductShowContext);

    const [orderedBrands, setOrderedBrands] = useState([]);

    useEffect(() => {
        const formattedBrands = formatBrandsForSelect(brandList);

        const orderedBrands = orderFormattedSelectOptionsByLabel(formattedBrands);

        setOrderedBrands(orderedBrands);
    }, [brandList]);

    function formatBrandsForSelect(brandList) {
        return brandList.map((key) => ({"value": key['@id'], "label": key.name}));
    }

    function getBrandNameById(id) {
        return brandList.find((brand) => brand['@id'] === id)?.name
    }

    return (
        <>
            <SelectTitle titleTranslationKey='product.show.product.form.field.brand' />
            <RadioGroup
                alignListWithButton
                selectedValueLabel={getBrandNameById(values.brand)}
                name={'brand'}
                value={values.brand}
                onChange={(event) => {
                    setFieldValue('brand', event.currentTarget.value)
                }}
                endAdornment={(
                    <LockFieldButton
                        isLocked={values.productLock?.brand}
                        onClick={() => setFieldValue('productLock.brand', !values.productLock?.brand)}
                    />
                )}
            >
                {orderedBrands.map((brand, index) => (
                    <FormControlLabel
                        key={index}
                        value={brand.value}
                        label={brand.label}
                    />
                ))}
            </RadioGroup>
        </>
    );
}

export default BrandField;
