import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import classNames from "classnames";

import {makeStyles} from "@mui/styles";
import {grayColor, red500} from "assets/jss/main";

const useStyles = makeStyles({
    titleContainer: {
        lineHeight: '1.5rem',
    },
    title: {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '1.5rem',
    },
    titleDisabled: {
        color: grayColor
    },
    subtitle: {
        fontSize: '0.75rem',
        lineHeight: '1.2rem',
        color: grayColor
    }
})

export default function SelectTitle(props) {
    const classes = useStyles();

    return (
        <div className={classes.titleContainer}>
            <div className={classNames({[classes.title]: true, [classes.titleDisabled]: props.disabled})}>
                <FormattedMessage id={props.titleTranslationKey} />
                {props.required && <span style={{color: red500}}>*</span>}
            </div>
            {props.subtitleTranslationKey && (
                <div>
                    <span className={classes.subtitle}>
                        <FormattedMessage id={props.subtitleTranslationKey} />
                    </span>
                </div>
            )}
        </div>
    )
}

SelectTitle.propTypes = {
    titleTranslationKey: PropTypes.string.isRequired,
    subtitleTranslationKey:  PropTypes.string,
    disabled:  PropTypes.bool,
}
