import React from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';

import {greyishBlue500} from "assets/jss/main";

FormSubtitle.propTypes = {
    subtitleId: PropTypes.string.isRequired
};

function FormSubtitle({subtitleId}) {
    return (
        <span style={{
            fontSize: '16px',
            fontWeight: '400',
            color: greyishBlue500,
        }}>
            <FormattedMessage id={subtitleId} />
        </span>
    );
}

export default FormSubtitle;
