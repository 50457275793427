import {fetch} from "utils/dataAccess";
import normalizeTranslations from "../../actions/translations";

export default async function update(optionId, values)  {
    values = normalizeTranslations(values);

    const response = await fetch(`/options/${optionId}`, {
        method: "PUT",
        body: JSON.stringify(values),
    });

    return await response.json();
}
