import Cookies from "js-cookie";
import {isTranslatedCode} from "../../domain/enum/locales";
import main_messages_fr from "./fr/main.json";
import main_messages_en from "./en/main.json";
import main_messages_it from "./it/main.json";

const userJson = Cookies.get('_c');
let language;

if (!!userJson) {
    language = JSON.parse(userJson)?.locale;
} else {
    language = navigator.language;
}

if (!isTranslatedCode(language)) {
    language = 'fr_FR';
}
language = language.split(/[-_]/)[0];

let messages;
switch (language) {
    case 'fr':
        messages = {...main_messages_fr};
        break;
    case 'en':
        messages = {...main_messages_en};
        break;
    case 'it':
        messages = {...main_messages_it};
        break;
    default:
        console.error('You must register translations for language code: ' + language);
}
const translations = {[language]: messages};

export {messages, language, translations};
