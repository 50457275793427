import React, {useContext, useRef, useState} from "react";
import {TableCell, TableRow} from "@mui/material";
import {withStyles} from "@mui/styles";
import {FormattedMessage} from "react-intl";

import {Formik} from "formik";
import debounce from "lodash.debounce";
import NumberFormat from "react-number-format";

import QuoteDetailGridContentLineAction from "./gridLineAction";
import detailStyle from "../../../../../assets/jss/views/quote/detailStyle";
import yup from '../../../../../assets/translations/validation';

import QuoteDetailGridUpdatableCell from "./gridUpdatableCell";
import {StoreContext} from "../../../../../contexts/storeContext";
import FormattedNumberWithCurrency
    from "../../../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency";
import {QuoteContext} from "../../../../../contexts/quoteContext";
import FormObserver from "../../../../../components/form/formik/formObserver";
import {getLineCalculator, convertHT_to_TTC} from "../../../../../services/quote/lineCalculator";
import updateLineItem from "../../../../../api/quote/lineItem/update";
import {getTranslation} from "../../../../../domain/helpers/translations";
import {
    WAITING_REVIEW,
    REVIEW_IN_PROGRESS,
} from "../../../enums/state";
import QuantityPromotionPopover from "../quantityPromotionPopover";
import {MAX_QUANTITY_PER_LINE} from "../../../enums/constants";

const LineItemSchema = yup.object().shape({
    quantity: yup.number().required().positive().integer().max(MAX_QUANTITY_PER_LINE),
    unitPrice: yup.number().required().positive(),
    discount: yup.number().min(0).max(100),
    taxRate: yup.string().uuid().required(),
});

const showQuantityPromotionPopoverIfNecessaryDebouncer = debounce((line, values, editorMode, lastQuantityPromotionInteracted, setQuantityPromotionPopoverDisplayed) => {
    if(editorMode && getQuantityPromotionByQuantity(line, values.quantity) !== lastQuantityPromotionInteracted) {
        setQuantityPromotionPopoverDisplayed(true);
    }
}, 500);

export const getQuantityPromotionByQuantity = (line, quantity) => {
    let targetPromotion = null;
    for (const promotion of line.variation?.promotions?.filter((promotion) => promotion['@type'] === 'QuantityDiscount') ?? []) {
        if (promotion.minQuantity <= quantity && (targetPromotion?.minQuantity ?? 0) < promotion.minQuantity) {
            targetPromotion = promotion;
        }
    }
    return targetPromotion;
}

const QuoteDetailGridContentLine = ({ line, classes }) => {
    const [editorMode, setEditorMode] = useState(false);
    const [isRemoving] = useState(false);
    const [lastTotalWithTaxes, setLastTotalWithTaxes] = useState(null);
    const [unitPriceTTC, setUnitPriceTTC] = useState(line.unitPrice / 100);
    const store = useContext(StoreContext);
    const { refreshQuote, taxRates, setLineBeingEdited } = useContext(QuoteContext);
    const currency = store.getState()?.currentOrganization?.retrieved?.currency;
    const { quote } = useContext(QuoteContext);
    const isQuoteEditable = (quote.state === WAITING_REVIEW || quote.state === REVIEW_IN_PROGRESS);
    const [quantityPromotionPopoverDisplayed, setQuantityPromotionPopoverDisplayed] = useState(false);
    const [lastQuantityPromotionInteracted, setLastQuantityPromotionInteracted] = useState(getQuantityPromotionByQuantity(line, line.units.length));
    const quantityInputRef = useRef(null);

    if (!taxRates) {
        return;
    }
    const getSelectedTaxRateValue = (selectedTaxRateId) => taxRates.find(taxRate => taxRate.id === selectedTaxRateId)?.value;
    const submit = (values, { setSubmitting }) => {
        setSubmitting(true);

        const difference = values.quantity - line.units.length;
        const promises = [];
        let units = [];
        if (0 !== difference) {
            units = line.units;
            const isNegative = difference < 0;
            for (let i = 0; i < Math.abs(difference); i++) {
                if (isNegative) {
                    units.splice(-1, 1);
                } else {
                    units.push({});
                }
            }
        }

        // unit price displayed in the front is without taxes. The unit price in database is stored with taxes
        // So this code just do the conversion before update the lineItem record
        const unitPrice = Math.round(values.unitPrice * 10000);
        const taxRateValue = getSelectedTaxRateValue(values.taxRate)
        const unitPriceWithTaxes = unitPrice + (taxRateValue * unitPrice / 100);

        Promise.all(promises).then(() => {
            updateLineItem(line.id, Math.round(unitPriceWithTaxes / 100), values.taxRate, values.discount, units).then(() => refreshQuote(() => {
                setSubmitting(false);
                setEditorMode(false);
                setLineBeingEdited(null);
            }));
        });
    };

    return (
        <Formik
            initialValues={{
                unitPrice: line.unitPrice / (100 + line.taxRate.value), // Tax excluded
                quantity: line.units.length,
                discount: line.discount,
                taxRate: line.taxRate.id,
            }}
            validationSchema={LineItemSchema}
            validateOnChange
            onSubmit={submit}
        >
            {({
                values,
                initialValues,
                isSubmitting,
                submitForm,
                resetForm,
                setFieldValue,
                errors,
            }) => {
                const closeQuantityPromotionPopover = (values, apply = false) => {
                    const quantityPromotion = getQuantityPromotionByQuantity(line, values.quantity);
                    setLastQuantityPromotionInteracted(quantityPromotion);
                    setQuantityPromotionPopoverDisplayed(false);

                    if (apply) {
                        if(!quantityPromotion) {
                            setFieldValue('discount', 0);
                            return;

                        }
                        setFieldValue('discount', (1 - (quantityPromotion.discountedPrice / unitPriceTTC)) * 100);
                    }
                }

                const emitCurrentLineValue = (calculator) => {
                    if (values === initialValues) {
                        return;
                    }

                    setLineBeingEdited({
                        id: line.id,
                        totalWithoutTaxes: calculator.getTotalTaxExcluded(),
                        quantity: calculator.quantity,
                        totalWithTaxes: calculator.getTotalWithTax(),
                        totalWithoutTaxesAndDiscount: calculator.getTotalTaxExcluded(false),
                        totalWithTaxesWithoutDiscount: calculator.getTotalWithTax(false),
                    });
                };

                function onFormUpdate() {
                    const newUnitPriceTTC = convertHT_to_TTC(values.unitPrice, getSelectedTaxRateValue(values.taxRate));
                    const calculator = getLineCalculator(
                        newUnitPriceTTC,
                        getSelectedTaxRateValue(values.taxRate),
                        values.quantity,
                        values.discount,
                    );

                    const total = calculator.getTotalWithTax();
                    setUnitPriceTTC(newUnitPriceTTC);
                    if (null !== lastTotalWithTaxes && lastTotalWithTaxes !== total) {
                        if (Object.keys(errors).length === 0) {
                            emitCurrentLineValue(calculator);
                        }
                    }
                    setLastTotalWithTaxes(total);
                    showQuantityPromotionPopoverIfNecessaryDebouncer(line, values, editorMode, lastQuantityPromotionInteracted, setQuantityPromotionPopoverDisplayed);
                }

                let unitPrice = values.unitPrice;
                if (typeof unitPrice === 'object') {
                    unitPrice = unitPrice.floatValue;
                }


                const {getTotalTaxExcluded, getTotalWithTax} = getLineCalculator(
                    unitPriceTTC,
                    getSelectedTaxRateValue(values.taxRate),
                    values.quantity,
                    values.discount,
                );

                return (
                <TableRow>
                    <FormObserver onChange={() => onFormUpdate()}/>
                    <TableCell width={100}>
                        <img src={line.variation?.pictures[0]?.mediaObject.contentUrl} alt={'Product image'} className={classes.tableTableProductImage}/>
                    </TableCell>
                    <TableCell>
                        <div><strong className={classes.primary}><span
                            className={classes.brand}>{line.variation?.product.brand.name}</span> - {getTranslation(line.variation?.product ?? {translations: []})?.name}
                        </strong></div>
                        <div><FormattedMessage id={"picking.quote.detail.gridContent.sku"}/> : {line.variation?.sku}
                        </div>
                        <div>
                            {Object.keys(line.variation.retailerOptionValues).map(key => {
                                const optionName = getTranslation(line.variation.retailerOptionValues[key]['option'])?.name;
                                const optionValue = getTranslation(line.variation.retailerOptionValues[key])?.name;

                                return (
                                    <span>
                                    {optionName} : <b>{optionValue ?? <FormattedMessage id={"product.show.attribute.na"}/>}</b>
                                        &nbsp;-&nbsp;
                                    </span>
                                )
                            })}
                        </div>
                    </TableCell>
                    <TableCell>
                        <div ref={quantityInputRef}>
                            <QuoteDetailGridUpdatableCell
                                value={line.units.length}
                                name={"quantity"}
                                editActive={editorMode}
                            />
                        </div>
                        <QuantityPromotionPopover
                            line={line}
                            values={values}
                            classes={classes}
                            quantityPromotionPopoverDisplayed={quantityPromotionPopoverDisplayed}
                            quantityInputRef={quantityInputRef}
                            closeQuantityPromotionPopover={closeQuantityPromotionPopover}
                        />
                    </TableCell>
                    <TableCell>
                        <div className={classes.inputUnitPriceHT}>
                        <QuoteDetailGridUpdatableCell
                            value={
                                <FormattedNumberWithCurrency value={unitPrice} currency={currency} decimalScale={3} />
                            }
                            name={"unitPrice"}
                            type={'price'}
                            editActive={editorMode}
                            customInputProps={{decimalScale:3}}
                        />
                        </div>
                        <div className={classes.inputUnitPriceTTC}>
                            <strong className={isQuoteEditable ? classes.inputUnitPriceTTCInEditView : ''}>
                                <FormattedNumberWithCurrency value={unitPriceTTC} currency={currency} decimalScale={2} />
                            </strong>
                        </div>
                    </TableCell>
                    <TableCell>
                        <QuoteDetailGridUpdatableCell
                            value={<NumberFormat value={line.taxRate.value} displayType="text" decimalScale={2} classes={classes.noWrap} suffix={" %"} />}
                            as={"select"}
                            children={taxRates.map(value => <option key={value.id} value={value.id}>{value.name}</option>)}
                            name={"taxRate"}
                            editActive={false}
                        />
                    </TableCell>
                    <TableCell>
                        <QuoteDetailGridUpdatableCell
                            value={
                                <NumberFormat value={line.discount} displayType="text" decimalScale={2} classes={classes.noWrap} suffix={" %"} />
                            }
                            name={"discount"}
                            editActive={editorMode}
                            type={"percentage"}
                            customInputProps={{decimalScale: 2}}
                        />
                    </TableCell>
                    <TableCell>
                        <FormattedNumberWithCurrency value={getTotalTaxExcluded()} currency={currency} decimalScale={2} /><br/>
                        <strong>
                            <FormattedNumberWithCurrency value={getTotalWithTax()} currency={currency}/>
                        </strong>
                    </TableCell>
                        <QuoteDetailGridContentLineAction
                            editorMode={editorMode}
                            isLoading={isSubmitting || isRemoving}
                            submitForm={submitForm}
                            resetForm={() => {
                                setLastQuantityPromotionInteracted(getQuantityPromotionByQuantity(line, line.units.length));
                                resetForm();
                            }}
                            line={line}
                            setEditorMode={setEditorMode}
                            isQuoteEditable={isQuoteEditable}
                        />
                </TableRow>
            )}}
        </Formik>
    );
};

export default withStyles(detailStyle)(QuoteDetailGridContentLine);
