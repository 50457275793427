import Cookies from "js-cookie";

export function setOrganization(values) {
    const member = Cookies.get("_c") ? JSON.parse(Cookies.get("_c").toString()) : null;

    if (member) {
        values.organization = `/organizations/${member["organization"]}`;
    }
}

export function getCurrentOrganizationId() {
    const member = Cookies.get("_c") ? JSON.parse(Cookies.get("_c").toString()) : null;

    if (!member) {
        return null;
    }

    return member["organization"];

}
